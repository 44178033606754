define('frontend/initializers/ember-simple-auth', ['exports', 'frontend/config/environment', 'ember-simple-auth/configuration', 'ember-simple-auth/initializers/setup-session', 'ember-simple-auth/initializers/setup-session-service'], function (exports, _environment, _configuration, _setupSession, _setupSessionService) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function setupTokenOverriding(registry) {
        var params = {};

        window.location.search.substr(1).split('&').forEach(function (item) {
            var tokens = item.split('=');
            params[tokens[0]] = tokens[1];
        });

        // Copied from ember-simple-auth/initializers/setup-session-restoration
        var ApplicationRoute = registry.resolveRegistration ? registry.resolveRegistration('route:application') : registry.resolve('route:application');

        ApplicationRoute.reopen({
            init: function init() {
                this._super.apply(this, arguments);

                var originalBeforeModel = this.beforeModel;
                this.beforeModel = function () {
                    var _this = this,
                        _arguments = arguments;

                    var session = Ember.getOwner(this).lookup('session:main');

                    if (params.token) {
                        session.get('store').persist({
                            authenticated: {
                                authenticator: "authenticator:jwt",
                                token: params.token
                            }
                        });
                    }

                    return session.restore().then(function () {
                        return originalBeforeModel.apply(_this, _arguments);
                    }, function () {
                        return originalBeforeModel.apply(_this, _arguments);
                    });
                };
            }
        });
    }

    exports.default = {
        name: 'ember-simple-auth',

        initialize: function initialize(registry) {
            var config = _environment.default['ember-simple-auth'] || {};
            config.baseURL = _environment.default.rootURL || _environment.default.baseURL;
            _configuration.default.load(config);

            (0, _setupSession.default)(registry);
            (0, _setupSessionService.default)(registry);
            setupTokenOverriding(registry);
        }
    };
});