define('frontend/services/modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        user: Ember.inject.service(),
        dialog: Ember.inject.service(),

        confirm: function confirm(options) {
            options.template = options.template || 'dialogs/messages/delete-model';

            if (this.get('user.data.confirmDelete')) {
                return this.get('dialog').show('dialogs/layouts/confirm', options.template, options);
            }

            return new Ember.RSVP.Promise(function (resolve) {
                resolve();
            });
        },
        alert: function alert(options) {
            options.template = options.template || 'dialogs/messages/error';

            return this.get('dialog').show('dialogs/layouts/alert', options.template, options);
        },
        settings: function settings(options) {
            return this.get('dialog').show('dialogs/layouts/settings', options.template, options);
        },
        destroyAllModals: function destroyAllModals() {
            this.get('dialog').destroyAllPresenter();
        },
        openSettingsModal: function openSettingsModal(template) {
            this.destroyAllModals();
            this.settings({
                template: '' + template
            });
        }
    });
});