define('frontend/services/locale', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        i18n: Ember.inject.service(),
        moment: Ember.inject.service(),

        curentLocale: null,
        languages: [{
            name: 'en',
            long: 'settings.main.languages.en.long',
            short: 'settings.main.languages.en.short'
        }, {
            name: 'bg',
            long: 'settings.main.languages.bg.long',
            short: 'settings.main.languages.bg.short'
        }],
        init: function init() {
            this.set('curentLocale', this.get('i18n.locale'));
        },
        changeLocale: function changeLocale(locale) {
            this.set('curentLocale', locale);
            this.set('i18n.locale', locale);
            this.get('moment').changeLocale(locale);
        }
    });
});