define('frontend/controllers/inner-app/events/contact-groups', ['exports', 'frontend/mixins/debounce-query-params'], function (exports, _debounceQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        selectedItems: service(),
        specialItems: service(),
        ajax: service(),
        queryParams: ['searchEventGroup'],
        ongoingRequest: computed.or('ajax.isRunning', 'campaign.isSaving'),
        allChecked: computed('selectedItems.updated', 'model', 'model.[]', function () {
            var normalGroupsSelected = false;

            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            if (this.get('model').get('length') > 0) {
                normalGroupsSelected = this.get('model').reduce(function (carry, group) {
                    if (specialItems.count('contact', group.get('id'))) {
                        return carry && selectedItems.areSelected(specialItems.getIdsArray('contact', group.get('id')));
                    }
                    return carry && group.get('isChecked');
                }, true);
            }

            if (normalGroupsSelected && !this.searchEventGroup) {
                if (!selectedItems.areSelected(specialItems.getIdsArray('contact', 'google'))) {
                    return false;
                }
                if (!selectedItems.areSelected(specialItems.getIdsArray('contact', 'no-group'))) {
                    return false;
                }
            }
            return normalGroupsSelected;
        }),
        disabledEditing: computed.or('campaign.hasRun', 'ongoingRequest')
    });
});