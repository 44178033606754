define('frontend/controllers/inner-app/reports/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Controller.extend({

        message: computed('model.type', function () {
            return this.get('model.type') === 'sms' ? this.get('i18n').t('reports.sent-sms') : this.get('i18n').t('reports.sent-voice');
        }),

        allSnapshots: computed.mapBy('model.deliveryStatistic', 'countByType'),
        allSnapshotsCount: computed.sum('allSnapshots'),

        successfullySent: computed('model.deliveryStatistic', 'model.type', function () {
            if (this.get('model.type') === 'voice') {
                return this.filterStatistic(['ready', 'initiated']);
            }
            return this.get('model.deliveryStatistic').filterBy('status', 'delivered_to_handset').get('firstObject.countByType');
        }),
        successfullySentProgress: computed('allSnapshotsCount', 'successfullySent', function () {
            var progress = this.get('successfullySent') / this.get('allSnapshotsCount');
            return progress.toFixed(2);
        }),
        unsuccessfullySent: computed('model.deliveryStatistic', 'model.type', function () {
            if (this.get('model.type') === 'voice') {
                return this.filterStatistic(['busy', 'no_answer']);
            }

            return this.filterStatistic(['not_delivered_to_handset', 'not_accepted_from_smsc']);
        }),
        unsuccessfullySentProgress: computed('allSnapshotsCount', 'unsuccessfullySent', function () {
            var progress = this.get('unsuccessfullySent') / this.get('allSnapshotsCount');
            return progress.toFixed(2);
        }),
        inProcess: computed('model.deliveryStatistic', 'model.type', function () {
            if (this.get('model.type') === 'voice') {
                return this.filterStatistic(['queued', 'ringing', 'in_progress']);
            }

            return this.filterStatistic(['sent_to_transport', 'queued_on_smsc', 'delivered_to_smsc']);
        }),
        inProcessProgress: computed('allSnapshotsCount', 'inProcess', function () {
            var progress = this.get('inProcess') / this.get('allSnapshotsCount');
            return progress.toFixed(2);
        }),
        failedStatus: computed('model.deliveryStatistic', 'model.type', function () {
            if (this.get('model.type') === 'voice') {
                return this.filterStatistic(['failed', 'transport_server_failed']);
            }

            return this.filterStatistic(['send_failed', 'transport_server_failed']);
        }),
        failedStatusProgress: computed('allSnapshotsCount', 'failedStatus', function () {
            var progress = this.get('failedStatus') / this.get('allSnapshotsCount');
            return progress.toFixed(2);
        }),

        getCount: function getCount(items) {
            var result = 0;
            if (items.length > 0) {
                items.forEach(function (item) {
                    result += item.countByType;
                });
            }
            return result;
        },
        filterStatistic: function filterStatistic(statuses) {
            var items = this.get('model.deliveryStatistic').filter(function (item) {
                return statuses.contains(item.status);
            });
            return this.getCount(items);
        }
    });
});