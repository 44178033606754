define('frontend/models/template-group', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validators = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            ignoreBlank: true,
            message: 'validation.group-name-not-blank'
        }), (0, _emberCpValidations.validator)('ds-error')]
    }, {
        debounce: 500
    });

    var templateGroup = _model.default.extend(Validators, {
        name: (0, _attr.default)(),
        companyId: (0, _attr.default)(),
        templates: (0, _relationships.hasMany)('template', { async: true }),
        countTemplates: (0, _attr.default)('number', { defaultValue: 0 }),
        templateIds: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return Ember.A([]);
            }
        }),
        isNotValid: Ember.computed.empty('name'),
        isChecked: false
    });

    exports.default = templateGroup;
});