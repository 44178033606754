define('frontend/services/notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Service = Ember.Service,
        service = Ember.inject.service;
    exports.default = Service.extend({
        flashMessages: service(),

        flashOptions: {
            timeout: 3000,
            actionNotification: true
        },

        showSuccess: function showSuccess(message, options) {
            var flashOptions = Object.assign({}, this.get('flashOptions'), options);
            this.get('flashMessages').success(message, flashOptions);
        },
        showWarning: function showWarning(message, options) {
            var flashOptions = Object.assign({}, this.get('flashOptions'), options);
            this.get('flashMessages').danger(message, flashOptions);
        }
    });
});