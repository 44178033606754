define("frontend/components/tooltip-help", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        text: "tooltip.default",
        icon: "icon-tooltip"
    });
});