define('frontend/controllers/inner-app/reports', ['exports', 'frontend/mixins/debounce-query-params', 'moment', 'frontend/config/environment'], function (exports, _debounceQueryParams, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        user: Ember.inject.service(),
        locale: Ember.inject.service(),

        queryParams: ['searchFromDate', 'searchToDate', 'searchTypeTemplate', 'searchWord'],

        searchFromDate: null,
        searchToDate: null,
        searchTypeTemplate: "sms",
        searchWord: null,

        report: '',

        filteredModel: Ember.computed('model', 'model.[]', 'model.@each.isChecked', function () {
            if (this.get('model').get('length') > 0) {
                var checkedCampaigns = this.get('model').filterBy('isChecked').length;
                return this.get('model.length') > 0 && checkedCampaigns > 0;
            }
            return false;
        }),

        allChecked: Ember.computed('model', 'model.[]', 'model.@each.isChecked', function () {
            var selectedCampaigns = false;

            if (this.get('model').get('length') > 0) {
                var checkedCampaigns = this.get('model').filterBy('isChecked').length;
                selectedCampaigns = checkedCampaigns === this.get('model').get('length');
            }

            return selectedCampaigns;
        }),

        dateFrom: Ember.computed('display_searchFromDate', function () {
            var date = (0, _moment.default)(this.get('display_searchFromDate'));
            return date.isValid() ? date : '';
        }),

        dateTo: Ember.computed('display_searchToDate', function () {
            var date = (0, _moment.default)(this.get('display_searchToDate'));
            return date.isValid() ? date : '';
        }),

        templateType: Ember.computed('templateTypes', function () {
            if (this.get('searchTypeTemplate') && this.get('searchTypeTemplate') === 'voice') {
                return this.get('templateTypes')[1];
            }
            return this.get('templateTypes')[0];
        }),

        templateTypes: Ember.computed('i18n.locale', 'config.showVoice', function () {
            var templateTypes = [{
                value: 'sms',
                label: '' + this.get('i18n').t('reports.sms-template-type')
            }, {
                value: 'voice',
                label: '' + this.get('i18n').t('reports.voice-template-type')
            }];

            if (!_environment.default.showVoice) {
                templateTypes[1].disabled = true;
            }

            return templateTypes;
        }),

        actions: {
            changeInputDateFrom: function changeInputDateFrom(date) {
                if (date === '') {
                    this.debounceSetQueryParam('searchFromDate', date);
                } else {
                    if ((0, _moment.default)(date).isValid()) {
                        this.debounceSetQueryParam('searchFromDate', date.format());
                    }
                }
            },
            changeInputDateTo: function changeInputDateTo(date) {
                if (date === '') {
                    this.debounceSetQueryParam('searchToDate', date);
                } else {
                    if ((0, _moment.default)(date).isValid()) {
                        this.debounceSetQueryParam('searchToDate', date.format());
                    }
                }
            }
        }

    });
});