define('frontend/routes/inner-app/reports', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/infinite-scroll-route'], function (exports, _authenticatedRouteMixin, _infiniteScrollRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _infiniteScrollRoute.default, {

        queryParams: {
            searchFromDate: {
                refreshModel: true
            },
            searchToDate: {
                refreshModel: true
            },
            searchTypeTemplate: {
                refreshModel: true
            },
            searchWord: {
                refreshModel: true
            }
        },

        requestParams: {
            page: 1,
            per_page: 50,
            filter: {}
        },

        modelType: 'report',
        modelGroupType: '',
        modelGroupRelation: '',

        model: function model(params) {
            var _this = this;

            this.set('requestParams.filter', {
                word: params.searchWord,
                dateFrom: params.searchFromDate,
                dateTo: params.searchToDate,
                typeTemplate: params.searchTypeTemplate
            });
            this.set('requestParams.include', 'template');

            return this.getPage(this.get('requestParams')).then(function (result) {
                _this.set('totalPages', result.get('meta.pagination.total_pages'));
                return result.toArray();
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.initDebouncedQueryParams(['searchFromDate', 'searchToDate', 'searchTypeTemplate', 'searchWord']);
            model.setEach('isChecked', false);
        },
        getNextPage: function getNextPage() {
            var _this2 = this;

            var allChecked = this.controller.get('allChecked');

            return this._super.apply(this, arguments).then(function () {
                if (allChecked) {
                    _this2.getModel().setEach('isChecked', true);
                }
            });
        },


        actions: {
            selectTemplateType: function selectTemplateType(type) {
                this.controller.set('templateType', type);
                this.controller.debounceSetQueryParam('searchTypeTemplate', type.value);
            },
            checkAllReports: function checkAllReports(checked) {
                this.modelFor(this.routeName).setEach('isChecked', checked);
            },
            checkCampaign: function checkCampaign(report, checked) {
                report.set('isChecked', checked);
            }
        }

    });
});