define('frontend/components/calendar-day', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        moment: service(),

        tagName: 'button',

        classNames: ['ember-power-calendar-day--current-month', 'ember-power-calendar-day'],

        classNameBindings: ['isCurrentDay:ember-power-calendar-day--today', 'isSelected:ember-power-calendar-day--selected', 'isOtherMonth:ember-power-calendar-day--other-month'],

        day: null,
        calendarCenter: null,

        isCurrentDay: Ember.computed('day', function () {
            return this.get('day.isCurrentDay');
        }),

        isOtherMonth: Ember.computed('day', function () {
            return !this.get('moment').moment(this.get('day.moment')).isSame(this.get('calendarCenter'), 'month') && this.get('moment').moment(this.get('day.moment')).isSame(this.get('calendarCenter'), 'year');
        }),

        isSelected: false,

        click: function click() {
            this.sendAction('onSelect', this.get('moment').moment(this.get('day.moment')));
        },
        doubleClick: function doubleClick() {
            this.sendAction('openCalendar');
        },


        actions: {
            openCalendar: function openCalendar() {
                this.sendAction('openCalendar');
            }
        }

    });
});