define('frontend/components/guest/password-reset-form', ['exports', 'frontend/components/guest/base-form', 'ember-network/fetch', 'ember-cp-validations'], function (exports, _baseForm, _fetch, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.reset-email-present'
        }), (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'validation.reset-email-format'
        }), (0, _emberCpValidations.validator)('length', {
            max: 255,
            messageKey: 'validation.reset-email-length'
        })
        // should have unique as well
        ],
        password: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.reset-password-present'
        }), (0, _emberCpValidations.validator)('length', {
            min: 6,
            messageKey: 'validation.reset-password-length'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_+]{6,}$/,
            message: 'validation.reset-password-format'
        })],
        password_confirmation: [(0, _emberCpValidations.validator)('confirmation', {
            on: 'password',
            message: 'validation.password-mismatch'
        })]
    });

    exports.default = _baseForm.default.extend(Validations, {
        token: null, // passed in
        email: null, //passed in,
        showMessage: false,
        actions: {
            resetPassword: function resetPassword() {
                var _this = this;

                this.validate().then(function (_ref) {
                    var validations = _ref.validations;

                    if (validations.get('isValid')) {
                        (0, _fetch.default)('/api/v1/auth/password/reset', {
                            method: 'POST',
                            body: JSON.stringify({
                                email: _this.get('email'),
                                password: _this.get('password'),
                                password_confirmation: _this.get('password_confirmation'),
                                token: _this.get('token')
                            }),
                            headers: {
                                "Content-Type": "application/vnd.api+json",
                                "Accept": "application/vnd.api+json"
                            }
                        }).then(function (response) {
                            if (response.ok) {
                                _this.set('showMessage', true);
                                var transitionToLogin = _this.$(window).width() > 1023;
                                _this.sendAction('action', transitionToLogin);
                            } else {
                                return response.json().then(function (json) {
                                    return _this.handleErrors(json);
                                });
                            }
                        });
                    }
                });
            }
        }
    });
});