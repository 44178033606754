define('frontend/controllers/inner-app/api-reports', ['exports', 'frontend/mixins/debounce-query-params', 'moment'], function (exports, _debounceQueryParams, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
    user: Ember.inject.service(),
    locale: Ember.inject.service(),

    queryParams: ['fromDate', 'toDate'],

    fromDate: null,
    toDate: null,

    report: '',

    filteredModel: Ember.computed('model', 'model.[]', 'model.@each.isChecked', function () {
      if (this.get('model').get('length') > 0) {
        var checkedCampaigns = this.get('model').filterBy('isChecked').length;
        return this.get('model.length') > 0 && checkedCampaigns > 0;
      }
      return false;
    }),

    allChecked: Ember.computed('model', 'model.[]', 'model.@each.isChecked', function () {
      var selectedCampaigns = false;

      if (this.get('model').get('length') > 0) {
        var checkedCampaigns = this.get('model').filterBy('isChecked').length;
        selectedCampaigns = checkedCampaigns === this.get('model').get('length');
      }

      return selectedCampaigns;
    }),

    dateFrom: Ember.computed('display_fromDate', function () {
      var date = (0, _moment.default)(this.get('display_fromDate'));
      return date.isValid() ? date : '';
    }),

    dateTo: Ember.computed('display_toDate', function () {
      var date = (0, _moment.default)(this.get('display_toDate'));
      return date.isValid() ? date : '';
    }),

    actions: {
      changeInputDateFrom: function changeInputDateFrom(date) {
        if (date === '') {
          this.debounceSetQueryParam('fromDate', date);
        } else {
          if ((0, _moment.default)(date).isValid()) {
            this.debounceSetQueryParam('fromDate', date.format());
          }
        }
      },
      changeInputDateTo: function changeInputDateTo(date) {
        if (date === '') {
          this.debounceSetQueryParam('toDate', date);
        } else {
          if ((0, _moment.default)(date).isValid()) {
            this.debounceSetQueryParam('toDate', date.format());
          }
        }
      }
    }

  });
});