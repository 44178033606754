define('frontend/components/guest/register-form', ['exports', 'frontend/components/guest/base-form', 'ember-network/fetch', 'ember-cp-validations'], function (exports, _baseForm, _fetch, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validations = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.register-email-present'
        }), (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'validation.register-email-format'
        }), (0, _emberCpValidations.validator)('length', {
            max: 255,
            messageKey: 'validation.register-email-length'
        })
        // should have unique as well
        ],
        password: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.register-password-present'
        }), (0, _emberCpValidations.validator)('length', {
            min: 6,
            messageKey: 'validation.register-password-length'
        }), (0, _emberCpValidations.validator)('format', {
            regex: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*_+-]{6,}$/,
            message: 'validation.register-password-format'
        })],
        tos: [(0, _emberCpValidations.validator)('inclusion', {
            in: [true],
            message: 'validation.register-agree-terms'
        })]
    }, {
        debounce: 500
    });

    exports.default = _baseForm.default.extend(Validations, {
        locale: Ember.inject.service(),
        email: null,
        password: null,
        tos: null,

        actions: {
            registerUser: function registerUser() {
                var _this = this;

                this.validate().then(function (_ref) {
                    var validations = _ref.validations;

                    if (validations.get('isValid')) {
                        (0, _fetch.default)('/api/v1/auth/register', {
                            method: 'POST',
                            body: JSON.stringify({
                                email: _this.get('email'),
                                password: _this.get('password'),
                                tos: _this.get('tos'),
                                lang: _this.get('locale.curentLocale'),
                                g_recaptcha_response: _this.get('reCaptchaResponse')
                            }),
                            headers: {
                                "Content-Type": "application/vnd.api+json",
                                "Accept": "application/vnd.api+json"
                            }
                        }).then(function (response) {
                            if (response.ok) {
                                _this.get('flashMessages').success(_this.get('i18n').t('guest.success.register'));
                            } else {
                                response.json().then(function (json) {
                                    return _this.handleErrors(json);
                                });
                            }
                            _this.resetRecaptcha();
                        });
                    } else {
                        _this.set('didValidate', true);
                    }
                });
            }
        }
    });
});