define('frontend/models/help-category', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: (0, _attr.default)(),

        helpEntries: (0, _relationships.hasMany)('help-entry')
    });
});