define('frontend/transforms/moment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = _emberData.default.Transform.extend({
        moment: service(),
        deserialize: function deserialize(serialized) {
            return this.get('moment').moment(serialized);
        },
        serialize: function serialize(deserialized) {
            return deserialized ? deserialized.format() : '';
        }
    });
});