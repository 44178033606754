define('frontend/components/progress-bar', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        classNames: ['ember-progress-bar-component'],

        progressText: Ember.computed('status', function () {
            return {
                value: this.get('status'),
                className: 'progressbar-pers-text'
            };
        })
    });
});