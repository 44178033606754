define("frontend/utils/parse-error-status", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = parseErrorStatus;
    function parseErrorStatus(error, status) {
        return parseInt(error) === status;
    }
});