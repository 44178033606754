define('frontend/serializers/custom-field-value', ['exports', 'ember-data/serializers/json'], function (exports, _json) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _json.default.extend({
        attrs: {
            fieldId: 'id'
        }
    });
});