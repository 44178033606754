define('frontend/components/contact-group-li', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        tagName: '',
        specialItems: service(),
        searchGroup: null, // passed in,

        init: function init() {
            this._super.apply(this, arguments);
            Ember.defineProperty(this, 'contactsCount', Ember.computed('specialItems.updated', function () {
                return this.get('specialItems').count('contact', this.get('group.id'));
            }));
        },


        actions: {
            deleteGroup: function deleteGroup(contactGroup) {
                this.sendAction('deleteGroup', contactGroup);
            },
            saveGroup: function saveGroup(contactGroup) {
                this.sendAction('saveGroup', contactGroup);
            },
            checkAction: function checkAction(group, checked) {
                this.sendAction('checkContacts', group, checked);
            }
        }
    });
});