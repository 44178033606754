define('frontend/components/selection-checkbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        classNameBindings: ['isPartiallySelected:partially-selected'],
        selectedItems: Ember.inject.service(),
        specialItems: Ember.inject.service(),
        tagName: 'label',
        classNames: ['control', 'control--checkbox'],
        nameAttr: computed('type', 'item', function () {
            return 'selectionCheckbox-' + this.get('type') + '-' + this.get('item.id');
        }),
        isChecked: computed('selectedItems.updated', 'specialItems.updated', 'item.isChecked', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');
            var item = this.get('item');
            var itemId = this.get('item').get('id');

            if (this.get('type') === 'group') {
                var itemType = '';
                if (this.get('modelName') === 'template-group') {
                    itemType = 'template';
                } else {
                    itemType = 'contact';
                }

                if (specialItems.count(itemType, itemId) === 0) {
                    //if group just become empty (we deleted the last item...)
                    if (specialItems.recentlyEmptyGroup(itemType, itemId)) {
                        item.set('isChecked', false);
                        return false;
                    }
                    return item.get('isChecked');
                }

                var groupItemsIds = specialItems.getIdsArray(itemType, itemId);

                var isPartiallySelected = selectedItems.someSelected(groupItemsIds);

                //check if group is selected only if it is partially selected
                if (isPartiallySelected) {
                    item.set('isChecked', selectedItems.areSelected(groupItemsIds));
                    return item.get('isChecked');
                } else {
                    return isPartiallySelected;
                }
            }

            return selectedItems.isSelected(itemId);
        }),

        isPartiallySelected: computed('selectedItems.updated', 'specialItems.updated', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            if (this.get('type') === 'group') {
                var itemType = 'contact';
                if (this.get('modelName') === 'template-group') {
                    itemType = 'template';
                }
                return selectedItems.someSelected(specialItems.getIdsArray(itemType, this.get('item.id')));
            }
            return false;
        }),

        actions: {
            check: function check(checked) {
                var selectedItems = this.get('selectedItems');
                var itemId = this.get('item').get('id');

                if (this.get('checkAction')) {
                    return this.get('checkAction')(checked);
                }

                if (checked) {
                    selectedItems.add(itemId);
                } else {
                    selectedItems.remove(itemId);
                }
            }
        }
    });
});