define('frontend/components/settings/main-component', ['exports', 'frontend/mixins/user-settings', 'frontend/static-data/timezones'], function (exports, _userSettings, _timezones) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {

        language: null,
        dateFormat: null,
        timeFormat: null,
        weekdayStart: null,

        languages: [],
        timezones: _timezones.default,
        timezone: Ember.computed('user.data.timezone', function () {
            var _this = this;

            return this.get('timezones').filter(function (el) {
                return el.value === _this.get('user.data.timezone') ? true : false;
            })[0];
        }),
        dateFormats: [{
            format: 'DD/MM/YYYY',
            label: '23/06/2016'
        }, {
            format: 'DD/MM/YY',
            label: '23/06/16'
        }, {
            format: 'DD.MM.YYYY',
            label: '23.06.2016'
        }, {
            format: 'DD.MM.YY',
            label: '23.06.16'
        }, {
            format: 'MM/DD/YYYY',
            label: '06/23/2016'
        }, {
            format: 'MM/DD/YY',
            label: '06/23/16'
        }, {
            format: 'Do MMM YYYY',
            label: 'settings.main.date-format-translate'
        }],
        days: [{
            value: 1,
            label: 'settings.main.days.mo'
        }, {
            value: 2,
            label: 'settings.main.days.tu'
        }, {
            value: 3,
            label: 'settings.main.days.we'
        }, {
            value: 4,
            label: 'settings.main.days.th'
        }, {
            value: 5,
            label: 'settings.main.days.fr'
        }, {
            value: 6,
            label: 'settings.main.days.sa'
        }, {
            value: 0,
            label: 'settings.main.days.su'
        }],
        timeFormats: [{
            format: 'HH:mm',
            label: '15:37'
        }, {
            format: 'hh:mma',
            label: '03:37 am'
        }],

        didRender: function didRender() {
            this.set('languages', this.get('locale').languages);
            this.set('weekdayStart', this.get('days').findBy('value', this.get('user.data.weekdayStart')));
            this.set('language', this.get('languages').findBy('name', this.get('user.data.language')));
            this.set('dateFormat', this.get('dateFormats').findBy('format', this.get('user.data.dateFormat')));
            this.set('timeFormat', this.get('timeFormats').findBy('format', this.get('user.data.timeFormat')));
        },


        isAmPmTimeFormat: Ember.computed('user.data.timeFormat', function () {
            return this.get('user.data.timeFormat') === 'hh:mma';
        }),
        dndFrom: Ember.computed('user.data.dndFrom', function () {
            var time = this.get('user.data.dndFrom');
            if (typeof time === 'string' && time.length) {
                var split = time.split(':');
                return {
                    hours: parseInt(split[0]),
                    minutes: parseInt(split[1])
                };
            }
            return {
                hours: undefined,
                minutes: undefined
            };
        }),
        dndTo: Ember.computed('user.data.dndTo', function () {
            var time = this.get('user.data.dndTo');
            if (typeof time === 'string' && time.length) {
                var split = time.split(':');
                return {
                    hours: parseInt(split[0]),
                    minutes: parseInt(split[1])
                };
            }
            return {
                hours: undefined,
                minutes: undefined
            };
        }),

        actions: {
            saveLanguage: function saveLanguage(language) {
                this.set('language', language);
                this.set('reloadHelp', true);
                this.changeUserData({ name: 'language', value: language.name });
            },
            saveTimezone: function saveTimezone(timezone) {
                this.set('timezone', timezone);
                this.changeUserData({ name: 'timezone', value: timezone.value });
            },
            saveDateFormat: function saveDateFormat(dateFormat) {
                this.set('dateFormat', dateFormat);
                this.changeUserData({ name: 'dateFormat', value: dateFormat.format });
            },
            saveTimeFormat: function saveTimeFormat(timeFormat) {
                this.set('timeFormat', timeFormat);
                this.changeUserData({ name: 'timeFormat', value: timeFormat.format });
            },
            saveWeekdayStart: function saveWeekdayStart(day) {
                this.set('weekdayStart', day);
                this.changeUserData({ name: 'weekdayStart', value: day.value });
            },
            changeDnd: function changeDnd(fieldName, event) {
                if (fieldName !== 'dndFrom' && fieldName !== 'dndTo') {
                    return;
                }
                var newDnd = event.hours + ':' + event.minutes;
                if (newDnd.indexOf('undefined') !== -1) {
                    return;
                }
                this.changeUserData({ name: fieldName, value: newDnd });
            }
        }

    });
});