define('frontend/routes/inner-app/events/contact-groups/contacts', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/infinite-scroll-route'], function (exports, _authenticatedRouteMixin, _infiniteScrollRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _infiniteScrollRoute.default, {
        templateName: 'inner-app/events/contact-groups/contacts',
        selectedItems: Ember.inject.service(),
        specialItems: Ember.inject.service(),
        queue: Ember.inject.service(),
        ajax: Ember.inject.service(),

        queryParams: {
            search: {
                refreshModel: true
            }
        },

        modelType: 'contact',
        modelGroupType: 'contact-groups',
        modelGroupRelation: 'contacts',

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var params = this.getParams();
            controller.set('groupId', params.groupId);
            controller.initDebouncedQueryParam('search');
            controller.set('campaign', this.modelFor('inner-app.events'));
        },
        modelLoad: function modelLoad(params) {
            var specialItems = this.get('specialItems');
            this.get('queue').add(function () {
                return specialItems.loadSearch('contact', params);
            }, 'contact');
        },

        actions: {
            checkAll: function checkAll(checked) {
                var _this = this;

                var groupId = this.getParams().groupId;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    var promises = [];
                    if (_this.shouldDoGeneralQuery(groupId)) {
                        if (groupId === 'all') {
                            var groups = _this.modelFor('inner-app.events.contact-groups');
                            if (groups) {
                                groups.forEach(function (group) {
                                    promises.push(specialItems.getIds('contact', group.get('id'), 1).then(function (ids) {
                                        if (checked) {
                                            selectedItems.addMany(ids);
                                        } else {
                                            selectedItems.removeMany(ids);
                                        }
                                    }));
                                });
                            }
                        }
                        if (groupId === 'ungrouped' || groupId === 'all') {
                            promises.push(specialItems.getIds('contact', 'no-group', 1).then(function (ids) {
                                if (checked) {
                                    selectedItems.addMany(ids);
                                } else {
                                    selectedItems.removeMany(ids);
                                }
                            }));
                        }
                        if (groupId === 'google' || groupId === 'all') {
                            promises.push(specialItems.getIds('contact', 'google', 1).then(function (ids) {
                                if (checked) {
                                    selectedItems.addMany(ids);
                                } else {
                                    selectedItems.removeMany(ids);
                                }
                            }));
                        }
                    } else {
                        promises.push(specialItems.getIds('contact', groupId, 1).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        }));
                    }
                    return Ember.RSVP.all(promises);
                }, 'contact');
            }
        }
    });
});