define('frontend/models/payment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        subscriptionId: (0, _attr.default)(),
        companyId: (0, _attr.default)(),
        userId: (0, _attr.default)(),
        planId: (0, _attr.default)(),
        status: (0, _attr.default)(),
        price: (0, _attr.default)(),
        cardType: (0, _attr.default)(),
        cardNumber: (0, _attr.default)(),
        hookKind: (0, _attr.default)(),
        createdAt: (0, _attr.default)(),
        plan: _emberData.default.belongsTo('plan')
    });
});