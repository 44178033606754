define("frontend/routes/tos", ["exports", "frontend/mixins/routable-body-class"], function (exports, _routableBodyClass) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routableBodyClass.default, {
        i18n: Ember.inject.service(),

        bodyClass: 'guest_wrapper',

        actions: {
            setLanguage: function setLanguage(lang) {
                this.set('i18n.locale', lang);
            }
        }

    });
});