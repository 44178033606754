define('frontend/components/layout/header-notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service,
        computed = Ember.computed;
    exports.default = Ember.Component.extend({
        tagName: '',
        user: service(),
        specialItems: service(),
        notifications: Ember.A([]),
        store: service(),
        pusher: service(),
        i18n: service(),
        modal: service(),
        moment: service(),

        counter: computed('notifications.length', 'isOnFreePlan', function () {
            var count = this.get('notifications.length');
            if (this.get('isOnFreePlan')) {
                count = count + 1;
            }
            return count > 9 ? '9+' : count;
        }),

        isOnFreePlan: false,

        generateSuccessNotifications: function generateSuccessNotifications(text) {
            return {
                type: 'success',
                icon: 'icon-notification-success',
                text: text
            };
        },
        generateErrorNotifications: function generateErrorNotifications(text) {
            return {
                type: 'error',
                icon: 'icon-notification-error',
                text: text
            };
        },
        generateNoticeNotifications: function generateNoticeNotifications(text) {
            return {
                type: 'notice',
                icon: 'icon-notification-notice',
                text: text
            };
        },
        generateInfoNotifications: function generateInfoNotifications(text) {
            return {
                type: 'info',
                icon: 'icon-notification-info',
                text: text
            };
        },
        init: function init() {
            var notificationDay = this.get('moment').moment("2018-04-01");
            this.get('pusher').bind('import-finished', 'header-notifications-onImportFinished', this.get('onImportFinished').bind(this));
            this.get('pusher').bind('campaign-prepared', 'header-notifications-onCampaignPrepared', this.get('onCampaignPrepared').bind(this));
            if (this.get('moment').moment().isBefore(notificationDay) && !this.get('user.data.newAccount')) {
                this.newSubscriptionPlansNotification();
            }
            this._super.apply(this, arguments);
        },
        newSubscriptionPlansNotification: function newSubscriptionPlansNotification() {
            if (this.get('user.data.currentCompany.plan.id') === 'free') {
                this.set('isOnFreePlan', true);
            } else {
                this.get('notifications').pushObject(this.generateNoticeNotifications(this.get('i18n').t('notifications.active-plan-notice')));
            }
        },
        onImportFinished: function onImportFinished(data) {
            this.get('notifications').pushObject(this.generateSuccessNotifications(this.get('i18n').t('notifications.realtime.import-finished', { count: data.imported })));
        },
        onCampaignPrepared: function onCampaignPrepared(data) {
            this.get('notifications').pushObject(this.generateSuccessNotifications(this.get('i18n').t('notifications.realtime.campaign-prepared', { name: data.campaignName })));
            var campaign = this.get('store').peekRecord('campaign', data.campaignId);
            if (campaign) {
                campaign.reload();
            } else {
                this.get('store').findRecord('campaign', data.campaignId);
            }
        },
        willDestroyElement: function willDestroyElement() {
            this.get('pusher').unbind('import-finished', 'header-notifications-onImportFinished', this.get('onImportFinished').bind(this));
            this.get('pusher').unbind('campaign-prepared', 'header-notifications-onCampaignPrepared', this.get('onCampaignPrepared').bind(this));
        },


        actions: {
            removeNotification: function removeNotification(notification) {
                this.get('notifications').removeObject(notification);
            },
            changeDialog: function changeDialog(templatePath) {
                this.get('modal').destroyAllModals();
                this.get('modal').settings({
                    template: '' + templatePath
                });
            },
            closeNotice: function closeNotice() {
                this.set('isOnFreePlan', false);
            }
        }
    });
});