define('frontend/routes/inner-app/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/unsaved-data-transition-block', 'moment', 'frontend/config/environment'], function (exports, _authenticatedRouteMixin, _unsavedDataTransitionBlock, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedDataTransitionBlock.default, {
        user: Ember.inject.service(),
        specialItems: Ember.inject.service(),
        queue: Ember.inject.service(),

        model: function model() {
            var startDate = (0, _moment.default)().subtract(1, 'months').format();
            var endDate = (0, _moment.default)().add(1, 'months').format();

            return Ember.RSVP.hash({
                events: this.get('store').query('campaign', {
                    include: 'template',
                    filter: { scheduled_after: startDate, scheduled_before: endDate, useStatistic: "yes" }
                })
            });
        },
        pollEvent: function pollEvent(event, delay) {
            var _this = this;

            // TODO: UGLY HACK. WE NEED TO DEAL WITH THOSE PESKY TIMERS.
            if (_environment.default.environment === 'test') {
                return;
            }

            Ember.run.later(function () {
                if (event.get('isDeleted')) {
                    return;
                }
                event.reload().then(function (result) {
                    if (!result.get('hasRun')) {
                        _this.pollEvent(event, 60000);
                    }
                });
            }, delay);
        },
        afterModel: function afterModel(model) {
            var _this2 = this;

            model.events.map(function (event) {
                if (!event.get('hasRun')) {
                    _this2.pollEvent(event, event.get('sendDate').diff((0, _moment.default)()) + 30000);
                }
            });
        },
        activate: function activate() {
            var _this3 = this;

            this.get('store').query('contact', { per_page: 1 }).then(function (contacts) {
                _this3.controller.set('countContacts', contacts.get('meta.pagination.total'));
            });
            this.get('store').query('template', { per_page: 1 }).then(function (templates) {
                _this3.controller.set('countTemplates', templates.get('meta.pagination.total'));
            });

            var specialItems = this.get('specialItems');
            this.get('queue').add(function () {
                return specialItems.initialize('template');
            }, 'template');
            this.get('queue').add(function () {
                return specialItems.initialize('contact');
            }, 'contact');
        },


        actions: {
            willTransition: function willTransition() {
                this.controller.send('changeStateCalendarTransition');
            },
            didTransition: function didTransition() {
                this.get('user').pollPlan();
            }
        }
    });
});