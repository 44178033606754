define('frontend/components/actions-notification', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            close: function close() {
                var elem = this.$();
                elem.addClass('exiting').delay(500).queue(function () {
                    elem.remove().dequeue();
                });
            }
        }
    });
});