define('frontend/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    router: service(),
    unsupportedBrowser: false,
    isResetPasswordPage: false,
    unsupportedDevice: false,

    showForm: computed('isResetPasswordPage', function () {
      return this.get('isResetPasswordPage');
    }),

    init: function init() {
      var isSupportedOs = bowser.windows || bowser.mac || bowser.linux || bowser.ipad || bowser.android && bowser.tablet && bowser.chrome;

      var isUnsupportedBrowser = bowser.isUnsupportedBrowser({
        msie: '10',
        msedge: '14',
        safari: '10'
      }, window.navigator.userAgent);

      this.set('unsupportedDevice', !isSupportedOs || isUnsupportedBrowser);
    }
  });
});