define('frontend/components/cookie-consent', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        i18n: Ember.inject.service(),

        cookieConsentChangeLanguage: Ember.observer('i18n.locale', function () {
            Ember.$('.cc-message').html(this.get('i18n').t('cookieconsent.message').string + '<a aria-label="learn more about cookies" role=button tabindex="0" class="cc-link" href="' + this.get('i18n').t('cookieconsent.href').string + '" target="_blank">' + this.get('i18n').t('cookieconsent.link').string + '</a>');
            Ember.$('.cc-dismiss').html(this.get('i18n').t('cookieconsent.dismiss').string);
        }),

        didInsertElement: function didInsertElement() {
            this._super();

            window.cookieconsent.initialise({
                "palette": {
                    "popup": {
                        "background": "#237afc"
                    },
                    "button": {
                        "background": "#fff",
                        "text": "#237afc"
                    }
                },
                "content": {
                    "header": "Cookies used on the website!",
                    "message": this.get('i18n').t('cookieconsent.message').string,
                    "dismiss": this.get('i18n').t('cookieconsent.dismiss').string,
                    "allow": "Allow cookies",
                    "deny": "Decline",
                    "link": this.get('i18n').t('cookieconsent.link').string,
                    "href": this.get('i18n').t('cookieconsent.href').string,
                    "close": "&#x274c;"
                }
            });
        }
    });
});