define('frontend/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        email: (0, _attr.default)(),
        firstName: (0, _attr.default)(),
        lastName: (0, _attr.default)(),
        language: (0, _attr.default)(),
        timezone: (0, _attr.default)(),
        dateFormat: (0, _attr.default)(),
        timeFormat: (0, _attr.default)(),
        weekdayStart: (0, _attr.default)(),
        dndFrom: (0, _attr.default)(),
        dndTo: (0, _attr.default)(),
        reminderCampaignStart: (0, _attr.default)('boolean'),
        reminderCampaignEnd: (0, _attr.default)('boolean'),
        confirmDelete: (0, _attr.default)('boolean'),
        city: (0, _attr.default)(),
        address: (0, _attr.default)(),
        newAccount: (0, _attr.default)(),
        acceptedTerms: (0, _attr.default)(),

        companies: (0, _relationships.hasMany)('company', { async: true }),

        currentCompany: Ember.computed('companies', function () {
            return this.get('companies').objectAt(0);
        })
    });
});