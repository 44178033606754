define('frontend/lib/pset', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var USE_SET = "Set" in window;

    /**
     * Thin wrapper around the native Set class to allow using in computed properties
     * From Povikvane Set, until there's a native Ember alternative.
     */
    exports.default = Ember.Object.extend({
        _list: null,
        size: 0,

        init: function init() {
            var initArray = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            if (USE_SET) {
                this.set('_list', new Set(initArray));
                this.set('size', this.get('_list').size);
                this.add = this._addSet;
                this.has = this._hasSet;
                this.delete = this._deleteSet;
                this.clear = this._clearSet;
                this.items = this._itemsSet;
            } else {
                this.set('_list', initArray);
                this.set('size', this.get('_list').length);
                this.add = this._addArray;
                this.has = this._hasArray;
                this.delete = this._deleteArray;
                this.clear = this._clearArray;
                this.items = this._itemsArray;
            }
        },
        _addSet: function _addSet(item) {
            this.get('_list').add(item);
            this.set('size', this.get('_list').size);
        },
        _addArray: function _addArray(item) {
            if (this.get('_list').indexOf(item) < 0) {
                this.get('_list').push(item);
                this.set('size', this.get('_list').length);
            }
        },
        _hasSet: function _hasSet(item) {
            return this.get('_list').has(item);
        },
        _hasArray: function _hasArray(item) {
            return this.get('_list').indexOf(item) >= 0;
        },
        _deleteSet: function _deleteSet(item) {
            this.get('_list').delete(item);
            this.set('size', this.get('_list').size);
        },
        _deleteArray: function _deleteArray(item) {
            var pos = this.get('_list').indexOf(item);
            if (pos >= 0) {
                this.get('_list').splice(pos, 1);
            }
        },
        _clearSet: function _clearSet() {
            this.set('size', 0);
            this.get('_list').clear();
        },
        _clearArray: function _clearArray() {
            this.set('size', 0);
            this.set('_list', []);
        },
        _itemsSet: function _itemsSet() {
            return Array.from(this.get('_list'));
        },
        _itemsArray: function _itemsArray() {
            return this.get('_list');
        }
    });
});