define('frontend/instance-initializers/ember-dialog', ['exports', 'frontend/config/environment', 'ember-dialog/configuration'], function (exports, _environment, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    _configuration.default.load(_environment.default);
    application.inject('controller', 'dialog', 'service:dialog');
  }

  exports.default = {
    name: 'ember-dialog',
    initialize: initialize
  };
});