define("frontend/utils/i18n/missing-message", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (locale, key /*, context*/) {
        return key;
    };
});