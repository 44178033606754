define('frontend/models/api-report', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    sendAt: (0, _attr.default)('moment'),
    status: (0, _attr.default)('string'),
    month: (0, _attr.default)(),
    year: (0, _attr.default)(),
    period: computed('month', 'year', function () {
      var month = this.get('month') === undefined ? '' : this.get('month');
      var year = this.get('year') === undefined ? '' : this.get('year');
      return month + '-' + year;
    }),
    isChecked: (0, _attr.default)('boolean', { defaultValue: false }),
    deliveryStatistic: (0, _attr.default)()
  });
});