define('frontend/models/api-key', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model;
    exports.default = Model.extend({
        key: (0, _attr.default)('string')
    });
});