define('frontend/models/import-result', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        meta: (0, _attr.default)(),
        backgroundProcessing: (0, _attr.default)('boolean', { default: false }),
        invalid: (0, _attr.default)({ defaultValue: function defaultValue() {
                return [];
            } })
    });
});