define('frontend/models/report', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        addZero: function addZero(dateToAddZero) {
            if (dateToAddZero < 10) {
                dateToAddZero = "0" + dateToAddZero;
            }
            return dateToAddZero;
        },

        name: (0, _attr.default)('string'),
        sendDate: (0, _attr.default)('moment'),
        scheduled: (0, _attr.default)(),
        template: (0, _relationships.belongsTo)('template'),
        deliveryStatistic: (0, _attr.default)(),
        type: (0, _attr.default)('string'),
        isChecked: (0, _attr.default)('boolean', { defaultValue: false })
    });
});