define('frontend/controllers/inner-app/template-groups', ['exports', 'frontend/mixins/debounce-query-params'], function (exports, _debounceQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        session: service(),
        selectedItems: service(),
        specialItems: service(),

        queryParams: ['searchTmplGroup'],

        countAllTemplates: computed('specialItems.updated', function () {
            return this.get('specialItems').count('template', 'all');
        }),
        countUngroupedTemplates: computed('specialItems.updated', function () {
            return this.get('specialItems').count('template', 'no-group');
        }),

        allGroupsChecked: computed('selectedItems.updated', 'model', 'model.[]', function () {
            var normalGroupsSelected = false;

            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            if (this.get('model').get('length') > 0) {
                normalGroupsSelected = this.get('model').reduce(function (carry, group) {
                    if (specialItems.count('template', group.get('id'))) {
                        return carry && selectedItems.areSelected(specialItems.getIdsArray('template', group.get('id')));
                    }
                    return carry && group.get('isChecked');
                }, true);
            }

            if (normalGroupsSelected && !this.searchTmplGroup) {
                if (!selectedItems.areSelected(specialItems.getIdsArray('template', 'no-group'))) {
                    return false;
                }
            }
            return normalGroupsSelected;
        })
    });
});