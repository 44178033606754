define('frontend/models/method', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        type: (0, _attr.default)(),
        token: (0, _attr.default)(),
        cardType: (0, _attr.default)(),
        cardNumber: (0, _attr.default)(),
        email: (0, _attr.default)()
    });
});