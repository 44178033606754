define('frontend/components/audio-recorder', ['exports', 'frontend/config/environment', 'ember-uploader'], function (exports, _environment, _emberUploader) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        uploadPath: null,
        recorder: null,
        stream: null,
        recording: false,
        canRecordAudio: false,
        maxLength: 60,
        notifications: Ember.inject.service(),
        ajax: Ember.inject.service(),
        headerLoadingIndicator: Ember.inject.service(),
        session: Ember.inject.service(),
        hifi: Ember.inject.service(),

        fullUploadPath: Ember.computed('uploadPath', function () {
            return '/api/v1/' + this.get('uploadPath');
        }),

        onMediaSuccess: function onMediaSuccess(stream) {
            var mediaRecorder = new MediaStreamRecorder(stream);
            mediaRecorder.mimeType = 'audio/wav';
            mediaRecorder.audioChannels = 1;
            mediaRecorder.ondataavailable = function (blob) {
                var _this = this;

                var file = new File([blob], 'msr.wav', {
                    type: 'audio/wav'
                });

                this.get('headerLoadingIndicator').start();
                stream.getAudioTracks()[0].stop();
                mediaRecorder.stop();

                var authHeader = 'Bearer ' + this.get('session').get('data.authenticated.token');
                var headers = {};
                headers[_environment.default.authorizationHeaderName] = authHeader;

                var uploader = _emberUploader.default.Uploader.create({
                    url: this.get('fullUploadPath'),
                    method: 'POST',
                    ajaxSettings: {
                        headers: headers
                    }
                });

                uploader.upload(file).then(function (response) {
                    var template = response.data;
                    _this.set('template.path', template.attributes.path);
                    _this.set('template.duration', template.attributes.duration);
                    _this.get('headerLoadingIndicator').stop();
                    _this.set('recording', false);
                }, function () {
                    _this.get('notifications').showWarning(_this.get('i18n').t('errors.upload-audio'));
                    _this.get('headerLoadingIndicator').stop();
                    _this.set('recording', false);
                });
            }.bind(this);
            mediaRecorder.start(this.get('maxLength') * 1000);
            this.set('recorder', mediaRecorder);
            this.set('stream', stream);
            this.set('recording', true);
        },
        onMediaError: function onMediaError() {
            this.get('notifications').showWarning(this.get('i18n').t('errors.microphone-access'));
        },


        actions: {
            toggleRecording: function toggleRecording() {
                if (this.get('recording')) {
                    this.get('recorder').stop();
                    this.get('stream').getAudioTracks()[0].stop();
                } else {
                    var mediaConstraints = {
                        audio: true
                    };
                    navigator.getUserMedia(mediaConstraints, this.onMediaSuccess.bind(this), this.onMediaError.bind(this));
                }
            }
        }
    });
});