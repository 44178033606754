define('frontend/mixins/allowed-urls', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Mixin.create({

        user: service(),
        router: service(),

        isUserDeleted: false,

        activate: function activate() {
            if (this.get('user.data.acceptedTerms')) {
                this.transitionTo('inner-app');
                return;
            }
        },


        currentRouteName: computed('router.currentRouteName', function () {
            return this.get('router.currentRouteName');
        }),

        isAllowedUrl: computed('currentRouteName', function () {
            return ['inner-app.accept-terms', 'inner-app.not-accepted-terms'].contains(this.get('currentRouteName'));
        }),

        actions: {
            willTransition: function willTransition(transition) {
                var isAllowedTransition = ['inner-app.accept-terms', 'inner-app.not-accepted-terms'].contains(transition.targetName);
                var conditions = !this.get('isUserDeleted') && !this.get('user.data.acceptedTerms') && !isAllowedTransition;

                if (conditions) {
                    transition.abort();
                }
            }
        }

    });
});