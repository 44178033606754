define('frontend/controllers/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend({
        showResendVerification: false,
        showBlockedAccount: false,
        email: null,
        session: service(),

        showVerificationSuccess: computed('verificationSuccess', function () {
            if (this.get('verificationSuccess')) {
                this.get('flashMessages').success(this.get('i18n').t('guest.verification-success'));
            }
        }),

        actions: {
            didLogIn: function didLogIn() {
                if (!this.get('session.data.seenWelcome')) {
                    this.transitionToRoute('inner-app.welcome');
                    return;
                }
                this.transitionToRoute('inner-app');
            },
            setShowBlockedAccount: function setShowBlockedAccount(arg) {
                this.set('showBlockedAccount', arg);
            },
            setShowResendVerification: function setShowResendVerification(arg) {
                this.set('showResendVerification', arg);
            }
        }
    });
});