define('frontend/routes/inner-app/welcome', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        activate: function activate() {
            if (this.get('session').get('data.seenWelcome')) {
                this.transitionTo('inner-app');
                return;
            }
            this.get('session').set('data.seenWelcome', true);
        }
    });
});