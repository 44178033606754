define('frontend/components/time-field', ['exports', 'ember-time-field/components/time-field'], function (exports, _timeField) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var isNone = Ember.isNone;


    function mod(n, m) {
        return (n % m + m) % m;
    }

    exports.default = _timeField.default.extend({
        incrementHours: function incrementHours() {
            var amnt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

            var hours = this.get("hours");
            if (isNone(hours)) {
                this.set("hours", 0);
            } else {
                this.set("hours", mod(hours + amnt, 24));
            }
            if (this.get('hour12')) {
                if (this.get("hours") >= 12) {
                    this.set('period', 'pm');
                } else {
                    this.set('period', 'am');
                }
            }
            this.valueChanged();
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super();

            var value = this.get("value");
            var hours = null;
            var minutes = null;
            var period = "am";

            if (value) {
                hours = Ember.get(value, "hours");
                minutes = Ember.get(value, "minutes");
                if (hours >= 12) {
                    period = "pm";
                }
            }

            if (typeof hours === 'string') {
                hours = parseInt(hours);
            }
            if (typeof minutes === 'string') {
                minutes = parseInt(minutes);
            }

            this.setProperties({
                hours: hours, minutes: minutes, period: period
            });
        },
        mouseDown: function mouseDown() {
            this.$('input').focus();
        }
    });
});