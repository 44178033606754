define('frontend/components/templates/groups-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['tmpl-groups-list', 'parent-list'],
        tagName: 'ul',
        groups: null,
        actions: {
            deleteGroup: function deleteGroup(tmpGroup) {
                this.sendAction('deleteGroup', tmpGroup);
            },
            saveGroup: function saveGroup(tmpGroup) {
                this.sendAction('saveGroup', tmpGroup);
            },
            checkTemplates: function checkTemplates(group, checked) {
                this.sendAction('checkTemplatesForGroup', group, checked);
            }
        }
    });
});