define('frontend/models/contact-group', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validators = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            ignoreBlank: true,
            message: 'validation.group-name-not-blank'
        }), (0, _emberCpValidations.validator)('ds-error')]
    }, {
        debounce: 500
    });

    var contactGroup = _model.default.extend(Validators, {
        name: (0, _attr.default)(),
        contactsCount: (0, _attr.default)('number', { defaultValue: 0 }),
        contactIds: (0, _attr.default)({
            defaultValue: function defaultValue() {
                return Ember.A([]);
            }
        }),

        isChecked: false,

        contacts: (0, _relationships.hasMany)('contact', { async: true }),
        isNotValid: Ember.computed.empty('name')
    });

    exports.default = contactGroup;
});