define('frontend/routes/inner-app/import', ['exports', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unsavedDataTransitionBlock.default, {
        ajax: Ember.inject.service(),
        specialItems: Ember.inject.service(),
        fileQueue: Ember.inject.service(),

        model: function model() {
            return this.get('store').createRecord('import');
        },
        activate: function activate() {
            this.controllerFor(this.routeName).resetState();
        },
        redirect: function redirect(model, transition) {
            if (transition.queryParams.code) {
                this.transitionTo('/import-google').then(function (googleRoute) {
                    googleRoute.controller.googleLogin(transition.queryParams.code);
                });
            }
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var specialItems = this.get('specialItems');
            //reload google group after finished importing
            specialItems.reloadGoogle = true;

            this.controllerFor(this.routeName).checkGoogleLogin();
        },
        resetUpload: function resetUpload() {
            this.controllerFor(this.routeName).set('enableAdvancedSettings', false);
            this.controllerFor(this.routeName).resetEncodingAndDelimiter('UTF-8', ',');

            if (this.get('fileQueue.files.length') > 0) {
                this.get('fileQueue').get('files').forEach(function (file) {
                    file.set('queue', null);
                });
                this.set('fileQueue.files', Ember.A());
            }
        },


        actions: {
            willTransition: function willTransition() {
                this.resetUpload();
            }
        }
    });
});