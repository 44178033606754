define('frontend/components/settings/security-settings', ['exports', 'frontend/mixins/user-settings'], function (exports, _userSettings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {

        current_password: null,
        new_password: null,
        new_password_confirmation: null,
        modal: Ember.inject.service(),
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        router: Ember.inject.service(),

        deleteAccount: function deleteAccount() {
            var _this = this;

            this.get('ajax').delete('users/me').then(function () {
                _this.get('modal').alert({
                    template: 'dialogs/messages/error',
                    message: _this.i18n.t('settings.security.delete-success')
                }).finally(function () {
                    _this.get('modal').destroyAllModals();
                    _this.get('session').invalidate();
                    _this.get('router').transitionTo('login');
                });
            });
        },


        actions: {
            changePassword: function changePassword() {
                var _this2 = this;

                this.get('flashMessages').clearMessages();

                this.get('ajax').patch('password', {
                    data: {
                        current_password: this.get('current_password'),
                        new_password: this.get('new_password'),
                        new_password_confirmation: this.get('new_password_confirmation')
                    }
                }).then(function () {
                    _this2.get('flashMessages').success(_this2.get('i18n').t('general.success'), {
                        extendedTimeout: 10000
                    });

                    _this2.set('current_password', null);
                    _this2.set('new_password', null);
                    _this2.set('new_password_confirmation', null);
                }).catch(function (response) {
                    var errors = response.payload.errors;
                    for (var field in errors) {
                        _this2.get('flashMessages').danger(errors[field].detail, {
                            extendedTimeout: 10000
                        });
                        break;
                    }
                });
            },
            confirmDeleteAccount: function confirmDeleteAccount() {
                var _this3 = this;

                this.get('modal').confirm({ template: 'dialogs/messages/delete-account', title: this.get('i18n').t('settings.security.delete-account-title') }).then(function () {
                    _this3.deleteAccount();
                });
            }
        }

    });
});