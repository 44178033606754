define('frontend/models/company', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        name: (0, _attr.default)('string'),
        eik: (0, _attr.default)('string'),
        address: (0, _attr.default)('string'),
        city: (0, _attr.default)('string'),
        zipcode: (0, _attr.default)('string'),
        mol: (0, _attr.default)('string'),
        phone: (0, _attr.default)('string'),
        creditsCurrent: (0, _attr.default)('number'),
        creditsTotal: (0, _attr.default)('number'),
        originator: (0, _attr.default)(),
        apiServiceId: (0, _attr.default)(),
        apiWebhookUrl: (0, _attr.default)(),
        contactsUniqueField: (0, _attr.default)('string'),

        users: (0, _relationships.hasMany)('user', { async: true }),
        plan: (0, _relationships.belongsTo)('plan', { async: true }),
        customFields: (0, _relationships.hasMany)('custom-field', { async: true }),

        creditsUsage: Ember.computed('creditsCurrent', 'creditsTotal', function () {
            if (this.get('creditsCurrent') > this.get('creditsTotal')) {
                return 1;
            }

            return this.get('creditsCurrent') / this.get('creditsTotal');
        })
    });
});