define('frontend/components/focus-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        reFocus: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.focusInput();
        },
        didUpdateAttrs: function didUpdateAttrs() {
            this._super.apply(this, arguments);
            this.focusInput();
        },
        focusInput: function focusInput() {
            Ember.run.schedule('afterRender', this, function () {
                this.$('input').focus();
            });
        }
    });
});