define('frontend/controllers/inner-app', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service('session'),
        pusher: Ember.inject.service('pusher'),
        user: Ember.inject.service('user'),

        isHelpHidden: true,
        activeAnchor: null,

        helpEntries: Ember.computed(function () {
            return this.get('store').findAll('help-category', {
                include: 'help-entries'
            });
        }),

        showSettingsIcon: Ember.computed('user.data.acceptedTerms', function () {
            return this.get('user.data.acceptedTerms');
        }),

        init: function init() {
            this._super.apply(this, arguments);
            Ember.run.schedule("afterRender", this, function () {
                var _this = this;

                var entries = Ember.A([]);

                if (window.location.hash) {
                    this.get('helpEntries').then(function (categories) {
                        categories.filter(function (category) {
                            var entry = category.get('helpEntries').filterBy('anchor', window.location.hash.substr(1));
                            if (entry.length > 0) {
                                entries.pushObject(entry);
                            }
                        });
                    }).finally(function () {
                        if (entries.length > 0) {
                            _this.set('isHelpHidden', false);
                            _this.set('activeAnchor', entries.get('firstObject').get('firstObject'));
                        }
                    });
                }
            });
        },


        actions: {
            logout: function logout() {
                this.get('pusher').unsubscribe();
                this.get('session').invalidate();
                window.location.href = '/login';
            },
            toggleHelp: function toggleHelp() {
                this.toggleProperty('isHelpHidden');
            },
            hideHelp: function hideHelp() {
                this.set('isHelpHidden', true);
            }
        }
    });
});