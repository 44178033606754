define('frontend/controllers/inner-app/events', ['exports', 'frontend/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend({
        selectedItems: service(),
        user: service(),
        ajax: service(),
        locale: service(),
        router: service(),

        highlightStats: false,

        currentCredits: computed.alias('user.data.currentCompany.creditsCurrent'),
        totalCredits: computed.alias('user.data.currentCompany.creditsTotal'),
        creditsLeft: computed('model.creditsCost', 'model.scheduled', 'currentCredits', function () {
            return !this.get('model.scheduled') && this.get('model.creditsCost') ? this.get('currentCredits') - this.get('model.creditsCost') : this.get('currentCredits');
        }),
        hasCredits: computed.gte('creditsLeft', 0),
        showNotEnoughCreditsButton: computed('hasCredits', 'notEnoughCredits', function () {
            return !this.get('hasCredits') || this.get('notEnoughCredits');
        }),

        selectedSmsEvent: computed.equal('selectedType.value', 1),
        selectedVoiceEvent: computed.equal('selectedType.value', 2),

        selectedContactsCount: computed.alias('selectedItems.count'),

        ongoingRequest: computed.or('ajax.isRunning', 'model.isSaving'),

        eventTypes: [{
            value: 1,
            text: 'events.types.sms',
            selected: true,
            disabled: false
        }, {
            value: 2,
            text: 'events.types.voice',
            disabled: false
        }],

        selectedType: null,

        enabledSelection: computed('router.currentRouteName', 'model.id', function () {
            return this.get('router.currentRouteName') === 'inner-app.events.edit.contact-groups.contacts' && this.get('model.id') !== null;
        }),

        init: function init() {
            this._super.apply(this, arguments);

            if (!_environment.default.showVoice) {
                this.get('eventTypes')[1].disabled = true;
            }

            if (this.get('model.type') === 'voice') {
                this.set('selectedType', this.get('eventTypes')[1]);
            } else {
                this.set('selectedType', this.get('eventTypes')[0]);
            }
        },


        sendDate: computed('model.sendDate', function () {
            return this.get('model.sendDate') && this.get('model.sendDate').isValid() ? this.get('model.sendDate').locale(this.get('locale.curentLocale')).format(this.get('user.data.dateFormat')) : '';
        }),

        sendTime: computed('model.sendDate', function () {
            return this.get('model.sendDate') && this.get('model.sendDate').isValid() ? this.get('model.sendDate').locale(this.get('locale.curentLocale')).format(this.get('user.data.timeFormat')) : '';
        }),

        hasChangedContacts: computed('model', 'model.scheduled', 'model.contactIds', 'selectedItems.updated', function () {
            if (this.get('model.contactIds.length') === this.get('selectedItems.count')) {
                return !this.get('selectedItems').areSelected(this.get('model.contactIds'));
            }
            return true;
        }),

        isDirty: computed('model.isNew', 'model.hasDirtyAttributes', 'hasChangedContacts', function () {
            return !this.get('model.isNew') && (this.get('model.hasDirtyAttributes') || this.get('hasChangedContacts'));
        }),

        notEnoughCredits: computed('model.creditsCost', 'data.currentCompany.creditsCurrent', 'selectedItems.updated', function () {
            var currentCredits = this.get('user.data.currentCompany.creditsCurrent');

            if (!this.get('model.isNew')) {
                currentCredits += this.get('model.creditsCost');
            }

            return this.get('selectedItems.count') > currentCredits || this.get('model.creditsCost') > currentCredits;
        }),

        calculatedCredits: computed('model.creditsCost', 'isDirty', function () {
            return this.get('isDirty') ? false : this.get('model.creditsCost') > 0;
        }),

        disableScheduling: computed.or('ongoingRequest', 'notEnoughCredits', 'manuallyDisableScheduling'),

        manuallyDisableScheduling: false,

        editingDisabled: computed.or('model.hasRun', 'ongoingRequest'),

        hasEnoughCredits: computed.not('notEnoughCredits'),
        hasNotRun: computed.not('model.hasRun'),
        shouldShowButtons: computed.and('hasNotRun', 'hasEnoughCredits')
    });
});