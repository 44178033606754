define('frontend/components/ember-progress-bar', ['exports', 'ember-progress-bar/components/ember-progress-bar'], function (exports, _emberProgressBar) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberProgressBar.default.extend({
        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.get('progressBar').setText(this.get('options.text.value'));
        }
    });
});