define('frontend/validators/time-dnd', ['exports', 'ember-cp-validations/validators/base', 'moment'], function (exports, _base, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var service = Ember.inject.service;


    var TimeDnd = _base.default.extend({
        user: service(),
        validate: function validate(value, options /*, model, attribute*/) {
            if (!value) {
                return true;
            }
            var dndFromVal = this.get('user.data.dndFrom');
            var dndToVal = this.get('user.data.dndTo');

            if (!dndFromVal || !dndToVal) {
                return true;
            }

            var currentHours = void 0,
                currentMinutes = void 0,
                fromHours = void 0,
                fromMinutes = void 0,
                toHours = void 0,
                toMinutes = void 0;

            var _parseTime = this.parseTime(value);

            var _parseTime2 = _slicedToArray(_parseTime, 2);

            currentHours = _parseTime2[0];
            currentMinutes = _parseTime2[1];

            var _parseTime3 = this.parseTime(dndFromVal);

            var _parseTime4 = _slicedToArray(_parseTime3, 2);

            fromHours = _parseTime4[0];
            fromMinutes = _parseTime4[1];

            var _parseTime5 = this.parseTime(dndToVal);

            var _parseTime6 = _slicedToArray(_parseTime5, 2);

            toHours = _parseTime6[0];
            toMinutes = _parseTime6[1];


            toHours = this.normalizeForInterval(fromHours, toHours);
            currentHours = this.normalizeForInterval(fromHours, currentHours);

            var currentValue = (0, _moment.default)().hours(currentHours).minutes(currentMinutes),
                dndFrom = (0, _moment.default)().hours(fromHours).minutes(fromMinutes),
                dndTo = (0, _moment.default)().hours(toHours).minutes(toMinutes);

            if (currentValue.isBetween(dndFrom, dndTo)) {
                return options.message ? options.message : 'validation.time_dnd';
            }

            return true;
        },
        parseTime: function parseTime(value) {
            var parts = value.split(':');
            return parts.map(function (part) {
                return parseInt(part);
            });
        },
        normalizeForInterval: function normalizeForInterval(baseHours, subjectHours) {
            if (baseHours > subjectHours) {
                return subjectHours + 24;
            }
            return subjectHours;
        }
    });

    TimeDnd.reopenClass({
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports.default = TimeDnd;
});