define('frontend/routes/inner-app/events', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/utils/parse-error-status'], function (exports, _authenticatedRouteMixin, _parseErrorStatus) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        selectedItems: service(),
        ajax: service(),
        queue: service(),
        modal: service(),
        user: service(),
        notifications: service(),

        model: function model() {
            var selectedItems = this.get('selectedItems');
            selectedItems.flush();

            var editParams = this.paramsFor('inner-app.events.edit');

            if (editParams.campaignId) {
                return this.get('store').findRecord('campaign', editParams.campaignId, { include: 'template' }).then(function (campaign) {
                    selectedItems.addMany(campaign.get('contactIds'));
                    return campaign;
                });
            }

            return this.get('store').createRecord('campaign');
        },
        afterModel: function afterModel() {
            var campaign = this.modelFor(this.routeName);

            // If the campaign has run transition to the appropriate report page
            if (campaign.get('hasRun')) {
                this.transitionTo('inner-app.reports.edit', campaign.get('id'));
            }

            if (!campaign.get('isNew') && !campaign.get('isReady')) {
                this.get('notifications').showWarning(this.get('i18n').t('notifications.error-background-processing-active'));
                this.transitionTo('inner-app.index');
            }
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            var editParams = this.paramsFor('inner-app.events.edit');
            controller.set('highlightStats', false);
            if (editParams.campaignId) {
                var eventTypes = controller.get('eventTypes');
                var selectedType = this.modelFor(this.routeName).get('template.type') === 'voice' ? eventTypes[1] : eventTypes[0];
                controller.set('selectedType', selectedType);
            }
        },


        selectedType: "1",

        handleErrors: function handleErrors(errors) {
            errors = Ember.A(errors);
            if ((0, _parseErrorStatus.default)(errors.get('firstObject.status'), 422)) {
                var title = this.get('i18n').t(errors.get('firstObject.title'));
                var message = this.get('i18n').t(errors.get('firstObject.detail'));
                this.get('modal').alert({
                    template: 'dialogs/messages/error',
                    title: title,
                    message: message
                });
            } else {
                var _title = this.get('i18n').t(errors.get('firstObject.detail') + '-title');
                var _message = this.get('i18n').t(errors.get('firstObject.detail'));
                this.get('modal').alert({
                    template: 'dialogs/messages/error',
                    title: _title,
                    message: _message
                });
            }
        },


        actions: {
            changeEventType: function changeEventType(typeEvent) {
                this.set('selectedType', typeEvent);
                var selectedValue = parseInt(typeEvent.target.value);

                if (selectedValue === 1) {
                    this.controllerFor("inner-app.events").set('selectedSmsEvent', true);
                } else {
                    this.controllerFor("inner-app.events").set('selectedSmsEvent', false);
                }
            },
            prepareCampaign: function prepareCampaign() {
                var _this = this;

                this.get('queue').add(function () {
                    var campaign = _this.modelFor(_this.routeName);
                    var successMessage = campaign.get('isNew') ? 'notifications.success-prepared-campaign' : 'notifications.success-update-campaign';

                    return campaign.validate().then(function (_ref) {
                        var validations = _ref.validations;

                        if (validations.get('isValid')) {
                            if (_this.get('selectedItems').getIds().length === 0) {
                                var title = _this.get('i18n').t('errors.events.contacts-not-selected-title');
                                var message = _this.get('i18n').t('errors.events.contacts-not-selected');
                                _this.get('modal').alert({
                                    template: 'dialogs/messages/error',
                                    title: title,
                                    message: message
                                });
                                return false;
                            } else {
                                campaign.set('contactIds', _this.get('selectedItems').getIds());
                                return campaign.save().then(function () {
                                    _this.set('controller.highlightStats', true);
                                    _this.get('notifications').showSuccess(_this.get('i18n').t(successMessage));
                                    return _this.get('user').reloadCompany().then(function () {
                                        return true;
                                    });
                                }).catch(function (response) {
                                    if (response.errors) {
                                        _this.handleErrors(response.errors);
                                    }
                                    if (!campaign.get('isNew') && !campaign.get('isDeleted')) {
                                        campaign.rollbackAttributes();
                                        campaign.reload();
                                        return true;
                                    }
                                });
                            }
                        } else {
                            var _title2 = _this.get('i18n').t('errors.validation-title');
                            var _message2 = _this.get('i18n').t('errors.validation-text');
                            _this.get('modal').alert({
                                template: 'dialogs/messages/error',
                                title: _title2,
                                message: _message2
                            });
                            return true;
                        }
                    });
                }, 'contact');
            },
            scheduleCampaign: function scheduleCampaign() {
                var _this2 = this;

                this.controller.set('manuallyDisableScheduling', true);
                this.get('queue').add(function () {
                    var campaign = _this2.modelFor(_this2.routeName);
                    return _this2.get('ajax').post('/campaigns/' + campaign.get('id') + '/schedule', {
                        data: campaign.serialize()
                    }).then(function (scheduledCampaign) {
                        return _this2.get('user').reloadCompany().then(function () {
                            campaign.set('scheduled', true);
                            campaign.set('prepared', scheduledCampaign.data.attributes.prepared);
                            return _this2.get('modal').alert({
                                title: '',
                                message: _this2.get('i18n').t('notifications.success-scheduled-campaign')
                            }).finally(function () {
                                _this2.transitionTo('inner-app');
                                _this2.controller.set('manuallyDisableScheduling', false);
                                return true;
                            });
                        });
                    }).catch(function (response) {
                        if (response.payload.errors) {
                            _this2.handleErrors(response.payload.errors);
                        }
                        return true;
                    });
                }, 'contact');
            },
            deleteCampaign: function deleteCampaign() {
                var _this3 = this;

                this.get('queue').add(function () {
                    return _this3.get('modal').confirm({
                        template: 'dialogs/messages/delete-model',
                        title: _this3.get('i18n').t('confirm.event-delete-title'),
                        message: _this3.get('i18n').t('confirm.event-delete')
                    }).then(function () {
                        var campaign = _this3.modelFor(_this3.routeName);
                        return campaign.destroyRecord().then(function () {
                            _this3.get('notifications').showSuccess(_this3.get('i18n').t('notifications.success-delete-campaign'));
                            _this3.get('user').reloadCompany();
                            _this3.transitionTo('inner-app');
                        }).catch(function (response) {
                            if (response.errors) {
                                _this3.handleErrors(response.errors);
                            }
                            campaign.rollbackAttributes();
                            return true;
                        });
                    });
                }, 'contact');
            },
            creditsSettings: function creditsSettings(templatePath) {
                this.get('modal').openSettingsModal(templatePath);
            }
        }

    });
});