define("frontend/routes/password/reset", ["exports", "frontend/mixins/routable-body-class"], function (exports, _routableBodyClass) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routableBodyClass.default, {
        bodyClass: 'guest_wrapper',
        flashMessages: Ember.inject.service(),

        beforeModel: function beforeModel(transition) {
            this.controllerFor('application').set('isResetPasswordPage', true);
            this.set('email', transition.queryParams.email);
        },
        model: function model(params) {
            return {
                token: params.reset_token,
                email: this.get('email')
            };
        },


        actions: {
            didResetPassword: function didResetPassword(transitionToLogin) {
                this.get('flashMessages').success(this.get('i18n').t('general.success'));
                if (transitionToLogin) {
                    this.transitionTo('login');
                }
            }
        }

    });
});