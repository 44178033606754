define('frontend/models/template', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validators = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            messageKey: 'validation.template-name-present'
        }), (0, _emberCpValidations.validator)('length', {
            max: 250,
            messageKey: 'validation.template-name-max-length'
        })],
        transliteratedBody: [(0, _emberCpValidations.validator)('length', {
            min: 1,
            message: 'validation.template-body-min-length',
            disabled: Ember.computed.not('model.isSms')
        }), (0, _emberCpValidations.validator)('template-length', {
            disabled: Ember.computed.not('model.isSms')
        })]
    }, {
        debounce: 100
    });

    exports.default = _model.default.extend(Validators, {
        name: (0, _attr.default)('string'),
        companyId: (0, _attr.default)(),
        body: (0, _attr.default)('string', { defaultValue: '' }),
        bodyTransliterated: (0, _attr.default)('string'),
        duration: (0, _attr.default)(),
        path: (0, _attr.default)(),
        type: (0, _attr.default)('string'),
        groupId: (0, _attr.default)('number'),
        isNotValid: Ember.computed.empty('name'),

        templateGroups: (0, _relationships.hasMany)('template-group', { async: true }),
        reports: (0, _relationships.hasMany)('report'),

        isSms: Ember.computed('type', function () {
            return this.get('type') === 'sms';
        }),

        isVoice: Ember.computed('type', function () {
            return this.get('type') === 'voice';
        }),

        transliteratedBody: (0, _attr.default)(),

        /*computed('body', function () {
            let body = '';
             if(!this.get('body')) {
                body = '';
            } else {
                body = transl(this.get('body'), {
                    replace: {
                        'ъ': 'a',
                        'ь': 'y',
                        'й': 'y',
                        'щ': 'sht',
                        'ще': 'shte',
                        'Ще': 'Shte'
                    }
                });
            }
            this.set('bodyTransliterated', body);
            return body;
        }),*/

        upcomingCampaigns: (0, _attr.default)('boolean')
    });
});