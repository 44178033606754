define('frontend/components/contact-group-name-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        tagName: '',
        hideClass: 'hide',
        showClass: 'show-inline',
        searchGroup: null, //passed in
        showWhenEditing: Ember.computed('group.isEditing', function () {
            return this.get('group.isEditing') ? this.get('showClass') : this.get('hideClass');
        }),

        hideWhenEditing: Ember.computed('group.isEditing', function () {
            return this.get('group.isEditing') ? this.get('hideClass') : this.get('showClass');
        }),

        actions: {
            deleteGroup: function deleteGroup(contactGroup) {
                this.sendAction('deleteGroup', contactGroup);
            },
            editGroup: function editGroup(contactGroup) {
                contactGroup.set('isEditing', true);
            },
            cancelGroupEdit: function cancelGroupEdit(contactGroup) {
                contactGroup.set('isEditing', false);
                contactGroup.rollbackAttributes();
            },
            saveGroup: function saveGroup(contactGroup) {
                if (contactGroup.get('isNotValid')) {
                    return;
                }
                if (contactGroup.get('isEditing') === true) {
                    contactGroup.set('isEditing', false);
                    this.sendAction('saveGroup', contactGroup);
                }
                return;
            }
        }
    });
});