define('frontend/components/group-choser', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        store: service('store'),
        groups: [], //
        selectedGroups: null, // passed in
        selectedGroup: null,
        single: false,
        groupType: null,
        currentModel: null,
        active: false,
        allowClear: null,
        dropdown: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.loadGroups();
        },
        loadGroups: function loadGroups() {
            var groups = this.get('store').findAll(this.get('groupType'), {
                reload: true
            });

            this.set('groups', groups);
        },


        isContactGroup: Ember.computed('groupType', function () {
            return this.get('groupType') === 'contact-group';
        }),

        actions: {
            selectedGroup: function selectedGroup(group) {
                //this.set('selectedGroup', group);
                if (this.get('dropdown') !== null) {
                    this.sendAction('action', group, this.get('dropdown'));
                } else {
                    this.sendAction('action', group);
                }
            },
            addGroup: function addGroup(group) {
                if (this.get('selectedGroups').content.length === 0) {
                    this.set('selectedGroups', []);
                }
                this.get('selectedGroups').addObject(group);
                this.sendAction('action', this.get('selectedGroups'));
                this.set('active', false);
            },
            deleteGroup: function deleteGroup(group) {
                this.get('selectedGroups').removeObject(group);
                this.sendAction('action', this.get('selectedGroups'));
            },
            toggleActive: function toggleActive() {
                this.set('active', !this.get('active'));
            }
        }
    });
});