define('frontend/components/contacts/groups-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        id: 'GroupContent',
        classNames: ['contact-goups-list', 'child-list', 'sortable'],
        tagName: 'ul',
        groups: null, // passed in,
        searchGroup: null, // passed in,
        actions: {
            deleteGroup: function deleteGroup(contactGroup) {
                this.sendAction('deleteGroup', contactGroup);
            },
            saveGroup: function saveGroup(contactGroup) {
                this.sendAction('saveGroup', contactGroup);
            },
            checkContacts: function checkContacts(group, checked) {
                this.sendAction('checkContactsForGroup', group, checked);
            }
        }
    });
});