define('frontend/components/layout/settings-inner', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        modal: Ember.inject.service(),

        actions: {
            changeDialog: function changeDialog(templatePath) {
                this.get('modal').destroyAllModals();
                this.get('modal').settings({
                    template: '' + templatePath
                });
            },
            decline: function decline() {
                this.sendAction('decline');
            }
        }

    });
});