define('frontend/routes/inner-app/reports/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model: function model(params) {
            return this.get('store').findRecord('report', params.campaignId).catch(function (error) {
                return error;
            });
        }
    });
});