define('frontend/routes/inner-app/contact-groups/contacts', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/infinite-scroll-route', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _authenticatedRouteMixin, _infiniteScrollRoute, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _infiniteScrollRoute.default, _unsavedDataTransitionBlock.default, {
        queryParams: {
            search: {
                refreshModel: true
            }
        },

        modelType: 'contact',
        modelGroupType: 'contact-groups',
        modelGroupRelation: 'contacts',

        modal: service(),
        selectedItems: service(),
        specialItems: service(),
        queue: service(),
        ajax: service(),
        notifications: service(),

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var params = this.getParams();
            controller.set('groupId', params.groupId);
            controller.set('searchGroups', this.paramsFor('inner-app.contact-groups').searchGroup);
            controller.initDebouncedQueryParam('search');
        },

        unloadContact: function unloadContact() {
            var _this = this;

            var controller = this.controllerFor(this.routeName);
            if (controller.get('unsavedContacts').length > 1) {
                controller.get('unsavedContacts').forEach(function (contact) {
                    _this.get('store').unloadRecord(contact);
                    _this.getModel().removeObject(contact);
                });
            }
        },
        modelLoad: function modelLoad(params) {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');
            this.get('queue').add(function () {
                return specialItems.loadSearch('contact', params).then(function (result) {
                    if (result) {
                        selectedItems.flush();
                    }
                    return true;
                });
            }, 'contact');
        },
        closeDropdown: function closeDropdown(dropdown) {
            dropdown.actions.close();
        },

        actions: {
            checkAll: function checkAll(checked) {
                var _this2 = this;

                var groupId = this.getParams().groupId;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    var promises = [];
                    if (_this2.shouldDoGeneralQuery(groupId)) {
                        if (groupId === 'all') {
                            var groups = _this2.modelFor('inner-app.contact-groups');
                            groups.forEach(function (group) {
                                promises.push(specialItems.getIds('contact', group.get('id'), 1).then(function (ids) {
                                    if (checked) {
                                        selectedItems.addMany(ids);
                                    } else {
                                        selectedItems.removeMany(ids);
                                    }
                                }));
                            });
                        }
                        if (groupId === 'ungrouped' || groupId === 'all') {
                            promises.push(specialItems.getIds('contact', 'no-group', 1).then(function (ids) {
                                if (checked) {
                                    selectedItems.addMany(ids);
                                } else {
                                    selectedItems.removeMany(ids);
                                }
                            }));
                        }
                        if (groupId === 'google' || groupId === 'all') {
                            promises.push(specialItems.getIds('contact', 'google', 1).then(function (ids) {
                                if (checked) {
                                    selectedItems.addMany(ids);
                                } else {
                                    selectedItems.removeMany(ids);
                                }
                            }));
                        }
                    } else {
                        promises.push(specialItems.getIds('contact', groupId, 1).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        }));
                    }
                    return Ember.RSVP.all(promises);
                }, 'contact');
            },
            batchDeleteContacts: function batchDeleteContacts() {
                var _this3 = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                var search = 0;
                if (specialItems.search) {
                    search = 1;
                }

                var params = this.paramsFor(this.routeName);
                var groupId = params.groupId;
                groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

                return specialItems.getIds('contact', groupId, search).then(function (ids) {
                    var contactIds = selectedItems.getIds(ids);

                    if (contactIds.length === 0) {
                        _this3.get('modal').alert({
                            template: 'dialogs/messages/delete-model',
                            message: _this3.get('i18n').t('confirm.select-contacts-to-delete')
                        });
                        return false;
                    }

                    return _this3.get('modal').confirm({ message: _this3.get('i18n').t('confirm.contacts-delete') }).then(function () {
                        _this3.get('queue').add(function () {
                            return _this3.get('ajax').del('contacts', { data: { ids: JSON.stringify(contactIds) } }).then(function () {
                                specialItems.reloadGoogle = true;
                                return specialItems.syncGroups('contact').then(function () {
                                    return specialItems.removeManyFromAllGroups('contact', contactIds).then(function () {
                                        contactIds.forEach(function (id) {
                                            var contact = _this3.getModel().findBy('id', id);
                                            if (contact) {
                                                _this3.get('store').unloadRecord(contact);
                                                _this3.getModel().removeObject(contact);
                                            }
                                        });

                                        selectedItems.flush();
                                        _this3.modelFor('inner-app/contact-groups').forEach(function (group) {
                                            group.set('isChecked', false);
                                        });

                                        _this3.get('notifications').showSuccess(_this3.get('i18n').t('notifications.success-delete-many-contacts'));
                                        return _this3.transitionTo('inner-app.contact-groups.contacts', params.groupId);
                                    });
                                });
                            });
                        }, 'contact');
                    });
                });
            },
            createContact: function createContact() {
                this.unloadContact();
                this.transitionTo('inner-app.contact-groups.contacts.create', this.getParams().groupId);
            },
            batchAddGroupToContacts: function batchAddGroupToContacts(group, dropdown) {
                var _this4 = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    if (!group) {
                        return false;
                    }

                    _this4.closeDropdown(dropdown);

                    var search = 0;
                    if (specialItems.search) {
                        search = 1;
                    }

                    var params = _this4.paramsFor(_this4.routeName);
                    var groupId = params.groupId;
                    groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

                    return specialItems.getIds('contact', groupId, search).then(function (ids) {
                        var checkedContactsId = selectedItems.getIds(ids);

                        return _this4.get('ajax').patch('contacts', {
                            data: {
                                ids: JSON.stringify(checkedContactsId),
                                groupId: group.get('id')
                            }
                        }).then(function () {
                            //resync groups
                            return specialItems.syncGroups('contact').then(function () {
                                //remove ember models
                                var promiseModelAddGroup = void 0;
                                var models = _this4.getModel();
                                var modelChunks = [];
                                for (var i = 0; i < models.length; i += 50) {
                                    modelChunks.push(models.slice(i, i + 50));
                                }
                                if (modelChunks.length) {
                                    modelChunks.forEach(function (modelChunk) {
                                        promiseModelAddGroup = _this4.get('queue').add(function () {
                                            modelChunk.forEach(function (contact) {
                                                if (checkedContactsId.indexOf(contact.get('id')) >= 0) {
                                                    if (params.groupId === 'ungrouped') {
                                                        //record is undefined fix
                                                        _this4.get('store').unloadRecord(contact);
                                                        _this4.getModel().removeObject(contact);
                                                    } else {
                                                        contact.get('contactGroups').addObject(group);
                                                    }
                                                }
                                            });
                                            return true;
                                        }, 'addGroupToContactModels');
                                    });
                                }

                                return promiseModelAddGroup.then(function () {
                                    _this4.get('notifications').showSuccess(_this4.get('i18n').t('notifications.success-add-contacts-to-group'));
                                    if (params.groupId === 'ungrouped') {
                                        _this4.transitionTo('inner-app.contact-groups.contacts', params.groupId);
                                    }
                                });
                            });
                        });
                    });
                }, 'contact');
            },
            deleteContact: function deleteContact(contact) {
                var _this5 = this;

                var specialItems = this.get('specialItems');

                var params = this.paramsFor(this.routeName);

                this.get('modal').confirm({ message: this.get('i18n').t('confirm.contact-delete') }).then(function () {
                    _this5.get('queue').add(function () {
                        var contactIndex = _this5.getModel().indexOf(contact);
                        if (contactIndex === -1) {
                            return false;
                        }
                        var contactId = contact.get('id');

                        _this5.getModel().removeAt(contactIndex, 1);
                        return contact.destroyRecord().then(function () {
                            specialItems.removeFromAllGroups('contact', contactId);
                            _this5.get('notifications').showSuccess(_this5.get('i18n').t('notifications.success-delete-contacts'));
                            return _this5.transitionTo('inner-app.contact-groups.contacts', params.groupId);
                        });
                    }, 'contact');
                });
            },
            willTransition: function willTransition() {
                this.get('selectedItems').flush();
                this.modelFor('inner-app/contact-groups').forEach(function (group) {
                    group.set('isChecked', false);
                });
            }
        }
    });
});