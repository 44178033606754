define('frontend/mixins/infinite-scroll-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        /**
         * Should be set in the receiving route.
         * Used for sotre.query to get the new pages.
         */
        modelType: '',
        /**
         * Should be set in the receiving route.
         * Used to get the group for the case of grouped models.
         */
        modelGroupType: '',
        /**
         * Should be set in the receiving route.
         * Used to query the models inside a group.
         */
        modelGroupRelation: '',

        totalPages: 0,

        /**
         * Parameters added as query string when making a request to paginated and filtered resource.
         */
        requestParams: {
            page: 1,
            per_page: 100,
            filter: {}
        },

        model: function model(params) {
            var _this = this;

            return this.get('queue').add(function () {
                _this.resetPagination();
                var search = params.search ? params.search : '';
                search = search.toString().replace(/\s+/g, ' ').trim();
                if (search) {
                    _this.set('requestParams.filter.word', search);
                }

                var parameters = params.groupId !== undefined ? params.groupId : params.templateGroupId;

                if (parameters === 'ungrouped') {
                    _this.set('requestParams.filter.group', 'no-group');
                }

                if (parameters === 'google') {
                    _this.set('requestParams.filter.group', 'google');
                }

                if (_this.modelLoad) {
                    _this.modelLoad(params);
                }

                return _this.getPage(params).then(function (result) {
                    _this.set('totalPages', result.get('meta.pagination.total_pages'));
                    return result.toArray();
                });
            }, this.get('modelType'));
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.resetScroll();
        },


        /**
         * Retrieves the next page from the API.
         * Updates the pagination state.
         */
        getNextPage: function getNextPage() {
            var _this2 = this;

            this.set('requestParams.page', this.get('requestParams.page') + 1);

            if (this.get('totalPages') < this.get('requestParams.page')) {
                return Ember.RSVP.cast(Ember.A([]));
            }

            var params = this.getParams();

            return this.getPage(params).then(function (result) {
                var resultArray = result.toArray();
                _this2.getModel().pushObjects(resultArray);
                return resultArray;
            });
        },
        getPage: function getPage(params) {
            var parameters = params.groupId !== undefined ? params.groupId : params.templateGroupId;

            this.set('requestParams.include', this.get('modelGroupType'));

            if (this.shouldDoGeneralQuery(parameters)) {
                return this.get('store').query(this.get('modelType'), this.get('requestParams'));
            }

            if (this.get('modelGroupType') !== '') {
                this.set('requestParams.filter.group', parameters);
            }

            return this.get('store').query(this.get('modelType'), this.get('requestParams'));
        },


        /**
         * TODO: Extract next three methods in separate mixin
         * @returns {*|Object|DS.Model}
         */
        getModel: function getModel() {
            return this.modelFor(this.routeName);
        },


        getParams: function getParams() {
            return this.paramsFor(this.routeName);
        },

        shouldDoGeneralQuery: function shouldDoGeneralQuery(groupId) {
            return groupId === 'all' || groupId === 'ungrouped' || groupId === 'google';
        },

        /**
         * Brings the pagination tracking properties in initial state.
         * Used on willTransition event to reset them for the next page or for when the user returns.
         */
        resetPagination: function resetPagination() {
            this.set('requestParams', {
                page: 1,
                per_page: 100,
                filter: {}
            });
        },
        resetScroll: function resetScroll() {
            if (this.get('controller.positionIndex')) {
                this.set('controller.positionIndex', 0);
            } else {
                this.set('controller.positionIndex', 0.1);
            }
        },


        actions: {
            willTransition: function willTransition() {
                this.resetPagination();
                this.resetScroll();
            },
            loadNext: function loadNext() {
                if (this.get('totalPages') <= this.get('requestParams.page')) {
                    return new Ember.RSVP.Promise(function (resolve) {
                        return resolve();
                    });
                }
                return this.getNextPage();
            }
        }
    });
});