define('frontend/components/templates/template-group-li', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        tagName: '',
        specialItems: service(),
        searchTmplGroup: null, //passed in

        init: function init() {
            this._super.apply(this, arguments);
            Ember.defineProperty(this, 'countTemplates', Ember.computed('specialItems.updated', function () {
                return this.get('specialItems').count('template', this.get('group.id'));
            }));
        },


        actions: {
            deleteGroup: function deleteGroup(tmpGroup) {
                this.sendAction('deleteGroup', tmpGroup);
            },
            saveGroup: function saveGroup(tmpGroup) {
                this.sendAction('saveGroup', tmpGroup);
            },
            checkAction: function checkAction(group, checked) {
                this.sendAction('checkTemplates', group, checked);
            }
        }
    });
});