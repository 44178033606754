define('frontend/services/user', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        store: service(),
        locale: service(),
        rollbar: service(),
        pusher: service(),
        moment: service(),

        load: function load() {
            var _this = this;

            return this.get('store').queryRecord('user', { me: true, include: 'companies.plan' }).then(function (user) {
                _this.set('data', user);
                _this.set('rollbar.currentUser', { username: 'User ' + user.id, id: user.id });
                _this.get('pusher').subscribe(user.id);
            });
        },
        reloadCompany: function reloadCompany() {
            // Alias, in case we change the logic in the future
            return this.load();
        },
        updateCredits: function updateCredits(planId) {
            var plan = this.get('store').peekRecord('plan', planId);
            var credits = plan.get('credits');
            this.set('data.currentCompany.creditsTotal', credits);
            this.set('data.currentCompany.creditsCurrent', credits);
        },
        takeCredits: function takeCredits(amount) {
            var currentAmount = this.get('data.currentCompany.creditsCurrent');
            this.set('data.currentCompany.creditsCurrent', currentAmount - amount);
        },
        addCredits: function addCredits(amount) {
            var currentAmount = this.get('data.currentCompany.creditsCurrent');
            this.set('data.currentCompany.creditsCurrent', currentAmount + amount);
        },

        languageObserver: Ember.observer('data.language', function () {
            if (this.get('data.language')) {
                this.get('locale').changeLocale(this.get('data.language'));
            }
        }),
        timezoneObserver: Ember.observer('data.timezone', function () {
            if (this.get('data.timezone')) {
                this.get('moment').changeTimeZone(this.get('data.timezone'));
                _moment.default.tz.setDefault(this.get('data.timezone'));
            }
        }),
        pollPlan: function pollPlan() {
            var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

            var _this2 = this;

            var repetitions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
            var expectedPlanId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

            if (repetitions <= 0 || this.get('data.currentCompany.plan.id') === expectedPlanId) {
                return;
            }
            Ember.run.later(function () {
                _this2.reloadCompany().then(function () {
                    _this2.pollPlan(delay, repetitions - 1, expectedPlanId);
                });
            }, delay);
        },

        isOnFreePlan: computed.equal('data.currentCompany.plan.id', 'free')
    });
});