define('frontend/mixins/user-settings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        store: Ember.inject.service(),
        user: Ember.inject.service(),
        locale: Ember.inject.service(),
        notifications: Ember.inject.service(),

        reloadHelp: false,

        changeUserData: function changeUserData(args) {
            var userId = this.get('user.data.id');
            var user = this.get('store').peekRecord('user', userId);
            user.set(args.name, args.value);
            return Ember.run.debounce(this, this.saveUserData, 1000);
        },
        saveUserData: function saveUserData() {
            var _this = this;

            var userId = this.get('user.data.id');
            var user = this.get('store').peekRecord('user', userId);
            return user.save().then(function () {
                _this.get('user').load();
                if (_this.get('reloadHelp')) {
                    _this.reloadHelpData();
                    _this.set('reloadHelp', false);
                }
                _this.get('notifications').showSuccess(_this.get('i18n').t('notifications.success-update-users'));
            });
        },
        getCompanyCustomFields: function getCompanyCustomFields() {
            return this.get('store').findAll('custom-field', { reload: true });
        },
        reloadHelpData: function reloadHelpData() {
            this.get('store').findAll('help-category', {
                include: 'help-entries',
                reload: true
            });
        },
        changeCompanyData: function changeCompanyData(args) {
            var companyId = this.get('user.data.currentCompany.id');
            var company = this.get('store').peekRecord('company', companyId);
            company.set(args.name, args.value);
            return Ember.run.debounce(this, this.saveCompanyData, args.notificationMessage, 1000);
        },
        saveCompanyData: function saveCompanyData(messageOverride) {
            var _this2 = this;

            var message = messageOverride ? messageOverride : 'notifications.success-update-company';
            var companyId = this.get('user.data.currentCompany.id');
            var company = this.get('store').peekRecord('company', companyId);
            return company.save().then(function () {
                _this2.get('notifications').showSuccess(_this2.get('i18n').t(message));
            });
        },
        extractDataFromInput: function extractDataFromInput(event) {
            this.set('updateElement', Ember.$(event.target));
            var elem = this.get('updateElement');
            var elemIsCheckbox = elem[0].nodeName === 'INPUT' && elem.attr('type') === 'checkbox';
            var value = elemIsCheckbox ? elem.is(':checked') : elem.val();
            var name = elem.attr('name');
            return { name: name, value: value };
        },


        actions: {
            changeUserDataFromInput: function changeUserDataFromInput(event) {
                this.changeUserData(this.extractDataFromInput(event));
            }
        }

    });
});