define('frontend/routes/verification', ['exports', 'ember-network/fetch'], function (exports, _fetch) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        flashMessages: Ember.inject.service(),
        i18n: Ember.inject.service(),

        redirect: function redirect(model, transition) {
            var _this = this;

            (0, _fetch.default)('/api/v1/verification?code=' + transition.params.verification.code).then(function (response) {
                if (response.ok) {
                    _this.transitionTo('login').then(function (loginRoute) {
                        loginRoute.controller.set('verificationSuccess', true);
                    });
                } else {
                    _this.transitionTo('login').then(function (loginRoute) {
                        loginRoute.controller.set('email', transition.queryParams.email);
                        loginRoute.controller.set('showResendVerification', true);
                    });
                }
            });
        }
    });
});