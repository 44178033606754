define('frontend/helpers/percent-value', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.percentValue = percentValue;
  function percentValue(params /*, hash*/) {
    return (params[0] * 100).toFixed(2) + '%';
  }

  exports.default = Ember.Helper.helper(percentValue);
});