define('frontend/controllers/inner-app/contact-groups/contacts/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        proceedToSave: false,

        contactsController: Ember.inject.controller('inner-app.contact-groups.contacts'),
        disableFocus: Ember.computed.alias('contactsController.disableFocus')
    });
});