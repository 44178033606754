define('frontend/components/settings/profile-settings', ['exports', 'frontend/mixins/user-settings'], function (exports, _userSettings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {

        modal: Ember.inject.service(),
        session: Ember.inject.service(),
        router: Ember.inject.service(),
        allMethods: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('allMethods', this.returnAllMethods());
        },
        returnAllMethods: function returnAllMethods() {
            return this.get('store').findAll('method');
        },


        actions: {
            changeCompanyDataFromInput: function changeCompanyDataFromInput(args) {
                this.changeCompanyData(this.extractDataFromInput(args));
            },
            deletePaymentMethod: function deletePaymentMethod(method) {
                this.get('modal').confirm({ message: this.i18n.t('confirm.payment-method-delete') }).then(function () {
                    method.destroyRecord();
                });
            },
            activateWelcomeScreen: function activateWelcomeScreen() {
                this.get('session').set('data.seenWelcome', false);
                this.get('modal').destroyAllModals();
                this.get('router').transitionTo('inner-app.welcome');
            }
        }

    });
});