define('frontend/components/validated-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        user: Ember.inject.service(),
        classNames: ['input', 'validated-input'],
        classNameBindings: ['showErrorClass:error', 'isValid:success'],
        model: null,
        value: null,
        type: 'text',
        valueAttr: '',
        placeholder: '',
        tabindex: null,
        validation: null,
        isTyping: false,
        displayErrors: '',
        focusField: false,

        timeInputValue: computed('value', function () {
            var time = this.get('value');
            if (typeof time === 'string') {
                var split = time.split(':');
                return {
                    hours: split[0] || undefined,
                    minutes: split[1] || undefined
                };
            }
            return time;
        }),

        isCheckButton: computed.equal('type', 'checkbox'),
        isTimeInput: computed.equal('type', 'time'),
        isAmPmTimeFormat: computed.equal('user.data.timeFormat', 'hh:mma'),

        focusOut: function focusOut() {
            if (this.has('focusOutAction')) {
                this.get('focusOutAction')();
            }
        },
        didRender: function didRender() {
            if (this.get('focusField')) {
                this.$('input').focus();
                this.set('focusField', false);
            }
        },


        actions: {
            changeHandler: function changeHandler() {
                if (this.has('changeHandlerAction')) {
                    this.get('changeHandlerAction').apply(undefined, arguments);
                }
            },
            focusOutAction: function focusOutAction() {
                if (this.has('focusOutAction')) {
                    this.get('focusOutAction')();
                }
            }
        },

        didReceiveAttrs: function didReceiveAttrs() {
            var valueAttr = this.get('valueAttr');
            Ember.defineProperty(this, 'validation', Ember.computed.oneWay('model.validations.attrs.' + valueAttr));
            Ember.defineProperty(this, 'value', Ember.computed.alias('model.' + valueAttr));
        },


        didValidate: Ember.computed.oneWay('targetObject.didValidate'),
        hasContent: Ember.computed.notEmpty('value'),
        isValid: Ember.computed.and('hasContent', 'validation.isValid'),
        isInvalid: Ember.computed.oneWay('validation.isInvalid'),
        showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
        showErrorMessage: Ember.computed('model.validations.isDirty', 'isInvalid', function () {
            return this.get('model.validations.isDirty') && this.get('isInvalid');
        }),

        showWarningMessage: Ember.computed('validation.warnings.[]', 'hasContent', function () {
            if (this.get('hasContent') && !Ember.isEmpty(this.get('validation.warnings'))) {
                return this.get('validation.warnings');
            }
            return;
        }),

        isWarning: Ember.computed('showWarningMessage', function () {
            return this.get('showWarningMessage');
        })
    });
});