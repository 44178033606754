define('frontend/helpers/in-array-moment', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.inArrayMoment = inArrayMoment;
    function inArrayMoment(params, options) {
        if (!options.precision) {
            options.precision = 'day';
        }

        var dates = Ember.A(params[1]);
        var passedInDate = params[0];
        return dates.find(function (dateToTest) {
            return dateToTest.isSame(passedInDate, options.precision);
        });
    }

    exports.default = Ember.Helper.helper(inArrayMoment);
});