define('frontend/components/infinite-scroll-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            Ember.run.scheduleOnce('afterRender', function () {
                _this.set('containerHeight', _this.$().height());

                Ember.$(window).on('resize.infinitescroll', function () {
                    _this.set('containerHeight', _this.$().height());
                });
            });
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            Ember.$(window).off('resize.infinitescroll');
        },


        /**
         * The position to which to scroll to
         * used by virtual-each when new items are loaded
         */
        positionIndex: 0,

        /**
         * Tricks virtual-each to update it's state and handle scrolling to bottom correctly
         */
        infiniteScrollItems: Ember.computed('items.length', function () {
            return this.get('items');
        }),

        containerHeight: 0,
        itemHeight: 0,

        actions: {
            loadNext: function loadNext(startAt) {
                var _this2 = this;

                // Fix for virtual-each firign the onScrollBotommed action multiple times with 0 items
                if (this.get('items.length') === 0) {
                    return;
                }
                this.get('loadNext')().then(function () {
                    if (_this2.get('positionIndex') >= 1) {
                        _this2.set('positionIndex', startAt);
                    }
                });
            }
        }
    });
});