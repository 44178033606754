define('frontend/controllers/inner-app/template-groups/templates', ['exports', 'frontend/mixins/debounce-query-params', 'frontend/config/environment'], function (exports, _debounceQueryParams, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        queryParams: ['search'],

        session: service(),
        selectedItems: service(),
        specialItems: service(),

        bulkChangePopoverOpen: false,
        disableFocus: false,

        showVoice: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('showVoice', _environment.default.showVoice);
        },
        checkAndRemoveFromList: function checkAndRemoveFromList(template) {
            if (isNaN(parseInt(this.get('templateGroupId')))) {
                return;
            }

            if (!template.get('templateGroups').mapBy('id').includes(this.get('templateGroupId'))) {
                this.get('model').removeObject(template);
            }
        },


        shouldAllowMovingToGroup: computed('selectedItems.updated', 'model', 'model.[]', function () {
            var _this = this;

            return this.get('model').filter(function (template) {
                return _this.get('selectedItems').isSelected(template.get('id'));
            }).length > 0;
        }),

        unsavedTemplates: computed('model', 'model.[]', function () {
            return this.get('model').filter(function (template) {
                return template.get('isNew');
            });
        }),

        allChecked: computed('selectedItems.updated', 'specialItems.updated', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            var search = 0;
            var itemsIds = void 0;

            if (specialItems.search) {
                search = 1;
            }

            var groupId = this.get('templateGroupId');
            groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

            itemsIds = specialItems.getIdsArray('template', groupId, search);
            if (itemsIds.length) {
                return selectedItems.areSelected(itemsIds);
            }

            return false;
        }),

        actions: {
            changeSearchTo: function changeSearchTo(value) {
                this.set('disableFocus', true);
                this.debounceSetQueryParam('search', value);
            },
            disableFocus: function disableFocus() {
                this.set('disableFocus', false);
            }
        }
    });
});