define('frontend/utils/shared-audio-access', ['exports', 'ember-hifi/utils/shared-audio-access'], function (exports, _sharedAudioAccess) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _sharedAudioAccess.default;
    }
  });
});