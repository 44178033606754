define('frontend/helpers/price-format', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        i18n: Ember.inject.service(),

        compute: function compute(params /*, hash*/) {
            var currency = params[1] && params[1] === true ? this.get('i18n').t('plans.currency_per_month') : this.get('i18n').t('plans.currency');
            var price = params[0].toFixed(2);

            return '' + price + currency;
        }
    });
});