define('frontend/components/settings/credits-settings', ['exports', 'frontend/mixins/user-settings', 'frontend/utils/parse-error-status'], function (exports, _userSettings, _parseErrorStatus) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {

        ajax: Ember.inject.service(),
        notifications: Ember.inject.service(),
        modal: Ember.inject.service(),

        currentPlan: null,
        displayPaymentMethodForm: false,
        expandedPlan: null,
        attemptToSubscribeToPlan: null,
        subscribing: false,
        displayCreditsProgressBar: true,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('currentPlan', this.returnCurrentPlan());
        },
        returnCurrentPlan: function returnCurrentPlan() {
            return this.get('store').query('plan', {});
        },


        currentPlanId: Ember.computed('currentPlan.content.meta.current-plan-id', function () {
            return this.get('currentPlan').get('content.meta.current-plan-id');
        }),

        isSystemPlan: Ember.computed('currentPlan.content.meta.is-system-plan', function () {
            return this.get('currentPlan').get('content.meta.is-system-plan');
        }),

        companyHasAllRequiredFieldsForInvoice: Ember.computed('user.data.currentCompany.{eik,name,address,city,mol}', function () {
            return !!(this.get('user.data.currentCompany.eik.length') && this.get('user.data.currentCompany.name.length') && this.get('user.data.currentCompany.address.length') && this.get('user.data.currentCompany.city.length') && this.get('user.data.currentCompany.mol.length'));
        }),

        userHasAllRequiredFieldsForInvoice: Ember.computed('user.data.{firstName,lastName,city,address}', function () {
            return !!(this.get('user.data.firstName.length') && this.get('user.data.lastName.length') && this.get('user.data.city.length') && this.get('user.data.address.length'));
        }),

        canPurchasePlan: Ember.computed('companyHasAllRequiredFieldsForInvoice', 'userHasAllRequiredFieldsForInvoice', function () {
            if (this.get('user.data.currentCompany.eik.length')) {
                return this.get('companyHasAllRequiredFieldsForInvoice');
            }
            return this.get('userHasAllRequiredFieldsForInvoice');
        }),

        disabledPlans: Ember.computed('canPurchasePlan', 'isSystemPlan', function () {
            return this.get('isSystemPlan') || !this.get('canPurchasePlan');
        }),

        showPaymentMethodForm: function showPaymentMethodForm() {
            this.set('displayPaymentMethodForm', true);
        },
        hidePaymentMethodForm: function hidePaymentMethodForm() {
            this.set('displayPaymentMethodForm', false);
        },
        selectPlan: function selectPlan(planId) {
            this.get('user').updateCredits(planId);
            this.set('currentPlan.content.meta.current-plan-id', planId);
        },
        subscribe: function subscribe(planId) {
            var _this = this;

            if (this.get('subscribing') === true || this.get('canPurchasePlan') === false) {
                return;
            }

            this.set('subscribing', true);

            this.get('ajax').put('payments/plan', { data: {
                    'plan': planId
                } }).then(function () {
                _this.hidePaymentMethodForm();
                _this.set('displayCreditsProgressBar', false);
                _this.get('modal').alert({ title: _this.i18n.t('settings.credits.change-plan-payment-title'), message: _this.i18n.t('settings.credits.change-plan-payment-message') });
                _this.get('user').pollPlan(5000, 3, planId);
            }).catch(function (error) {
                if ((0, _parseErrorStatus.default)(error.payload.errors[0].status, 422)) {
                    _this.set('attemptToSubscribeToPlan', planId);
                    _this.showPaymentMethodForm();
                } else if ((0, _parseErrorStatus.default)(error.payload.errors[0].status, 403)) {
                    var title = _this.get('i18n').t(error.payload.errors[0].detail + '-title');
                    var message = _this.get('i18n').t(error.payload.errors[0].detail);
                    _this.get('modal').alert({
                        template: 'dialogs/messages/error',
                        title: title,
                        message: message
                    });
                } else {
                    var msg = void 0;

                    if (error.payload.errors[0].meta && error.payload.errors[0].meta.type === 'create_subscription') {
                        msg = _this.get('i18n').t('errors.card-processor-declined');
                    } else {
                        msg = _this.get('i18n').t('errors.busy-payment-gateway');
                    }

                    _this.get('notifications').showWarning(msg, { timeout: 6000 });
                    _this.$('#addPaymentMethodButton').removeAttr('disabled');
                }
                _this.set('displayCreditsProgressBar', true);
            }).finally(function () {
                _this.set('subscribing', false);
            });
        },


        actions: {
            setExpandedPlan: function setExpandedPlan(planId) {
                this.set('expandedPlan', planId);
            },
            closePaymentMethodForm: function closePaymentMethodForm() {
                this.hidePaymentMethodForm();
            },
            paymentMethodAdded: function paymentMethodAdded() {
                var plan = this.get('attemptToSubscribeToPlan');

                if (plan === null) {
                    return;
                }

                this.subscribe(plan);
            },
            confirmChangePlan: function confirmChangePlan(planId) {
                this.subscribe(planId);
            }
        }

    });
});