define('frontend/components/dialog-body', ['exports', 'ember-dialog/components/dialog-body'], function (exports, _dialogBody) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dialogBody.default;
    }
  });
});