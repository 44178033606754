define("frontend/locales/bg/translations", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        "general": {
            "submit": "Изпращане",
            "login": "Вписване",
            "logout": "Изход",
            "ok": "Добре",
            "yes": "да",
            "no": "не",
            "confirm": "Потвърждавам",
            "decline": "Отказвам",
            "email": "E-mail",
            "password": "Парола",
            "password-confirmation": "Потвръждаване на парола",
            "register": "Регистрация",
            "the-terms-of-service": "условията за ползване",
            "the-terms-of-service-href": "http://povikvane.io/uslovia-polzvane",
            "agree-to": "Съгласяване с",
            "success": "Операцията беше извършена успешно",
            "error": "Грешна парола и/или потребителско име",
            "send": "Изпращане",
            "dont-have-contacts": "Нямате записан контакт в тази група. За да въведете, използвайте бутона \"Добави\"",
            "contacts-list-empty": "Нямате записан контакт в тази група. За да въведете, използвайте бутона \"Добави\"",
            "templates-list-empty": "Нямате шаблони в тази група. За да създадете, използвайте бутона \"SMS шаблон\" или \"Звуков шаблон\"",
            "please-select-contact": "Моля, изберете контакт за редактиране",
            "please-select-template": "Моля, изберете шаблон за редактиране",
            "save": "Запазване",
            "changes-saved": "Промените бяха записани успешно!",
            "error-msg": "Съобщение за грешка!",
            "notice": "Знаете ли това?",
            "info": "Знаете ли това?",
            "file-upload-error": "Възникна грешка при качването на данни. Опитайте отново",
            "file-upload-formats": "Файловите формати, които може да използвате са .wav / .mp3 ",
            "choose-file": "Избери файл",
            "uploading": "Качване на файл",
            "files": "Файлове",
            "name": "Име",
            "last-name": "Фамилия",
            "phone": "Телефон",
            "organization": "Организация",
            "tags": "Полета за персонализация ",
            "symbol-count": "Брой символи",
            "groups": "Групи",
            "group": "Група",
            "record-voice-msg": "Запис на гласово съобщение",
            "voice-msg-recorded": "Съобщението е записано",
            "record": "Запис",
            "play": "Прослушай",
            "stop": "Спри",
            "upload-file": "Качване на файл",
            "no-templates-in-group": "Нямате записан шаблон в тази група.",
            "contacts": "Контакти",
            "add-contact": "Добави",
            "import-contacts": "Импортиране",
            "move-contacts-to-group": "Преместване на избраните контакти в група",
            "select-contacts-first": "Изберете първо контакт",
            "export-selected-contacts": "Експортиране на избраните контакти",
            "delete-selected-contacts": "Изтриване на избраните контакти",
            "edit": "Редактиране",
            "templates": "Шаблони",
            "sms-template": "SMS шаблон",
            "voice-template": "Звуков шаблон",
            "delete-selected-templates": "Изтриване на избраните шаблони",
            "move-selected-templates-to-group": "Преместване на избраните шаблони в група",
            "select-templates-first": "Изберете първо шаблон",
            "edit-template": "Редактиране на шаблон",
            "create-template": "Създай шаблон",
            "create-contact": "Създай контакт",
            "export-selected-groups": "Експортиране на избраните групи",
            "delete-selected-groups": "Изтриване на избраните групи",
            "all-templates": "Всички шаблони",
            "ungrouped-templates": "Негрупирани шаблони",
            "add-group": "Добави",
            "delete-selected-template-groups": "Изтриване на избрания шаблон от група",
            "settings": "Настройки",
            "main": "Основни",
            "profile": "Профил",
            "users": "Потребител",
            "emails": "Имейли",
            "credits": "Кредити",
            "notifications": "Известяване",
            "visualization": "Изглед",
            "information": "Информация",
            "done": "Готово",
            "total": "Общо",
            "contacts-subcaption": "Открийте всички контакти и групи или създайте нови.",
            "templates-subcaption": "Разгледайте всички ваши шаблони или създайте нови.",
            "reports": "Отчети",
            "view-all-reports": "Виж всички отчети",
            "successfull-deliveries": "Успешно доставени",
            "unsuccessfull-deliveries": "Неуспешно доставени",
            "processing": "В процес на изпращане",
            "card-type": "Тип карта",
            "card-number": "Номер на карта",
            "price": "Цена",
            "status": "Статус",
            "payments": "Плащания",
            "export-contacts-tooltip": "Експортиране на контакти",
            "export-contacts-tooltip-msg": "Моля, изберете контакти или група/и за експорт",
            "export-contact-groups-tooltip": "Експортиране на група контакти",
            "export-contact-groups-tooltip-msg": "Моля, изберете контакти или група за експорт",
            "security": "Защита",
            "change-password": "Смяна на парола",
            "delete": "Изтрий",
            "credits-info": "<p><span class=\"bold\">1 кредит=1 SMS / 1 обаждане 30 сек.</span></p><p>Ако желаете да закупите по-голям пакет свържете с нас:</p><p><a href=\"tel:+35924372202\">+359 (2) 437 2202</a><a href=\"mailto: contact@povikvane.io\">contact@povikvane.io</a></p>",
            "retry-upload": "Импортиране",
            "reset-upload": "Нов файл",
            "home": {
                "your_credits": "Кредити"
            },
            "new-contact-group": "Нова група",
            "new-template-group": "Нова група",
            "no-groups-found": "Няма намерени групи",
            "coming-soon": "Скоро...",
            "unsupported-device": 'Здравейте! <br><br>Извинете за причиненото неудобство, но <span class="bold">Povikvane</span> не може да се използва през това устройство или операционна система.',
            "unsupported-resolution": 'Здравейте! <br><br>Извинете за причиненото неудобство, но <span class="bold">Povikvane</span> не може да се използва на тази резолюция.',
            "website": "Уебсайт",
            "address": "Адрес",
            "note": "Бележка",
            "no-timezone-found": "Няма намерена часова зона",
            "reset-password-success-mobile": "За да използвате системата Povikvane, моля, преминете на устройство с големина и резолюция на екрана над 1024х768 пиксела.",
            "view-api-reports": "Виж АПИ отчети"
        },
        "guest": {
            "forgotten-password-heading": "Забравена парола?",
            "password-reset-button": "Изпрати линк",
            "forgot-your-password": "Забравена парола?",
            "remember-me": "Запомни ме",
            "dont-have-account": "Нямате профил в Povikvane?",
            "sign-up-for-free": "Регистрирайте се безплатно",
            "reset-password": "Задаване на парола",
            "verification-success": "Профилът Ви беше потвърден, можете да влезете в системата.",
            "verification-link-sent": "Изпратен е линк за потвърждение",
            "resend-verification": "Възникна грешка или линкът е вече използван. Желаете ли да получите нов линк:",
            "password-reset-success": "Новата парола беше запазена успешно.",
            "back-to-login": "Обратно",
            "account-blocked": "Този акаунт е блокиран.",
            "login": "Вход",
            "success": {
                "register": "Благодарим Ви! Изпратихме линк за активация на Вашия акаунт на посочения имейл."
            }
        },
        "validation": {
            "register-email-present": "Полето се попълва задължително.",
            "register-email-format": "Посочете валиден имейл адрес",
            "register-email-length": "Въведените символи са твърде много (максумим {{max}} символа)",
            "register-password-present": "Полето се попълва задължително.",
            "register-password-format": "Паролата трябва да съдържа поне една голяма и малка латинска буква, както и най-малко една цифра. Позволени символи: !@#$%^&*_+",
            "register-password-length": "Въведените символи са прекалено малко (минимум {{min}} символа)",
            "register-agree-terms": "Трябва да потвърдите, че приемате Услоявията за ползване на Povikvane.",
            "login-email-present": "Полето се попълва задължително",
            "login-email-format": "Посочете валиден имейл адрес",
            "login-password-present": "Полето се попълва задължително",
            "forgotten-email-present": "Полето се попълва задължително",
            "forgotten-email-format": "Посочете валиден имейл адрес",
            "forgotten-email-length": "Въведените символи са твърде много (максумим {{max}} символа)",
            "reset-email-present": "Полето се попълва задължително",
            "reset-email-format": "Посочете валиден имейл адрес",
            "reset-email-length": "Въведените символи са твърде много (максумим {{max}} символа)",
            "reset-password-present": "Полето се попълва задължително",
            "reset-password-format": "Паролата трябва да съдържа поне една голяма и малка буква, както и поне една цифра",
            "reset-password-length": "Въведените символи са прекалено малко (минимум {{min}} символа)",
            "password-mismatch": "Повторената парола не съвпада",
            "contact-email-present": "Полето се попълва задължително",
            "contact-email-format": "Посочете валиден имейл адрес",
            "contact-firstname-format": "Полето се попълва задължително",
            "contact-phonenumber-present": "Полето се попълва задължително",
            "template-name-present": "Полето с име на шаблона е задължително",
            "phone_number": "Полето трябва да съдържа валиден телефонен номер",
            "unique_for_company": "Полето трябва да е с уникална стойност",
            "unique_phone": "Телефонният номер вече съществува в системата",
            "date_in_future": "Моля, изберете бъдеща дата",
            "template_length": "Достигнали сте максималният брой символи в шаблона",
            "template-body-min-length": "Шаблонът не може да бъде празен.",
            "template-name-max-length": "Максималният брой символи в заглавието на шаблона е 250",
            "time_dnd": "Зададеният час попада в интервала за \"Не безпокойте\"",
            "event": {
                "name-present": "Липсва име",
                "send-date-present": "Липсва дата",
                "send-time-present": "Липсва час",
                "template-present": "Липсва шаблон",
                "invalid-date-past": "Датата трябва да е бъдеща",
                "invalid-date-format": "Грешен формат (дд/мм/гггг)",
                "invalid-time-past": "Часът трябва да е бъдещ"
            },
            "group-name-unique": "Полето Име на група трябва да е уникално",
            "group-name-not-blank": "Полето Име на група трябва да бъде попълнено"
        },
        "confirm": {
            "contact-delete": "Сигурни ли сте, че желаете да изтриете този контакт?",
            "contacts-delete": "Сигурни ли сте, че желаете да изтриете избраните контакти?",
            "select-contacts-to-delete": "Моля, първо изберете контакти",
            "select-contact-groups-to-delete": "Моля, първо изберете групи",
            "select-template-groups-to-delete": "Моля, първо изберете групи",
            "select-templates-to-delete": "Моля, първо изберете шаблони",
            "group-delete": "Сигурни ли сте, че желаете да изтриете избраната група?",
            "groups-delete": "Сигурни ли сте, че желаете да изтриете избраните групи?",
            "template-delete": "Сигурни ли сте, че желаете да изтриете избрания шаблон?",
            "templates-delete": "Сигурни ли сте, че желаете да изтриете избраните {{templatesCount}} шаблони?",
            "template-groups-delete": "Сигурни ли сте, че желаете да изтриете избраните {{groupCount}} групи от шаблони?",
            "select-template-to-delete": "Моля, първо изберете шаблони",
            "event-delete-title": "Изтриване на кампания",
            "event-delete": "Сигурни ли сте, че желаете да изтриете тази кампания?",
            "payment-method-delete": "Сигурни ли сте, че желаете да изтриете този метод за плащане?",
            "custom-field-delete": "Сигурни ли сте, че искате да изтриете това персонализирано поле?"
        },
        "tooltip": {
            "dashboard-reports-successful": "Това са успешно доставени съобщения, достигнали до посочените получатели.",
            "dashboard-reports-unsuccessful": "Това са съобщения, които са приети от мобилните оператори и изпратени към посочените получателите, но не са приети от тях.",
            "dashboard-reports-processing": "Това са съобщения в обработка. След като бъдат обработени, те ще се прехвърлят в графата Успешно или Неуспешно доставени.",
            "template-body-has-tags": "Шаблонът съдържа динамични полета. Това ще промени дължината на изпратения SMS и може да доведе до изпращане на допълнителни SMS-и."
        },
        "contacts": {
            "confirm-import": "Импорт",
            "not-imported-contacts": "<span>{{invalid}}</span> контакти съдържат невалиден телефонен номер.",
            "duplicated-contacts": "<span>{{duplicated}}</span> контакта са дубликати и съществуват вече в системата или документа.",
            "imported-contacts": "<span>{{imported}}</span> контакта бяха успешно импортирани.",
            "import": {
                "heading": "Импортиране на контакти",
                "file-type-hint": "Моля, изберете файл (<span class=\"bold\">CSV, XLS, XLSX</span>) или свалете примерен" + " шаблон",
                "download-template": "Изтегляне на шаблон",
                "back-to-contacts": "Върни се в контакти",
                "back": "Назад",
                "new-import": "Ново импортиране",
                "encoding-utf8": "UTF-8",
                "encoding-cp1251": "Windows 1251",
                "encoding-latin": "Latin 1 (ISO-8859-1)",
                "delimiter-comma": ",",
                "delimiter-pipe": "|",
                "delimiter-tab": "tab",
                "no-column-found": "Не е намерена колона с такова име",
                "loading": "Зареждане...",
                "not-found": "Няма намерени контакти.",
                "invalid-contacts": "Неуспешно импортирани контакти",
                "successful-import": "Успешно импортирани контакти",
                "mapping": "Разпределение",
                "first-name": "Име",
                "last-name": "Фамилия",
                "phone-number": "Телефонен номер",
                "email": "Имейл",
                "company": "Фирма",
                "website": "Уеб сайт",
                "address": "Адрес",
                "note": "Бележка",
                "google": "Импортиране на контакти от Google",
                "google-button": "Импортиране",
                "google-revoke": "Изход",
                "map-phone-field": "Mоля, изберете коя колона репрезентира телефонния номер.",
                "map-unique-field": "Mоля, изберете коя колона репрезентира полето за валидация по уникалност.",
                "background-processing-started": "Вашият файл се обработва. Ще получите email, когато операцията приключи.",
                "error": {
                    "default": "Грешка при импортиране.",
                    "format": "Файловият формат е грешен.",
                    "delimiter": "Разделителят не може да бъде запетая."
                },
                "invalid-contacts-csv": "<a href=\"{{csvLink}}\" target='_blank'>Файл</a> с неуспешно импортираните" + " контакти"
            },
            "all": "Всички контакти",
            "ungrouped": "Негрупирани контакти",
            "google": "Импортирани от Google",
            "groups-select-all": "При избор на бутона ще селектирате<br> всички групи и контактите в тях",
            "contacts-select-all": "При избиране на бутона ще селектирате<br> всички контакти в групата",
            "continue-editing-contact": "Продължи",
            "deny-editing-contact": "Откажи",
            "save-contact-message": "За да запаметите Вашия контакт, моля продължете с въвеждането на информация"
        },
        "payments": {
            "subscription_canceled": "Отказано",
            "subscription_charged_successfully": "Успешно",
            "subscription_charged_unsuccessfully": "Неуспешно",
            "subscription_went_past_due": "Просрочено",
            "payment-method": {
                "add": "Добави метод за плащане",
                "card-number": "Номер на карта",
                "expiration-date": "Валидна до",
                "cvv": "CVV",
                "postal-code": "Пощенски код",
                "pay": "Плати"
            },
            "download-invoice": "Свалете фактура",
            "missing-payments": "Вие все още нямате направено плащане."
        },
        "plans": {
            "currency": "лв.",
            "currency_per_month": "лв./м",
            "credits_per_month": "кредита на месец",
            "credits": "кредита",
            "symbols_in_sms": "{{symbols}} символа в SMS",
            "full_functionality": "Пълна функционалност",
            "debit": "Месечен абонамент",
            "online_invoice": "Електронно фактуриране",
            "choose": "Избери",
            "chosen": "Избран"
        },
        "formats": {
            "date": "DD MMM YYYY",
            "date_short": "DD MM YYYY",
            "time": "HH:mm",
            "time_full": "HH:mm:ss Z",
            "datetime": "DD MMM YYYY, HH:mm:ss",
            "datetime_full": "DD MMM YYYY, HH:mm:ss Z",
            "time_zone": "Z"
        },
        "settings": {
            "credits": {
                "info": "<p><span class=\"bold\">1 кредит=1 SMS / 1 обаждане 30 сек.</span></p><p>Ако желаете да закупите по-голям пакет свържете с нас:</p><p><a href=\"tel:+35924372806\">+359 (2) 437 2806</a><a href=\"mailto:contact@povikvane.io\">contact@povikvane.io</a></p>",
                "more-information-required": "<p>За да преминете към друг пакет, моля попълнете Вашите лични или фирмени данни в екран \"Профил\", за да можем да Ви издаваме фактури за плащанията.</p><p>След като ги попълните, може да се върнете тук и да изберете желаният от Вас пакет.</p>",
                "change-system-plan": "<p>Вие сте на системен пакет. За да преминете към друг пакет, моля свържете се с нас.</p>",
                "change-plan": "Промяна на абонаментен план",
                "change-plan-prompt": "Потвърждавате ли промяната на абонаментния план?<br><br>Имате  {{credits}} неизползвани кредита, които няма да се прехвърлят към новия Ви абонаментен план.",
                "credits": "Кредити",
                "change-plan-payment-title": "Вашата заявка се обработва в момента",
                "change-plan-payment-message": "След одобряването ѝ ще получите имейл с потвърждение и Вашият нов абонаментен план ще бъде отразен в Povikvane.",
                "change-plan-progress-message": "Вашата заявка се обработва в момента. Моля, опитайте след 2 минути."
            },
            "main": {
                "language": "Език",
                "languages": {
                    "en": {
                        "short": "En",
                        "long": "Английски"
                    },
                    "bg": {
                        "short": "Bg",
                        "long": "Български"
                    }
                },
                "timezone": "Часова зона",
                "date-format": "Формат на датата",
                "date-format-translate": "23-ти Юни 2016",
                "start-week": "Начало на седмицата от",
                "days": {
                    "mo": "Понеделник",
                    "tu": "Вторник",
                    "we": "Сряда",
                    "th": "Четвъртък",
                    "fr": "Петък",
                    "sa": "Събота",
                    "su": "Неделя",
                    "short": {
                        "mo": "Пон",
                        "tu": "Вто",
                        "we": "Сря",
                        "th": "Чет",
                        "fr": "Пет",
                        "sa": "Съб",
                        "su": "Нед"
                    }
                },
                "disturb": "Не безпокойте"
            },
            "information": {
                "content": "<h3>Относно Povikvane</h3><p>Всички права запазени © 2010-2018 Щрак БГ" + " ЕООД</p><h3>Лицензи</h3>",
                "show-licenses": "Използван софтуер:",
                "show-licenses-action": "покажи"
            },
            "notifications": {
                "content": "<p>Povikvane ще Ви информира за насрочените кампании чрез имейл.</p><p>Можете да изключите напомнянето от тук:</p>",
                "upcoming-campaign": "Напомняне за започване на предстояща кампания",
                "completed-campaign": "Информиране при приключване на кампания",
                "confirm-delete": "Потвърждения при изтриване"
            },
            "payments": {
                "history": "История на плащанията"
            },
            "profile": {
                "personal-info": "Лична Информация",
                "company-details": "Фирмени данни",
                "payment-methods": "Метод на плащане",
                "payment-method-delete": "Изтриване",
                "payment-method-missing": "Към този момент нямате създаден метод за плащане. Можете да добавите метод на плащане от екран \"Кредити\" във Вашите настройки.",
                "welcome-screen": "Начален екран",
                "welcome-screen-button": "Начален екран"
            },
            "security": {
                "delete-account-title": "Изтриване на профил",
                "delete-account": "Сигурни ли сте, че искате да изтриете профила си?",
                "delete-success": "Вашият акаунт беше успешно изтрит!",
                "change-password": "Запази"
            },
            "api-access": {
                "title": "API достъп",
                "keys": "Ключове за достъп",
                "service-id": "Service ID",
                "service-id-info": "Идентификатор на Вашия акаунт в Povikvane",
                "active-keys": "Активни ключове",
                "revoke-key": "Спиране на достъп",
                "key": "Ключ",
                "actions": "Действия",
                "generate-key": "Създаване на нов ключ",
                "webhook-title": "Webhook адрес",
                "webhook-description": "URL адрес, на който да изпращаме данни за статуса на съобщения изпратени през API.",
                "webhook-placeholder": "url адрес за webhook",
                "no-active-keys": "В момента нямате активни ключове",
                "webhook-save-notification": "Webhook адреса беше записан успешно",
                "download-docs": "Изтегляне на документация",
                "doc-header": "API Документация"
            },
            "custom-fields": {
                "unique-validation-field-title": "Избор на уникално поле за валидация",
                "title": "Персонализирани полета",
                "add-custom-field": "Добави поле",
                "custom-field-save-notification": "Името на полето е променено успешно",
                "custom-field-create-notification": "Полето е създадено успешно",
                "custom-field-create-name": "Поле",
                "custom-field-delete-notification": "Полето е изтрито успешно",
                "no-custom-fields": "В момента нямате персонализирани полета"
            }
        },
        "placeholder": {
            "template": {
                "search": "Търси...",
                "group-search": "Търси...",
                "name": "Име на шаблон"
            },
            "profile": {
                "first-name": "Име",
                "last-name": "Фамилия",
                "email": "Имейл",
                "organization": "Фирма",
                "eik": "ЕИК",
                "address": "Адрес",
                "city": "Град",
                "zip-code": "Пощенски код",
                "manager": "МОЛ",
                "phone": "Телефонен номер"
            },
            "contact": {
                "first-name": "Име",
                "last-name": "Фамилия",
                "company": "Фирма",
                "website": "Уебсайт",
                "phone": "Телефонен номер (359xxxxxxxx)",
                "email": "Имейл",
                "address": "Адрес",
                "group-search": "Търси...",
                "search": "Търси...",
                "note": "Бележка"
            },
            "login-form": {
                "email": "Имейл",
                "password": "Парола"
            },
            "password-reset-form": {
                "email": "Имейл",
                "password": "Парола",
                "password-confirmation": "Потвърди парола"
            },
            "forgotten-password-form": {
                "email": "Имейл"
            },
            "register-form": {
                "email": "Имейл",
                "password": "Парола",
                "registrations-closed": "Моля да ни извините! В момента не се приемат регистрации на нови потребители."
            },
            "security": {
                "current-password": "Текуща парола",
                "new-password": "Нова парола",
                "new-password-confirmation": "Повтори новата парола"
            },
            "help": {
                "search": "Търси..."
            },
            "select": {
                "group": "Моля, изберете група...",
                "groups": "Моля, изберете име...",
                "interval": "Моля, изберете интервал...",
                "template": "Моля, изберете шаблон...",
                "import-encoding": "Моля, изберете кодировка за файла...",
                "import-delimiter": "Моля, изберете разделител на колоните във файла...",
                "add-to-group": "Добавете към група",
                "no-templates": "Нямате добавени шаблони.",
                "create-template": "Създай шаблон"
            },
            "reports": {
                "search": "Търси...",
                "datepicker": {
                    "from": "От",
                    "to": "До"
                }
            },
            "events": {
                "group-search": "Търси...",
                "contact-search": "Търси...",
                "event-name": "Име на кампания",
                "date": "Дата"
            }
        },
        "reports": {
            "title": "Отчети",
            "category-reports": "Кампании",
            "sent-sms": "Изпратени съобщения",
            "sent-voice": "Изпратени гласови съобщения",
            "export-campaign": "Експортирай избраната кампания",
            "choose": "Избери",
            "sms-template-type": "SMS",
            "voice-template-type": "Гласови съобщения",
            "no-campaigns-to-show": "Нямате кампании, отговарящи на търсенето.",
            "edit-campaign": "Моля, изберете кампания.",
            "successfully-sent": "Успешно доставени",
            "unsuccessfully-sent": "Неуспешно доставени",
            "in-process": "В процес на изпращане",
            "failed": "Отхвърлени от оператор",
            "export-campaigns": "Експортиране на кампании",
            "export-campaigns-msg": "Не сте селектирали кампании, които да експортнете",
            "select-all": "При избор на бутона ще селектирате<br> всички кампании",
            "api-sms-report-header": "Изпратени съобщения за {{period}}"
        },
        "events": {
            "add-new": "Създай кампания",
            "event-receipt": "Обобщение за предстоящата кампания",
            "template-name": "Име на шаблона",
            "when": "Кога",
            "repeat": "Повтаряемост",
            "hour": "Час",
            "contacts": "Контакти",
            "credits": "Кредити",
            "send-to": "Ще се изпрати до",
            "will-spend": "Ще изразходва",
            "available-credits": "Оставащи кредити",
            "voice-event-receipt": "Обобщение за предстоящата кампания",
            "prepare": "Изчисляване и подготвяне",
            "schedule": "Насрочване на кампанията",
            "done": "Готово",
            "repeat-on-failure": "Грешка при повтаряемостта!",
            "select-event": "Избери нова кампания",
            "sms-event": "SMS кампания",
            "voice-event": "Автоматизирано обаждане (ОЧАКВАЙТЕ СКОРО)",
            "ungrouped-contacts": "Негрупирани контакти",
            "google-contacts": "Импортирани от Google",
            "campaign-scheduled": "Насрочена кампания",
            "campaign-edit": "Промяна на кампанията",
            "campaign-delete": "Изтриване на кампанията",
            "campaign-stopped": "Вашата кампания е спряна",
            "no-contacts": "Няма контакти",
            "intervals": {
                "do-not-repeat": "Не повтаряй",
                "daily": "Всеки ден",
                "weekly": "Всяка седмица",
                "monthly": "Всеки месец",
                "annually": "Всяка година"
            },
            "events-today": "Кампании днес",
            "no-events": "Нямате кампания днес",
            "credits-left": "Оставащи кредити:",
            "not-enough-credits": "Нямате достатъчно кредити, за да насрочите тази кампания. Моля, минете на по-висок" + " план.",
            "running_in_less_than_five_minutes-title": "Не можете да анулирате кампанията",
            "running_in_less_than_five_minutes": "Не можете да анулирате кампанията, тъй като тя ще започне скоро или вече е активна.",
            "past-date_title": "Стартирала кампания",
            "past-date": "Кампанията вече е стартирала",
            "change-plan": "Кликнете тук за промяна",
            "set-event": "Задай кампания",
            "groups": "Групи",
            "types": {
                "sms": "SMS кампания",
                "voice": "Гласова кампания"
            },
            "used-for-upcoming-campaigns": "Този шаблон участва във вече насрочена кампания. Неговата промяна или изтриване няма да се отрази в шаблона на вече насрочената кампания.",
            "groups-select-all": "При избор на бутона ще селектирате<br> всички групи и контактите в тях",
            "contacts-select-all": "При избор на бутона ще селектирате<br> всички контакти в групата"
        },
        "templates": {
            "groups-select-all": "При избор на бутона ще селектирате<br> всички групи шаблони и шаблоните в тях",
            "templates-select-all": "При избор на бутона ще селектирате<br> всички шаблони в групата",
            "continue-editing-template": "Продължи",
            "deny-editing-template": "Откажи",
            "save-template-message": "За да запаметите Вашия шаблон, моля продължете с въвеждането на информация",
            "play": "Прослушване",
            "stop": "Спиране",
            "delete": "Изтриване",
            "record": "Запис",
            "upload": "Зареждане на WAV файл"
        },
        "notifications": {
            "realtime": {
                "no-notifications": "В момента нямате известия.",
                "import-finished": {
                    "zero": "Вашето импортиране приключи. Няма успешно импортирани контакти.",
                    "one": "Вашето импортиране приключи. Един контакт беше импортиран успешно.",
                    "other": "Вашето импортиране приключи. {{count}} контакта бяха импортирани успешно."
                },
                "campaign-prepared": "Вашата кампания {{name}} е подготвена и ще се изпълни по разписание. Вече можете и да я променяте или откажете."
            },
            "success-update-campaign": "Вашата кампания е успешно обновена",
            "success-prepared-campaign": "Вашата кампания е успешно подготвена",
            "success-scheduled-campaign": "Вашата кампания е успешно насрочена",
            "success-delete-campaign": "Вашата кампания е успешно изтрита",
            "error-delete-campaign": "Кампанията не може да бъде изтрита",
            "error-background-processing-active": "Кампанията все още се подготвя и не може да бъде променяна",
            "success-create-companies": "Успешно създадена кампания",
            "success-update-companies": "Успешно обновена кампания",
            "success-delete-companies": "Успешно изтрита кампания",
            "success-create-contacts": "Успешно създаден контакт",
            "success-update-contacts": "Успешно обновен контакт",
            "success-delete-contacts": "Успешно изтрит контакт",
            "success-delete-many-contacts": "Успешно изтрити контакти",
            "success-add-contacts-to-group": "Успешно добавени контакти към група",
            "success-create-contact-groups": "Успешно създадена група",
            "success-update-contact-groups": "Успешно обновена група",
            "success-delete-contact-groups": "Успешно изтрита група",
            "success-delete-many-contact-groups": "Успешно изтрити групи",
            "success-create-imports": "Успешно импортирани данни",
            "success-create-methods": "Успешно създаден метод за плащане",
            "success-update-methods": "Успешно обновен метод за плащане",
            "success-delete-methods": "Успешно изтрит метод за плащане",
            "success-create-reports": "Успешно създаден отчет",
            "success-update-reports": "Успешно обновен отчет",
            "success-delete-reports": "Успешно изтрит отчет",
            "success-create-templates": "Успешно създаден шаблон",
            "success-update-templates": "Успешно обновен шаблон",
            "success-delete-templates": "Успешно изтрит шаблон",
            "success-delete-many-templates": "Успешно изтрити шаблони",
            "success-add-templates-to-group": "Успешно добавени шаблони към група",
            "success-create-template-groups": "Успешно създадена шаблон група",
            "success-update-template-groups": "Успешно обновена шаблон група",
            "success-delete-template-groups": "Успешно изтрита шаблон група",
            "success-delete-many-template-groups": "Успешно изтрити шаблон групи",
            "success-update-users": "Успешно обновени настройки на потребителя",
            "success-update-company": "Успешно обновена компания",
            "success-upload-voice-file": "Успешно качен файл",
            "free-plan-notice": "Нови цени на абонаментни планове",
            "active-plan-notice": "Нови <a target='_blank' href='http://povikvane.io/pricing'>цени</a> на абонаментни" + " планове от 01.04.2018. При изтичане на абонаментният Ви план след тази дата Вие автоматично ще преминете на \"Безплатен абонаментен план.\""
        },
        "errors": {
            "validation-title": "Грешка при валидация",
            "validation-text": "Моля, попълнете задължителните полета",
            "events": {
                "template-not-found": "Липсва шаблон",
                "template-not-found-title": "Грешка",
                "contacts-not-selected": "Няма избрани контакти",
                "contacts-not-selected-title": "Грешка",
                "already-scheduled-title": "Грешка",
                "already-scheduled": "Кампанията е вече насрочена"
            },
            "busy-payment-gateway": "Възникна проблем с интернет връзката. Вашата заявка не беше обработена. Моля, опитайте отново.",
            "invalid-card-details": "Възникна грешка при обработката на плащането. Проверете данните, които сте въвели и опитайте отново. Ако виждате това съобщение повторно, моля, свържете се с картоиздателя си.",
            "card-processor-declined": "Вашето плащане е отказано. Моля, свържете се с банката издател на Вашата карта" + " или въведете друга карта.",
            "login-general-error": "Възникна грешка",
            "microphone-access": "За да запис на звук трябва да позволите достъп до микрофона",
            "upload-audio": "Възникна грешка при качването на файла.",
            "upload-audio-wrong-file-type": "Грешен тип файл. Моля, използвайте WAV файл.",
            "user-not-approved-title": "Грешка",
            "user-not-approved": "Потребилетят не е одобрен от администратор."
        },
        "tos": {
            "title": "<h1>BG Povikvane Terms of Service</h1>",
            "text": "<p>BG Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n                dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n                suscipit ullam. Dolor, laborum!</p>\n            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>",
            "go-back": "Назад към формата за регистрация."
        },
        "cookieconsent": {
            "message": "Този уебсайт използва бисквитки (cookies) за повишаване на ефективността си.",
            "dismiss": "Продължете",
            "link": "Научи повече",
            "href": "http://povikvane.io/obshti-uslovia"
        },
        "welcome": {
            "title": "Добре дошли в Povikvane",
            "create-event": "Създай кампания",
            "slide1": {
                "title": "За да изпратите своя първи SMS, следвайте тези няколко стъпки.",
                "text": "\u0412\u044A\u0432\u0435\u0434\u0435\u0442\u0435 \u0412\u0430\u0448\u0438\u0442\u0435 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0438.\n                    \u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0431\u0443\u0442\u043E\u043D \"\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u0438\" \u043E\u0442 \u043D\u0430\u0447\u0430\u043B\u043D\u0438\u044F \u0435\u043A\u0440\u0430\u043D.\n                    \u0412\u044A\u0432\u0435\u0434\u0435\u0442\u0435 \u0440\u044A\u0447\u043D\u043E \u0438\u043B\u0438 \u0438\u043C\u043F\u043E\u0440\u0442\u0438\u0440\u0430\u0439\u0442\u0435 \u0412\u0430\u0448\u0438\u0442\u0435 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0438."
            },
            "slide2": {
                "title": "Създайте Вашето първо съобщение.",
                "text": "\u041E\u0442 \u043D\u0430\u0447\u0430\u043B\u043D\u0438\u044F\u0442 \u0435\u043A\u0440\u0430\u043D \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0431\u0443\u0442\u043E\u043D\u0430 \"\u0428\u0430\u0431\u043B\u043E\u043D\u0438\".\n                    \u0412\u044A\u0432\u0435\u0434\u0435\u0442\u0435 \u0438\u043C\u0435 \u043D\u0430 \u0448\u0430\u0431\u043B\u043E\u043D\u0430 \u0438 \u0442\u0435\u043A\u0441\u0442 \u043D\u0430 \u0441\u044A\u043E\u0431\u0449\u0435\u043D\u0438\u0435\u0442\u043E, \u043A\u043E\u0435\u0442\u043E \u0438\u0441\u043A\u0430\u0442\u0435 \u0434\u0430 \u0438\u0437\u043F\u0440\u0430\u0442\u0438\u0442\u0435.\n                    \u0412\u0435\u0447\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u0434\u0430 \u0438\u0437\u043F\u0440\u0430\u0442\u0438\u0442\u0435 \u0412\u0430\u0448\u0435\u0442\u043E \u043F\u044A\u0440\u0432\u043E \u0441\u044A\u043E\u0431\u0449\u0435\u043D\u0438\u0435."
            },
            "slide3": {
                "title": "\u041E\u0442 \u0435\u043A\u0440\u0430\u043D \"\u041E\u0442\u0447\u0435\u0442\u0438\" \u043C\u043E\u0436\u0435\u0442\u0435 \u0434\u0430 \u0440\u0430\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043A\u043E\u043B\u043A\u043E \u043E\u0442 \u0412\u0430\u0448\u0438\u0442\u0435 SMS-\u0438 \u0441\u0430 \u0434\u043E\u0441\u0442\u0430\u0432\u0435\u043D\u0438.",
                "text": "\u0421\u043B\u0435\u0434\u0435\u0442\u0435 \u0431\u0440\u043E\u044F \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438 SMS-\u0438, \u043A\u0430\u043A\u0442\u043E \u0438 \u0434\u0440\u0443\u0433\u0438\u0442\u0435 \u0441\u0442\u0430\u0442\u0443\u0441\u0438 \u043D\u0430 \u0438\u0437\u043F\u0440\u0430\u0442\u0435\u043D\u0438\u0442\u0435 \u0441\u044A\u043E\u0431\u0449\u0435\u043D\u0438\u044F.\n                  \u041C\u043E\u0436\u0435\u0442\u0435 \u0434\u0430 \u0441\u0432\u0430\u043B\u0438\u0442\u0435 \u043F\u043E\u0434\u0440\u043E\u0431\u0435\u043D \u043E\u0442\u0447\u0435\u0442 \u0437\u0430 \u0432\u0441\u044F\u043A\u0430 \u0435\u0434\u043D\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F \u0432 \u0443\u0434\u043E\u0431\u0435\u043D \u0437\u0430 \u0412\u0430\u0441 \u0444\u0430\u0439\u043B\u043E\u0432 \u0444\u043E\u0440\u043C\u0430\u0442.\n                  \u0427\u0440\u0435\u0437 \u0430\u043D\u0430\u043B\u0438\u0437 \u043D\u0430 \u0434\u0430\u043D\u043D\u0438\u0442\u0435 \u0412\u0438\u0435 \u0449\u0435 \u043F\u043E\u0434\u043E\u0431\u0440\u0438\u0442\u0435 \u0432\u0441\u044F\u043A\u0430 \u0441\u043B\u0435\u0434\u0432\u0430\u0449\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F."
            },
            "slide4": {
                "title": "\u0417\u0430 \u0434\u0430 \u043D\u0430\u0441\u0440\u043E\u0447\u0438\u0442\u0435 \u0438\u0437\u043F\u0440\u0430\u0449\u0430\u043D\u0435\u0442\u043E \u043D\u0430 \u043F\u044A\u0440\u0432\u0438\u044F \u0412\u0438 SMS, \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \"\u041D\u043E\u0432\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F\".",
                "text": "\u0412\u044A\u0432\u0435\u0434\u0435\u0442\u0435 \u0434\u0435\u043D \u0438 \u0447\u0430\u0441 \u0437\u0430 \u043D\u0430\u0447\u0430\u043B\u043E \u043D\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F\u0442\u0430.\n                  \u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u0448\u0430\u0431\u043B\u043E\u043D\u0430 \u0441 \u0442\u0435\u043A\u0441\u0442\u0430 \u043D\u0430 \u0441\u044A\u043E\u0431\u0449\u0435\u043D\u0438\u0435\u0442\u043E, \u043A\u0430\u043A\u0442\u043E \u0438 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0438\u0442\u0435, \u0434\u043E \u043A\u043E\u0438\u0442\u043E \u0438\u0441\u043A\u0430\u0442\u0435 \u0434\u0430 \u0438\u0437\u043F\u0440\u0430\u0442\u0438\u0442\u0435 SMS.\n                  \u0418\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \"\u041D\u0430\u0441\u0440\u043E\u0447\u0432\u0430\u043D\u0435 \u043D\u0430 \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F\u0442\u0430\",  \u0437\u0430 \u0434\u0430 \u043D\u0430\u0441\u0440\u043E\u0447\u0438\u0442\u0435 \u0438\u0437\u043F\u0440\u0430\u0449\u0430\u043D\u0435\u0442\u043E \u043D\u0430 \u0412\u0430\u0448\u0430\u0442\u0430 SMS \u043A\u0430\u043C\u043F\u0430\u043D\u0438\u044F."
            },
            "slide5": {
                "title": "Настройте Вашия профил.",
                "text": "\u041E\u0442 \u0435\u043A\u0440\u0430\u043D \"\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438\" \u0412\u0438\u0435 \u0443\u043F\u0440\u0430\u0432\u043B\u044F\u0432\u0430\u0442\u0435 \u0441\u0432\u043E\u044F \u043F\u0440\u043E\u0444\u0438\u043B.\n                  \u0412\u044A\u0432\u0435\u0436\u0434\u0430\u0442\u0435 \u0438\u043B\u0438 \u0440\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u0430\u0442\u0435 \u043B\u0438\u0447\u043D\u0430 \u0438 \u0444\u0438\u043D\u0430\u043D\u0441\u043E\u0432\u0430 \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F.\n                  \u0418\u0437\u0431\u0438\u0440\u0430\u0442\u0435 \u043E\u043F\u0442\u0438\u043C\u0430\u043B\u043D\u0438\u044F \u0437\u0430 \u0412\u0430\u0441 SMS \u043F\u043B\u0430\u043D.\n                  \u0417\u0430\u0434\u0430\u0432\u0430\u0442\u0435 \u043F\u0440\u0435\u0434\u043F\u043E\u0447\u0438\u0442\u0430\u043D\u0438 \u043E\u0442 \u0412\u0430\u0441 \u0441\u0438\u0441\u0442\u0435\u043C\u043D\u0438 \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438."
            }
        },
        "gdpr": {
            "the-terms-of-service-title": "Важно съобщение",
            "the-terms-of-service": "условията за ползване",
            "the-terms-of-service-href": "http://povikvane.io/uslovia-polzvane",
            "agree-terms-text": "<p>Във връзка с влизащия в сила от 25.05.2018г. регламент на ЕС за защита на информацията, бихме искали да Ви информираме, че общите условия за използване на платформата Povikvane са обновени и са съобразени с новия регламент.</p><p>Ако желаете да продължите да изпращате своите SMS-и и гласови съобщения чрез app.povikvane.io , Ви молим да прочетете условията:</p>",
            "agree-to-terms": "Съгласен съм",
            "delete-account": "Не съм съгласен"
        }
    };
});