define('frontend/components/welcome-slider', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        cardClasses: ['contacts', 'templates', 'reports', 'newEvent', 'settings'],
        router: service(),
        didRender: function didRender() {
            var _this = this;

            this.$('.welcome_screen__slider').slick({
                dots: true,
                appendArrows: '.welcome_screen__nav',
                appendDots: '.welcome_screen__nav'
            }).on('afterChange', function (event, slick, currentSlide) {
                var currentClass = _this.get('cardClasses.' + currentSlide);
                _this.$('.card').removeClass('show_preview');
                _this.$('.' + currentClass).addClass('show_preview');
            });
        },
        willDestroyElement: function willDestroyElement() {
            this.$('.welcome_screen__slider').slick('unslick');
        },

        actions: {
            transitionToIndex: function transitionToIndex() {
                this.get('router').transitionTo('inner-app');
            }
        }
    });
});