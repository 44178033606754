define('frontend/routes/inner-app/template-groups', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/unsaved-data-transition-block', 'frontend/utils/unique-group-name'], function (exports, _authenticatedRouteMixin, _unsavedDataTransitionBlock, _uniqueGroupName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedDataTransitionBlock.default, {
        queryParams: {
            searchTmplGroup: {
                refreshModel: true
            }
        },

        selectedItems: service(),
        specialItems: service(),
        queue: service(),
        modal: service(),
        notifications: service(),

        groupIsSaving: false,

        model: function model(params) {
            var _this = this;

            var specialItems = this.get('specialItems');

            this.get('selectedItems').flush();
            return this.get('queue').add(function () {
                return specialItems.initialize('template').then(function () {
                    return _this.loadModel(params);
                });
            }, 'template');
        },
        loadModel: function loadModel(params) {
            var specialItems = this.get('specialItems');
            var searchTmplGroup = params.searchTmplGroup ? params.searchTmplGroup : '';
            searchTmplGroup = searchTmplGroup.toString().replace(/\s+/g, ' ').trim();

            //start loading counters
            var loadCounters = specialItems.loadGroupCounters('template');
            return this.get('store').query('template-group', {
                reload: true,
                filter: { name: searchTmplGroup }
            }).then(function (groups) {
                groups.forEach(function (group) {
                    group.set('isChecked', false);
                });

                return loadCounters.then(function (counters) {
                    return specialItems.syncGroups('template', counters).then(function () {
                        return groups.toArray();
                    });
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.initDebouncedQueryParam('searchTmplGroup');
        },


        actions: {
            checkAllGroups: function checkAllGroups(checked) {
                var _this2 = this;

                var params = this.paramsFor(this.routeName);

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    var promises = [];
                    _this2.modelFor(_this2.routeName).forEach(function (group) {
                        group.set('isChecked', checked);
                        promises.push(specialItems.getIds('template', group.get('id')).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        }));
                    });

                    if (!params.searchTmplGroup) {
                        if (checked) {
                            promises.push(specialItems.getIds('template', 'no-group').then(function (ids) {
                                selectedItems.addMany(ids);
                            }));
                        } else {
                            promises.push(specialItems.getIds('template', 'no-group').then(function (ids) {
                                selectedItems.removeMany(ids);
                            }));
                        }
                    }
                    return Ember.RSVP.all(promises);
                }, 'template');
            },
            checkTemplatesForGroup: function checkTemplatesForGroup(group, checked) {
                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    if (!group) {
                        return false;
                    }
                    group.set('isChecked', checked);
                    if (specialItems.count('template', group.get('id')) === 0) {
                        return false;
                    }

                    return specialItems.getIds('template', group.get('id')).then(function (ids) {
                        if (checked) {
                            selectedItems.addMany(ids);
                        } else {
                            selectedItems.removeMany(ids);
                        }
                    });
                }, 'template');
            },
            addTemplateGroup: function addTemplateGroup() {
                var _this3 = this;

                var specialItems = this.get('specialItems');
                this.get('queue').add(function () {
                    if (!_this3.get('groupIsSaving')) {
                        _this3.set('groupIsSaving', true);
                        var groupName = (0, _uniqueGroupName.default)(_this3.modelFor(_this3.routeName), _this3.i18n.t('general.new-template-group').string, 0);
                        var group = _this3.store.createRecord('template-group', {
                            name: groupName
                        });

                        return group.save().then(function (group) {
                            _this3.modelFor(_this3.routeName).pushObject(group);
                            _this3.get('notifications').showSuccess(_this3.get('i18n').t('notifications.success-create-template-groups'));
                            group.set('isEditing', true);
                            _this3.set('groupIsSaving', false);
                            return specialItems.createGroup('template', group.get('id'));
                        });
                    }
                    return false;
                }, 'template');
            },
            deleteTemplateGroups: function deleteTemplateGroups(groups) {
                var _this4 = this;

                var checked = groups.filterBy('isChecked');
                var numberCheckedGroups = checked.get('length');
                var specialItems = this.get('specialItems');

                if (numberCheckedGroups === 0) {
                    this.get('modal').alert({
                        template: 'dialogs/messages/delete-model',
                        message: this.i18n.t('confirm.select-template-groups-to-delete')
                    });
                    return;
                }

                this.get('modal').confirm({ message: this.i18n.t('confirm.template-groups-delete', { groupCount: numberCheckedGroups }) }).then(function () {
                    _this4.get('queue').add(function () {
                        var deletePromise = void 0;
                        checked.forEach(function (group) {
                            deletePromise = _this4.get('queue').add(function () {
                                return group.destroyRecord();
                            }, 'templateDeleteGroup');
                        });

                        return deletePromise.then(function () {
                            return specialItems.syncGroups('template').then(function () {
                                checked.forEach(function (group) {
                                    _this4.modelFor(_this4.routeName).removeObject(group);
                                });
                                _this4.get('notifications').showSuccess(_this4.get('i18n').t('notifications.success-delete-many-template-groups'));
                                _this4.transitionTo("inner-app.template-groups.templates", "all");
                            });
                        });
                    }, 'template');
                });
            },
            deleteGroup: function deleteGroup(tmpGroup) {
                var _this5 = this;

                var specialItems = this.get('specialItems');
                this.get('modal').confirm({ message: this.i18n.t('confirm.group-delete') }).then(function () {
                    _this5.get('queue').add(function () {
                        if (!tmpGroup || tmpGroup.get('isDeleted')) {
                            return false;
                        }

                        return tmpGroup.destroyRecord().then(function (group) {
                            return specialItems.syncGroups('template').then(function () {
                                _this5.modelFor(_this5.routeName).removeObject(group);
                                _this5.get('notifications').showSuccess(_this5.get('i18n').t('notifications.success-delete-template-groups'));
                                _this5.transitionTo("inner-app.template-groups.templates", "all");
                            });
                        });
                    }, 'template');
                });
            },
            saveGroup: function saveGroup(tmpGroup) {
                var _this6 = this;

                this.get('queue').add(function () {
                    return tmpGroup.validate().then(function (_ref) {
                        var model = _ref.model,
                            validations = _ref.validations;

                        if (validations.get('isValid')) {
                            return tmpGroup.save().then(function () {
                                _this6.get('notifications').showSuccess(_this6.get('i18n').t('notifications.success-update-template-groups'));
                                return true;
                            }).catch(function (response) {
                                _this6.get('modal').alert({
                                    title: _this6.i18n.t('errors.validation-title'),
                                    message: _this6.i18n.t(response.errors[0].detail)
                                });
                                tmpGroup.rollbackAttributes();
                                return true;
                            });
                        } else {
                            _this6.get('modal').alert({
                                title: _this6.i18n.t('errors.validation-title'),
                                message: _this6.i18n.t(model.get('validations.errors').get('firstObject').get('message'))
                            });
                            tmpGroup.rollbackAttributes();
                            return true;
                        }
                    });
                }, 'template');
            }
        }
    });
});