define('frontend/services/header-loading-indicator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        storeLoading: true,
        ajaxLoading: false,
        serviceLoading: false,
        queueLoading: false,

        start: function start() {
            this.set('storeLoading', true);
        },
        stop: function stop() {
            this.set('storeLoading', false);
        },
        startAjax: function startAjax() {
            this.set('ajaxLoading', true);
        },
        stopAjax: function stopAjax() {
            this.set('ajaxLoading', false);
        },
        startService: function startService() {
            this.set('serviceLoading', true);
        },
        stopService: function stopService() {
            this.set('serviceLoading', false);
        },
        startQueue: function startQueue() {
            this.set('queueLoading', true);
        },
        stopQueue: function stopQueue() {
            this.set('queueLoading', false);
        }
    });
});