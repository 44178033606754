define("frontend/routes/login", ["exports", "frontend/mixins/routable-body-class"], function (exports, _routableBodyClass) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_routableBodyClass.default, {
        flashMessages: Ember.inject.service(),
        i18n: Ember.inject.service(),

        bodyClass: 'guest_wrapper'
    });
});