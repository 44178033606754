define('frontend/routes/inner-app/template-groups/templates/create', ['exports', 'frontend/mixins/unsaved-data', 'frontend/mixins/unsaved-data-transition-block', 'frontend/config/environment'], function (exports, _unsavedData, _unsavedDataTransitionBlock, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_unsavedData.default, _unsavedDataTransitionBlock.default, {
        selectedItems: service(),
        specialItems: service(),
        notifications: service(),

        modelRoute: 'inner-app.template-groups.templates',
        modelName: 'template',
        proceedToSaveAction: 'createdHandler',

        model: function model(modelParams) {
            var params = this.paramsFor(this.get('modelRoute'));
            var templatesModel = this.modelFor(this.get('modelRoute'));

            if (this.shouldDoGeneralQuery(params.templateGroupId)) {
                var template = this.get('store').createRecord('template', { type: '' + modelParams.templateType });
                templatesModel.unshiftObject(template);
                return template;
            }

            return this.get('store').find('template-group', params.templateGroupId).then(function (group) {
                var template = group.get('templates').createRecord({ type: '' + modelParams.templateType });
                templatesModel.unshiftObject(template);
                return template;
            });
        },
        beforeModel: function beforeModel() {
            var params = this.paramsFor(this.routeName);
            if (params.templateType === 'voice' && !_environment.default.showVoice) {
                this.transitionTo('inner-app.template-groups.templates');
            }

            this._super.apply(this, arguments);
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.set('controller.focusedFirstFormField', false);
        },


        shouldDoGeneralQuery: function shouldDoGeneralQuery(groupId) {
            return groupId === 'all' || groupId === 'ungrouped';
        },

        handleSaveEvent: function handleSaveEvent(params, template) {
            var specialItems = this.get('specialItems');

            this.controllerFor('inner-app.template-groups.templates').checkAndRemoveFromList(template);

            specialItems.setItemGroups('template', template.get('id'), template.get('templateGroups').mapBy('id'));

            this.get('notifications').showSuccess(this.get('i18n').t('notifications.success-create-templates'));
        },


        actions: {
            createdHandler: function createdHandler() {
                var params = this.paramsFor(this.get('modelRoute'));
                var template = this.modelFor(this.routeName);
                this.handleSaveEvent(params, template);
                this.transitionTo('inner-app.template-groups.templates.edit', params.templateGroupId, template.get('id'));
            }
        }

    });
});