define('frontend/authenticators/jwt-lang-aware-recaptcha', ['exports', 'ember-simple-auth-token/authenticators/jwt'], function (exports, _jwt) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = _jwt.default.extend({
        getAuthenticateData: function getAuthenticateData(credentials) {
            var _authentication;

            var authentication = (_authentication = {}, _defineProperty(_authentication, 'lang', credentials.lang), _defineProperty(_authentication, 'g_recaptcha_response', credentials.g_recaptcha_response), _defineProperty(_authentication, this.passwordField, credentials.password), _defineProperty(_authentication, this.identificationField, credentials.identification), _authentication);
            return authentication;
        }
    });
});