define("frontend/routes/register", ["exports", "frontend/mixins/routable-body-class"], function (exports, _routableBodyClass) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routableBodyClass.default, {
    bodyClass: 'guest_wrapper',

    beforeModel: function beforeModel() /* transition */{
      this.transitionTo('login');
    }
  });
});