define("frontend/routes/inner-app/events/contact-groups", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        templateName: 'inner-app/events/contact-groups',
        selectedItems: Ember.inject.service(),
        specialItems: Ember.inject.service(),
        queue: Ember.inject.service(),
        queryParams: {
            searchEventGroup: {
                refreshModel: true
            }
        },

        model: function model(params) {
            var _this = this;

            var specialItems = this.get('specialItems');
            return this.get('queue').add(function () {
                return specialItems.initialize('contact').then(function () {
                    return _this.loadModel(params);
                });
            }, 'contact');
        },
        loadModel: function loadModel(params) {
            var specialItems = this.get('specialItems');
            var campaign = this.modelFor('inner-app.events');
            var searchEventGroup = params.searchEventGroup ? params.searchEventGroup : '';
            searchEventGroup = searchEventGroup.toString().replace(/\s+/g, ' ').trim();

            //start loading counters
            var loadCounters = specialItems.loadGroupCounters('contact');
            return this.get('store').query('contact-group', {
                reload: true,
                filter: { name: searchEventGroup }
            }).then(function (groups) {
                groups.set('campaign', campaign);

                groups.forEach(function (group) {
                    group.set('isChecked', false);
                });

                return loadCounters.then(function (counters) {
                    return specialItems.syncGroups('contact', counters).then(function () {
                        return groups.toArray();
                    });
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.initDebouncedQueryParam('searchEventGroup');
            controller.set('campaign', this.modelFor('inner-app.events'));
        },


        actions: {
            checkAllGroups: function checkAllGroups(checked) {
                var _this2 = this;

                var params = this.paramsFor(this.routeName);

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    var promises = [];
                    _this2.modelFor(_this2.routeName).forEach(function (group) {
                        group.set('isChecked', checked);
                        promises.push(specialItems.getIds('contact', group.get('id')).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        }));
                    });

                    if (!params.searchEventGroup) {
                        if (checked) {
                            promises.push(specialItems.getIds('contact', 'no-group').then(function (ids) {
                                selectedItems.addMany(ids);
                            }));
                            promises.push(specialItems.getIds('contact', 'google').then(function (ids) {
                                selectedItems.addMany(ids);
                            }));
                        } else {
                            promises.push(specialItems.getIds('contact', 'no-group').then(function (ids) {
                                selectedItems.removeMany(ids);
                            }));
                            promises.push(specialItems.getIds('contact', 'google').then(function (ids) {
                                selectedItems.removeMany(ids);
                            }));
                        }
                    }
                    return Ember.RSVP.all(promises);
                }, 'contact');
            },
            checkContactsForGroup: function checkContactsForGroup(group, checked) {
                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    if (!group) {
                        return false;
                    }
                    group.set('isChecked', checked);
                    if (specialItems.count('contact', group.get('id')) === 0) {
                        return false;
                    }

                    return specialItems.getIds('contact', group.get('id')).then(function (ids) {
                        if (checked) {
                            selectedItems.addMany(ids);
                        } else {
                            selectedItems.removeMany(ids);
                        }
                    });
                }, 'contact');
            }
        }
    });
});