define('frontend/mixins/debounce-query-params', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        initDebouncedQueryParams: function initDebouncedQueryParams(params) {
            var _this = this;

            params.forEach(function (param) {
                _this.initDebouncedQueryParam(param);
            });
        },
        initDebouncedQueryParam: function initDebouncedQueryParam(param) {
            if (this.getWithDefault('display_' + param, '') === '') {
                this.set('display_' + param, this.get(param));
            }
            if (this.get(param) === undefined) {
                this.set('display_' + param, '');
            }
            if (!this.get('set_' + param)) {
                this.set('set_' + param, function (value) {
                    this.set(param, value);
                });
            }
        },
        debounceSetQueryParam: function debounceSetQueryParam(param, value) {
            this.set('display_' + param, value);
            //sanitize value
            value = value.replace(/\s+/g, ' ').trim();
            Ember.run.debounce(this, this.get('set_' + param), value, 300);
        },

        actions: {
            debounceSetQueryParam: function debounceSetQueryParam(param, value) {
                this.debounceSetQueryParam(param, value);
            }
        }
    });
});