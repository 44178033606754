define('frontend/components/credits/plan-row', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_authenticatedRouteMixin.default, {
        modal: Ember.inject.service(),
        isActive: Ember.computed('currentPlanId', function () {
            return this.get('currentPlanId') === this.get('plan.id');
        }),
        isExpanded: Ember.computed('expandedPlan', function () {
            return this.get('expandedPlan') === this.get('plan.id');
        }),
        hideDetails: function hideDetails() {
            this.sendAction('setExpandedPlan', null);
        },
        showDetails: function showDetails() {
            this.sendAction('setExpandedPlan', this.get('plan.id'));
        },

        actions: {
            toggleDetails: function toggleDetails() {
                if (this.get('isExpanded')) {
                    this.hideDetails();
                } else {
                    this.showDetails();
                }
            },
            confirmChangePlan: function confirmChangePlan(planId) {
                var _this = this;

                this.get('modal').confirm({
                    template: 'dialogs/messages/change-plan',
                    title: this.get('i18n').t('settings.credits.change-plan'),
                    credits: this.get('user.data.currentCompany.creditsCurrent')
                }).then(function () {
                    _this.sendAction('confirmChangePlan', planId);
                });
            }
        }
    });
});