define("frontend/components/contacts/contact-form", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        queue: service(),
        contact: null, // passed in
        focusedFirstField: false,
        disableFocus: false, //passed in

        save: function save(groups) {
            var _this = this;

            var lastFocusedItem = this.$('input:focus');
            var lastFocusedItemSelector = lastFocusedItem ? lastFocusedItem.attr('name') : null;
            var contact = this.get('contact');

            // In some cases the action is executed after the route has been destroyed and the contact becomes null
            if (!contact) {
                return false;
            }

            var hasDirtyAttr = contact.get('hasDirtyAttributes');
            var hasChangedGroups = groups !== undefined;

            if (!this.get('contact.isSaving') && (hasDirtyAttr || hasChangedGroups)) {
                return contact.validate().then(function (_ref) {
                    var validations = _ref.validations;

                    if (validations.get('isValid')) {
                        _this.get('queue').add(function () {
                            // Custom field values error handling happens here with  custom properties,
                            // because ember-data, ember-data-model-fragments and ember-cp-validations
                            // don't play well at the moment
                            // If this goes to production we can refactor it a bit
                            return contact.save().then(function () {
                                contact.get('customFieldValues').map(function (field) {
                                    return field.set('validationError', null);
                                });
                                // Send the selector for the last focused input to the handler
                                // when creating a new contact it is used to preserve the focus
                                _this.get('action')('input[name="' + lastFocusedItemSelector + '"]');
                            }).catch(function (response) {
                                if (response.errors) {
                                    Ember.A(response.errors).forEach(function (error) {
                                        if (error.source.pointer.indexOf('custom-field-values') !== -1) {
                                            var fieldPosition = error.source.pointer.split('/')[3];
                                            // Switched error from title to detail
                                            // because currently all the validation these fields have is for unique field in contacts
                                            contact.get('customFieldValues').toArray()[fieldPosition].set('validationError', error.detail);
                                        }
                                    });
                                }
                            });
                        }, 'contact');
                    }
                });
            }
        },


        actions: {
            debouncedSave: function debouncedSave() {
                Ember.run.debounce(this, 'save', 500);
            },
            saveContact: function saveContact() {
                this.set('disableFocus', false);
                this.save();
            },
            changedGroups: function changedGroups(groups) {
                this.get('contact').get('contactGroups').addObjects(groups);
                this.save(groups);
            }
        },

        didRender: function didRender() {
            this._super.apply(this, arguments);

            // Ugly but working
            // Last focused input is a selector bound as attribute to the component
            // The save method sends it as parameter for the action
            // The handler then saves it on the contacts.edit controller
            // which sends it back after the transition
            // to the user it looks like no transition occurred and last focused field is still focused
            var selector = this.get('lastFocusedInput') ? this.get('lastFocusedInput') : 'li:first input';

            if (!this.get('focusedFirstField')) {
                if (!this.get('disableFocus')) {
                    this.$(selector).focus();
                }
                this.set('focusedFirstField', true);
            }
        }
    });
});