define('frontend/routes/inner-app/not-accepted-terms', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/allowed-urls'], function (exports, _authenticatedRouteMixin, _allowedUrls) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _allowedUrls.default, {

        user: service(),
        modal: service(),
        ajax: service(),
        session: service(),
        router: service(),

        deleteAccount: function deleteAccount() {
            var _this = this;

            this.get('ajax').delete('users/me').then(function () {
                _this.get('modal').alert({
                    template: 'dialogs/messages/error',
                    message: _this.i18n.t('settings.security.delete-success')
                }).finally(function () {
                    _this.set('isUserDeleted', true);
                    _this.get('modal').destroyAllModals();
                    _this.get('session').invalidate();
                    _this.get('router').transitionTo('login');
                });
            });
        },


        actions: {
            confirmDeleteAccount: function confirmDeleteAccount() {
                var _this2 = this;

                this.get('modal').confirm({
                    template: 'dialogs/messages/delete-account',
                    title: this.get('i18n').t('settings.security.delete-account-title')
                }).then(function () {
                    _this2.deleteAccount();
                });
            }
        }
    });
});