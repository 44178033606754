define('frontend/routes/inner-app/import-google', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            this.controllerFor(this.routeName).resetState();
        },

        actions: {
            didTransition: function didTransition() {
                Ember.run.later(this, function () {
                    if (!this.controllerFor(this.routeName).get('startedGoogleLogin')) {
                        this.transitionTo('/import');
                    }
                }, 500);
            }
        }
    });
});