define('frontend/helpers/slugify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.slugify = slugify;
  function slugify(params) {
    var string = params[0];
    string = string.toLowerCase();
    string = string.replace(/\s+/g, '-');
    return string;
  }

  exports.default = Ember.Helper.helper(slugify);
});