define('frontend/components/layout/main-header', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        headerLoadingIndicator: Ember.inject.service(),

        loadingIndicator: Ember.computed('headerLoadingIndicator.storeLoading', 'headerLoadingIndicator.ajaxLoading', 'headerLoadingIndicator.serviceLoading', 'headerLoadingIndicator.queueLoading', function () {
            if (this.get('headerLoadingIndicator.storeLoading') || this.get('headerLoadingIndicator.ajaxLoading') || this.get('headerLoadingIndicator.serviceLoading') || this.get('headerLoadingIndicator.queueLoading')) {
                return 'loading';
            }
            return '';
        }),
        actions: {
            logout: function logout() {
                this.sendAction('logout');
            },

            toggleHelp: function toggleHelp() {
                this.sendAction('toggleHelp');
            },
            hideHelp: function hideHelp() {
                this.sendAction('hideHelp');
            },
            openPopUpSettings: function openPopUpSettings(template, dropdown) {
                this.sendAction('openPopUpSettings', template);
                dropdown.actions.close();
            }
        }
    });
});