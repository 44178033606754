define('frontend/services/special-items', ['exports', 'frontend/lib/pset'], function (exports, _pset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        ajax: service(),
        headerLoadingIndicator: service(),
        queue: service(),

        items: {},
        type: '',
        search: '',
        updated: 0,
        templateAllowUpdate: true,
        contactAllowUpdate: true,
        groupsRecentlyEmpty: {},
        chunkSize: 100, //initial size
        chunkTime: 2, //2ms - ok, >5ms slower
        isInitialized: {
            contact: false,
            template: false
        },
        searchInProggress: null,
        reloadGoogle: true, //flag set by inports to reload google group from backend

        /**
         * Load all groups of given type, if not loaded
         * @param type
         * @returns {boolean}
         */
        initialize: function initialize(type) {
            var _this = this;

            if (this.get('isInitialized.' + type) === false) {
                this._disableUpdate(type);

                return this._initialize(type).then(function () {
                    _this._enableUpdate(type);
                    _this._updateService(type);
                });
            }
            return this.get('isInitialized.' + type);
        },


        /**
         * Wrapper for _syncGroups. Fetches groupCounters if not passed. Updates the service.
         * @param type
         * @param groupCounters
         * @returns {boolean}
         */
        syncGroups: function syncGroups(type) {
            var _this2 = this;

            var groupCounters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            this._disableUpdate(type);
            //if no array passed - get counters from backend
            if (groupCounters === false) {
                return this.loadGroupCounters(type).then(function (counters) {
                    return _this2._syncGroups(type, counters).then(function () {
                        _this2._enableUpdate(type);
                        _this2._updateService(type);
                    });
                });
            }
            return this._syncGroups(type, groupCounters).then(function () {
                _this2._enableUpdate(type);
                _this2._updateService(type);
            });
        },


        /**
         * Load groups counters from backend
         * @param type
         * @returns {*}
         */
        loadGroupCounters: function loadGroupCounters(type) {
            var groupCounters = [];
            var url = type + '-groups/ids';
            return this.get('ajax').request(url, {
                method: 'GET',
                data: {
                    filter: {
                        count: true
                    }
                }
            }).then(function (result) {
                var groups = Object.entries(result.groups);
                if (groups.length) {
                    groups.forEach(function (_ref) {
                        var _ref2 = _slicedToArray(_ref, 2),
                            groupName = _ref2[0],
                            itemsCount = _ref2[1];

                        groupCounters[groupName.toString()] = itemsCount;
                    });
                }

                return groupCounters;
            });
        },


        /**
         * Public method for _initGroup
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {*}
         */
        createGroup: function createGroup(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            return this._initGroup(type, groupName, searchFlag);
        },


        /**
         * Return group items count
         * @param type
         * @param groupName
         * @param searchFlag
         */
        count: function count(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            if (!this._issetGroup(type, groupName, searchFlag)) {
                return 0;
            }
            return this._getGroup(type, groupName, searchFlag).size;
        },


        /**
         * Remove many items from all groups
         * @param type
         * @param ids
         */
        removeManyFromAllGroups: function removeManyFromAllGroups(type, ids) {
            var _this3 = this;

            //call the chunked method and pass it the promise resolve function to execute when finished
            this._disableUpdate(type);
            return new Ember.RSVP.Promise(function (resolve) {
                this._removeManyFromAllGroupsQueue(type, ids, resolve);
            }.bind(this)).then(function () {
                _this3._enableUpdate(type);
                _this3._updateService(type);
            });
        },


        /**
         * Remove item from all groups
         * @param type
         * @param id
         * @param searchFlag
         */
        removeFromAllGroups: function removeFromAllGroups(type, id) {
            var _this4 = this;

            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            this.getItemGroups(type, id).forEach(function (groupName) {
                return _this4._remove(type, groupName, id, searchFlag);
            });
            this._updateService(type);
            return true;
        },


        /**
         * Add item to specified groups and remove it from the rest
         * @param type
         * @param itemId
         * @param groupIds
         * @param searchFlag
         */
        setItemGroups: function setItemGroups(type, itemId, groupIds) {
            var _this5 = this;

            var searchFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            //for contacts - if item is in google group - add 'google' to groupIds
            if (this._issetGroup(type, 'google', searchFlag) && this._getGroup(type, 'google', searchFlag).has(itemId)) {
                groupIds.push('google');
            }

            //if groupIds is empty - add 'no-group'
            if (groupIds.length === 0) {
                groupIds.push('no-group');
            }

            this._disableUpdate(type);
            this._groupNames(type).forEach(function (group) {
                if (groupIds.includes(group)) {
                    //add item to groups from groupIds array
                    _this5._add(type, group, itemId, searchFlag);
                } else {
                    //remove it from all other groups
                    _this5._remove(type, group, itemId, searchFlag);
                }
            });
            this._enableUpdate(type);
            this._updateService(type);
            return true;
        },


        /**
         * Get group ids as a promise
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {*}
         */
        getIds: function getIds(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            if (this.get('search') === '') {
                searchFlag = 0;
            }

            //if the group is a promise - return it
            if (this._isGroupPromise(type, groupName, searchFlag)) {
                return this._getGroup(type, groupName, searchFlag);
            }

            //return group ids as a promise
            return new Ember.RSVP.Promise(function (resolve) {
                resolve(this.getIdsArray(type, groupName, searchFlag));
            }.bind(this));
        },


        /**
         * Get group items as array
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {*}
         */
        getIdsArray: function getIdsArray(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            //if group is a promise or doesnt exist - return an empty array
            if (!this._issetGroup(type, groupName, searchFlag) || this._isGroupPromise(type, groupName, searchFlag)) {
                return [];
            }

            //return group items
            return this._getGroup(type, groupName, searchFlag).items();
        },


        /**
         * Get item groups as array
         * @param type
         * @param id
         * @param searchFlag
         * @returns {boolean}
         */
        getItemGroups: function getItemGroups(type, id) {
            var _this6 = this;

            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            return this._groupNames(type, searchFlag).filter(function (group) {
                if (_this6._getGroup(type, group, searchFlag).has(id)) {
                    return true;
                }
                return false;
            });
        },


        /**
         * Return true if groupName is in recently empty groups array and remove the group from the array
         * @param type
         * @param groupName
         * @returns {*}
         */
        recentlyEmptyGroup: function recentlyEmptyGroup(type, groupName) {
            if (this._getRecentlyEmptyGroupArray(type).has(groupName)) {
                return this._removeRecentlyEmptyGroup(type, groupName);
            }
            return false;
        },


        /**
         * Clear and reload the search results
         * @param type
         * @param params
         * @returns {boolean}
         */
        reloadSearch: function reloadSearch(type, params) {
            var _this7 = this;

            //clear and reload search
            return this.loadSearch(type, { search: '' }).then(function () {
                return _this7.loadSearch(type, params);
            });
        },


        /**
         * Start  loading search if needed, waits for previous search
         * @param type
         * @param params
         * @returns {*}
         */
        loadSearch: function loadSearch(type, params) {
            var _this8 = this;

            if (!this.get('searchInProggress')) {
                this.set('searchInProggress', new Ember.RSVP.Promise(function (resolve) {
                    resolve(true);
                }));
            }
            return this.get('searchInProggress').then(function () {
                var newSearchPromise = _this8._setSearch(type, params.search).then(function (result) {
                    //if search string is empty
                    if (_this8.get('search') === '') {
                        //and we just cleared it
                        if (result) {
                            //return true to flush selected items
                            return true;
                        }
                        return false;
                    }

                    //prepare groupId param
                    var groupId = params.groupId !== undefined ? params.groupId : params.templateGroupId;
                    if (groupId === 'ungrouped') {
                        groupId = 'no-group';
                    }

                    _this8._disableUpdate(type);
                    var loadSearchPromise = _this8._loadSearch(type, groupId, params.search).then(function () {
                        _this8._enableUpdate(type);
                        _this8._updateService(type);
                    });

                    //if searching in all groups
                    if (groupId === 'all') {
                        _this8._groupNames(type).forEach(function (groupId) {
                            //set all groups promises
                            _this8._setSearchGroupPromise(type, groupId, loadSearchPromise);
                        });
                    } else {
                        _this8._setSearchGroupPromise(type, groupId, loadSearchPromise);
                    }
                    return true;
                });
                _this8.set('searchInProggress', newSearchPromise);
                return newSearchPromise;
            });
        },


        // "PRIVATE" methods
        /**
         * Calculate new chunk size, based on execution time
         * @param t0
         * @param t1
         */
        _optimize: function _optimize(t0, t1) {
            var chunkSizeAdjust = this.get('chunkTime') / (t1 - t0);
            //da ne pokachva broq s tvurde mnogo navednuj
            chunkSizeAdjust = chunkSizeAdjust > 1.3 ? 1.3 : chunkSizeAdjust;
            var newChunkSize = Math.ceil(this.get('chunkSize') * chunkSizeAdjust);
            //da ne padame pod 50
            newChunkSize = newChunkSize < 50 ? 50 : newChunkSize;
            this.set('chunkSize', newChunkSize);
        },


        /**
         *
         * @param type
         * @returns {*}
         * @private
         */
        _initialize: function _initialize(type) {
            var initializedItems = this._loadAllGroups(type);
            this.set('isInitialized.' + type, initializedItems);
            return initializedItems;
        },


        /**
         * Get all groups IDs from server, processing is done with queue
         * @param type
         * @returns {boolean}
         * @private
         * return true if at least one group was loaded
         */
        _loadAllGroups: function _loadAllGroups(type) {
            var _this9 = this;

            var url = type + '-groups/ids';

            return this.get('ajax').request(url, {
                method: 'GET'
            }).then(function (result) {
                var groups = Object.entries(result.groups);
                if (groups.length) {
                    var allIds = [];

                    groups.forEach(function (_ref3) {
                        var _ref4 = _slicedToArray(_ref3, 2),
                            groupName = _ref4[0],
                            itemsArray = _ref4[1];

                        var groupIds = itemsArray.map(function (e) {
                            return e.toString();
                        });

                        //add groups ids
                        _this9.get('queue').add(function () {
                            _this9._initGroup(type, groupName.toString(), 0, groupIds);
                        }, type + 'InitGroup');

                        //combine all groups ids into one array
                        _this9.get('queue').add(function () {
                            allIds = allIds.concat(groupIds);
                        }, type + 'InitGroup');
                    });

                    //and init 'all' group
                    return _this9.get('queue').add(function () {
                        return _this9._initAll(type, 0, allIds);
                    }, type + 'InitGroup');
                }
                return false;
            });
        },


        /**
         * Load IDs for one group from server
         * @param type
         * @param groupName
         * @returns {boolean}
         */
        _loadGroup: function _loadGroup(type, groupName) {
            var _this10 = this;

            var url = type + '-groups/ids';
            return this.get('ajax').request(url, {
                method: 'GET',
                data: {
                    filter: {
                        group: groupName
                    }
                }
            }).then(function (result) {
                var groups = Object.entries(result.groups);
                if (groups.length) {
                    groups.forEach(function (_ref5) {
                        var _ref6 = _slicedToArray(_ref5, 2),
                            groupName = _ref6[0],
                            itemsArray = _ref6[1];

                        _this10._initGroup(type, groupName.toString(), 0, itemsArray.map(function (e) {
                            return e.toString();
                        }));
                    });

                    return true;
                }
                return false;
            });
        },


        /**
         * Matches passed pairs(groupName, counter) and if there is a new group or changed counter - reloads the group
         * @param type
         * @param groupCounters
         * @returns {boolean}
         */
        _syncGroups: function _syncGroups(type, groupCounters) {
            var _this11 = this;

            var updateGroupPromise = new Ember.RSVP.Promise(function (resolve) {
                resolve(true);
            });
            var needsUpdate = false;

            //compare service groups with passed groups
            this._groupNames(type).forEach(function (group) {
                //if service group exists in passed array - sync it
                if (groupCounters.hasOwnProperty(group)) {
                    updateGroupPromise = _this11.get('queue').add(function () {
                        return _this11._syncGroup(type, group, groupCounters[group]).then(function (result) {
                            needsUpdate = needsUpdate || result;
                        });
                    }, type + 'SyncGroups');
                } else {
                    //remove group if not passed
                    updateGroupPromise = _this11.get('queue').add(function () {
                        _this11._destroyGroup(type, group);
                        needsUpdate = true;
                        return new Ember.RSVP.Promise(function (resolve) {
                            resolve(true);
                        });
                    }, type + 'SyncGroups');
                }
            });

            //look for a new group
            return updateGroupPromise.then(function () {
                updateGroupPromise = new Ember.RSVP.Promise(function (resolve) {
                    resolve(true);
                });

                groupCounters.forEach(function (groupCount, groupName) {
                    //new group found
                    if (!_this11._groupNames(type).hasOwnProperty(groupName)) {
                        updateGroupPromise = _this11.get('queue').add(function () {
                            return _this11._syncGroup(type, groupName, groupCount).then(function (result) {
                                needsUpdate = needsUpdate || result;
                            });
                        }, type + 'SyncGroups');
                    }
                });

                //when (if) new groups are loaded
                return updateGroupPromise.then(function () {
                    //rebuild all if needed
                    if (needsUpdate) {
                        return _this11.get('queue').add(function () {
                            return _this11._initAll(type);
                        }, type + 'SyncGroups');
                    }

                    return new Ember.RSVP.Promise(function (resolve) {
                        resolve(true);
                    });
                });
            });
        },


        /**
         * If there is no such group or counter for existing group is different - reloads the group
         * @param type
         * @param groupName
         * @param count
         * @returns {*}
         * @private
         */
        _syncGroup: function _syncGroup(type, groupName, count) {
            if (!this._issetGroup(type, groupName) || this.count(type, groupName) !== count) {
                return this._loadGroup(type, groupName);
            }
            return new Ember.RSVP.Promise(function (resolve) {
                resolve(false);
            });
        },


        /**
         * Return variable name for given group. type and search flag
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {string}
         * @private
         */
        _groupVar: function _groupVar(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            var search = searchFlag ? 'search_' : '';
            return 'items.' + type + '_' + search + groupName;
        },


        /**
         * Check if group exists in the service
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {boolean}
         * @private
         */
        _issetGroup: function _issetGroup(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            if (this.get(this._groupVar(type, groupName, searchFlag)) === undefined) {
                return false;
            }
            return true;
        },


        /**
         * Return true if group is a promise
         * @param type
         * @param groupName
         * @param searchFlag
         * @returns {boolean}
         */
        _isGroupPromise: function _isGroupPromise(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            if (!this._issetGroup(type, groupName, searchFlag)) {
                return false;
            }
            if (typeof this._getGroup(type, groupName, searchFlag).then === 'function') {
                return true;
            }
            return false;
        },


        /**
         * Set group promise
         * @param type
         * @param groupName
         * @param promise
         * @param searchFlag
         */
        _setGroupPromise: function _setGroupPromise(type, groupName, promise) {
            var searchFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            this.set(this._groupVar(type, groupName, searchFlag), promise);
        },


        /**
         * Returns group variable. Creates one if it doesn't exist.
         * @param type
         * @param groupName
         * @param searchFlag
         * @private
         */
        _getGroup: function _getGroup(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            return this.get(this._groupVar(type, groupName, searchFlag));
        },


        /**
         * Delete group variable from service (items object)
         * @param type
         * @param groupName
         * @param searchFlag
         * @private
         */
        _destroyGroup: function _destroyGroup(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            var search = searchFlag ? 'search_' : '';
            delete this.get('items')[type + '_' + search + groupName];
            return true;
        },


        /**
         * Delete (if exists) the group and create it again. Fill it with initArray if provided. Return the group.
         * @param type
         * @param groupName
         * @param searchFlag
         * @param initArray
         */
        _initGroup: function _initGroup(type, groupName) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
            var initArray = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

            if (this._issetGroup(type, groupName, searchFlag)) {
                this._destroyGroup(type, groupName, searchFlag);
            }
            this.set(this._groupVar(type, groupName, searchFlag), new _pset.default(initArray));
            return this.get(this._groupVar(type, groupName, searchFlag));
        },


        /**
         * Return true if group is 'google' or 'no-group'
         * @param groupName
         * @returns {boolean}
         * @private
         */
        _isSpecialGroup: function _isSpecialGroup(groupName) {
            if (groupName === 'google' || groupName === 'no-group') {
                return true;
            }
            return false;
        },


        /**
         * Return an array of all available group names (regular or search without 'all' group)
         * @param type
         * @param searchFlag
         * @returns {*}
         * @private
         */
        _groupNames: function _groupNames(type) {
            var searchFlag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

            var groupIds = new _pset.default();

            if (Object.keys(this.get('items')).length === 0) {
                return [];
            }
            Object.keys(this.get('items')).forEach(function (key) {
                if (key.substr(0, type.length) === type) {
                    if (searchFlag) {
                        if (key.substr(type.length, 8) === '_search_') {
                            groupIds.add(key.substr(type.length + 8));
                        }
                    } else {
                        if (key.substr(type.length, 8) !== '_search_') {
                            groupIds.add(key.substr(type.length + 1));
                        }
                    }
                }
            });

            groupIds.delete('all');
            return groupIds.items();
        },


        /**
         * Add single item to a group, add it to all group, update recently empty groups
         * @param type
         * @param groupName
         * @param id
         * @param searchFlag
         * @private
         */
        _add: function _add(type, groupName, id) {
            var searchFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            if (!this._getGroup(type, 'all', searchFlag).has(id)) {
                this._getGroup(type, 'all', searchFlag).add(id);
            }

            if (groupName !== 'all') {
                this._getGroup(type, groupName, searchFlag).add(id);
            }

            if (groupName !== 'all' && !this._isSpecialGroup(groupName) && this.count(type, groupName) !== 0) {
                this._removeRecentlyEmptyGroup(type, groupName);
            }
        },


        /**
         * Remove many items from group. Method calculates execution time and changes the chunk number before adding itself to the queue
         * for the next batch of items. When it finishes, it calls the passed resolve method.
         * @param type
         * @param groupName
         * @param ids
         * @param searchFlag
         * @param resolveEnd
         * @param index
         * @returns {boolean}
         * @private
         */
        _removeManyQueue: function _removeManyQueue(type, groupName, ids, searchFlag, resolveEnd) {
            var _this12 = this;

            var index = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;

            var chunkSize = this.get('chunkSize');
            var toProcess = ids.slice(index, index + chunkSize);

            if (toProcess.length === 0) {
                resolveEnd(true);
                return true;
            }

            var t0 = performance.now();
            toProcess.forEach(function (id) {
                _this12._remove(type, groupName, id, searchFlag);
            });
            var t1 = performance.now();
            this._optimize(t0, t1);

            this.get('queue').add(function () {
                _this12._removeManyQueue(type, groupName, ids, searchFlag, resolveEnd, index + chunkSize);
            }, 'removeMany');

            return true;
        },


        /**
         * Remove many items from a group
         * @param type
         * @param groupName
         * @param ids
         * @param searchFlag
         */
        _removeMany: function _removeMany(type, groupName, ids) {
            var searchFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            //call the chunked method and pass it the promise resolve function to execute when finished
            return new Ember.RSVP.Promise(function (resolve) {
                this._removeManyQueue(type, groupName, ids, searchFlag, resolve);
            }.bind(this));
        },


        /**
         * Remove single item to a group, remove it to all group(if necessary), update recently empty groups
         * @param type
         * @param groupName
         * @param id
         * @param searchFlag
         */
        _remove: function _remove(type, groupName, id) {
            var searchFlag = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            if (this._issetGroup(type, groupName, searchFlag)) {
                this._getGroup(type, groupName, searchFlag).delete(id);
            }

            if (!searchFlag) {
                if (this._issetGroup(type, groupName, 1)) {
                    this._getGroup(type, groupName, 1).delete(id);
                }
            }

            if (!this._hasId(type, id, searchFlag)) {
                if (this._issetGroup(type, 'all', searchFlag)) {
                    this._getGroup(type, 'all', searchFlag).delete(id);
                }
                if (!searchFlag && !this._hasId(type, id, 1)) {
                    if (this._issetGroup(type, 'all', 1)) {
                        this._getGroup(type, 'all', 1).delete(id);
                    }
                }
            }
            if (groupName !== 'all' && !this._isSpecialGroup(groupName) && this.count(type, groupName) === 0) {
                this._addRecentlyEmptyGroup(type, groupName);
            }
        },


        /**
         * Remove many items from all groups. Method calculates execution time and changes the chunk number before adding itself to the queue
         * for the next batch of items. When it finishes, it calls the passed resolve method.
         * @param type
         * @param ids
         * @param resolveEnd
         * @param index
         * @returns {boolean}
         */
        _removeManyFromAllGroupsQueue: function _removeManyFromAllGroupsQueue(type, ids, resolveEnd) {
            var _this13 = this;

            var index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

            var chunkSize = this.get('chunkSize');
            var toProcess = ids.slice(index, index + chunkSize);

            if (toProcess.length === 0) {
                resolveEnd(true);
                return true;
            }

            toProcess.forEach(function (id) {
                _this13.removeFromAllGroups(type, id);
            });

            this.get('queue').add(function () {
                _this13._removeManyFromAllGroupsQueue(type, ids, resolveEnd, index + chunkSize);
            }, 'removeManyFromAllGroups');

            return true;
        },


        /**
         * Return true if id is found in any of the groups (without 'all')
         * @param type
         * @param id
         * @param searchFlag
         * @returns {*|Boolean|boolean}
         * @private
         */
        _hasId: function _hasId(type, id) {
            var searchFlag = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

            return this._groupNames(type, searchFlag).any(function (group) {
                if (!this._isGroupPromise(type, group, searchFlag) && this._getGroup(type, group, searchFlag).has(id)) {
                    return true;
                }
                return false;
            }, this);
        },


        /**
         * Get the array for recently removed groups
         * @param type
         * @private
         */
        _getRecentlyEmptyGroupArray: function _getRecentlyEmptyGroupArray(type) {
            if (this.get('groupsRecentlyEmpty.' + type) === undefined) {
                this.set('groupsRecentlyEmpty.' + type, new _pset.default());
            }
            return this.get('groupsRecentlyEmpty.' + type);
        },


        /**
         * Add a group to the recently removed groups array
         * @param type
         * @param groupName
         * @private
         */
        _addRecentlyEmptyGroup: function _addRecentlyEmptyGroup(type, groupName) {
            this._getRecentlyEmptyGroupArray(type).add(groupName);
        },


        /**
         * Remove a group from the recently removed groups array
         * @param type
         * @param groupName
         * @returns {boolean}
         * @private
         */
        _removeRecentlyEmptyGroup: function _removeRecentlyEmptyGroup(type, groupName) {
            this._getRecentlyEmptyGroupArray(type).delete(groupName);
            return true;
        },


        /**
         * Rebuilds/sets the 'all' group contents
         * @param type
         * @param searchFlag
         * @param initArray
         * @returns {*}
         * @private
         */
        _initAll: function _initAll(type) {
            var _this14 = this;

            var searchFlag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
            var initArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            if (initArray === false) {
                var allIds = [];
                var loadIds = void 0;

                //combine all groups ids into one array
                this._groupNames(type, searchFlag).forEach(function (group) {
                    loadIds = _this14.get('queue').add(function () {
                        allIds = allIds.concat(_this14.getIdsArray(type, group, searchFlag));
                        return true;
                    }, type + 'InitAll');
                });

                //and init the all group
                return loadIds.then(function () {
                    return _this14._initGroup(type, 'all', searchFlag, allIds);
                });
            } else {
                return this._initGroup(type, 'all', searchFlag, initArray);
            }
        },


        /**
         * Set sanitized search string to service.
         * If search is updated - delete all search groups
         * Returns promise:
         * If search is empty - false, otherwise - true
         * @param type
         * @param search
         * @returns {boolean}
         */
        _setSearch: function _setSearch(type, search) {
            var _this15 = this;

            search = search === undefined ? '' : search;
            search = search.replace(/\s+/g, ' ').trim();

            var result = false;
            if (this.get('search') !== search) {
                this.set('search', search);
                //delete previous search results
                this._destroyGroup(type, 'all', 1);
                this._groupNames(type, 1).forEach(function (group) {
                    _this15._destroyGroup(type, group, 1);
                });

                //if not empti search string - return true
                //if (search !== '') {
                result = true;
                //}
            }
            return new Ember.RSVP.Promise(function (resolve) {
                resolve(result);
            });
        },


        /**
         * Load One or all groups search results
         * @param type
         * @param groupName
         * @param search
         * @returns {boolean}
         * @private
         */
        _loadSearch: function _loadSearch(type, groupName, search) {
            var _this16 = this;

            var url = type + '-groups/ids';
            return this.get('ajax').request(url, {
                method: 'GET',
                data: {
                    filter: {
                        group: groupName,
                        search: search
                    }
                }
            }).then(function (result) {
                var groups = Object.entries(result.groups);
                if (groups.length) {
                    var allIds = [];
                    groups.forEach(function (_ref7) {
                        var _ref8 = _slicedToArray(_ref7, 2),
                            groupName = _ref8[0],
                            itemsArray = _ref8[1];

                        var groupIds = itemsArray.map(function (e) {
                            return e.toString();
                        });

                        //add groups ids
                        _this16.get('queue').add(function () {
                            _this16._initGroup(type, groupName.toString(), 1, groupIds);
                        }, type + 'InitSearchGroup');

                        //combine all groups ids into one array
                        _this16.get('queue').add(function () {
                            allIds = allIds.concat(groupIds);
                        }, type + 'InitSearchGroup');
                    });

                    //and init 'all' group
                    return _this16.get('queue').add(function () {
                        return _this16._initAll(type, 1, allIds);
                    }, type + 'InitSearchGroup');
                }
                return true;
            });
        },


        /**
         * Set search group promise to that of _loadSearch method
         * After it is resolved - return group items
         * @param type
         * @param groupName
         * @param loadSearchPromise
         * @returns {*}
         * @private
         */
        _setSearchGroupPromise: function _setSearchGroupPromise(type, groupName, loadSearchPromise) {
            var _this17 = this;

            var groupResolve = loadSearchPromise.then(function () {
                return _this17.getIdsArray(type, groupName, 1);
            });

            var groupPromise = new Ember.RSVP.Promise(function (resolve) {
                resolve(groupResolve);
            });

            this._setGroupPromise(type, groupName, groupPromise, 1);
        },


        /**
         * Disable toggle of 'updated' property
         * @param type
         * @private
         */
        _disableUpdate: function _disableUpdate(type) {
            this.set(type + 'AllowUpdate', false);
            this.get('headerLoadingIndicator').startService();
        },


        /**
         * Enable toggle of 'updated' property
         * @param type
         * @private
         */
        _enableUpdate: function _enableUpdate(type) {
            this.set(type + 'AllowUpdate', true);
            if (this.get('contactAllowUpdate') && this.get('templateAllowUpdate')) {
                this.get('headerLoadingIndicator').stopService();
            }
        },


        /**
         * Check if update is enabled
         * @param type
         * @private
         */
        _updateEnabled: function _updateEnabled(type) {
            return this.get(type + 'AllowUpdate');
        },


        /**
         * Increment 'updated' property so computed properties everywhere can update (values are 0 - 1000)
         * @param type
         * @private
         */
        _updateService: function _updateService(type) {
            if (this._updateEnabled(type)) {
                this.incrementProperty('updated');
                if (this.get('updated') === 1000) {
                    this.set('updated', 0);
                }
            }
        }
    });
});