define('frontend/controllers/inner-app/api-reports/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Ember.Controller.extend({

    moment: service(),

    message: computed('model', function () {
      return this.get('i18n').t('reports.sent-sms');
    }),

    modelPeriod: computed('model', function () {
      return this.get('moment').moment(this.get('model.period'), 'M-YYYY');
    }),

    allSnapshots: computed.mapBy('model.delivery-statistic', 'countByStatus'),
    allSnapshotsCount: computed.sum('allSnapshots'),

    successfullySent: computed('model.delivery-statistic', function () {
      return this.get('model.delivery-statistic').filterBy('status', 'delivered_to_handset').get('firstObject.countByStatus');
    }),
    successfullySentProgress: computed('allSnapshotsCount', 'successfullySent', function () {
      var progress = this.get('successfullySent') / this.get('allSnapshotsCount');
      return progress.toFixed(2);
    }),
    unsuccessfullySent: computed('model.delivery-statistic', function () {
      return this.filterStatistic(['not_delivered_to_handset', 'not_accepted_from_smsc']);
    }),
    unsuccessfullySentProgress: computed('allSnapshotsCount', 'unsuccessfullySent', function () {
      var progress = this.get('unsuccessfullySent') / this.get('allSnapshotsCount');
      return progress.toFixed(2);
    }),
    inProcess: computed('model.delivery-statistic', function () {
      return this.filterStatistic(['sent_to_transport', 'queued_on_smsc', 'delivered_to_smsc']);
    }),
    inProcessProgress: computed('allSnapshotsCount', 'inProcess', function () {
      var progress = this.get('inProcess') / this.get('allSnapshotsCount');
      return progress.toFixed(2);
    }),
    failedStatus: computed('model.delivery-statistic', function () {
      return this.filterStatistic(['send_failed', 'transport_server_failed']);
    }),
    failedStatusProgress: computed('allSnapshotsCount', 'failedStatus', function () {
      var progress = this.get('failedStatus') / this.get('allSnapshotsCount');
      return progress.toFixed(2);
    }),

    getCount: function getCount(items) {
      var result = 0;
      if (items.length > 0) {
        items.forEach(function (item) {
          result += item.countByStatus;
        });
      }
      return result;
    },
    filterStatistic: function filterStatistic(statuses) {
      var items = this.get('model.delivery-statistic').filter(function (item) {
        return statuses.contains(item.status);
      });
      return this.getCount(items);
    }
  });
});