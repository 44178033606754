define('frontend/controllers/inner-app/template-groups/templates/edit', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        proceedToSave: false,

        templatesController: Ember.inject.controller('inner-app.template-groups.templates'),
        disableFocus: Ember.computed.alias('templatesController.disableFocus')
    });
});