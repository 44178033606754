define('frontend/models/sms-snapshot', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr'], function (exports, _model, _relationships, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({

        phoneNumber: (0, _attr.default)(),
        status: (0, _attr.default)(),
        text: (0, _attr.default)(),
        contactId: (0, _attr.default)(),
        totalCount: (0, _attr.default)(),
        typeCount: (0, _attr.default)(),
        campaign: (0, _relationships.belongsTo)('campaign')

    });
});