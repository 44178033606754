define('frontend/components/settings/payments-settings', ['exports', 'frontend/mixins/user-settings'], function (exports, _userSettings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {

        payments: null,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.returnPayments().then(function (result) {
                _this.set('payments', result);
            });
        },
        returnPayments: function returnPayments() {
            return this.get('store').findAll('payment', { include: 'plan' }).then(function (results) {
                return results.sortBy('createdAt.date').reverse();
            });
        }
    });
});