define('frontend/initializers/custom-field-value-serializer', ['exports', 'frontend/serializers/custom-field-value'], function (exports, _customFieldValue) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        application.register('serializer:-custom-field-value', _customFieldValue.default);
    }

    exports.default = {
        name: 'custom-field-value-serializer',
        initialize: initialize
    };
});