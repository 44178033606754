define('frontend/components/payments/payment-row', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        i18n: Ember.inject.service(),
        user: Ember.inject.service(),

        url: Ember.computed('payment', function () {
            return '/api/v1/payments/' + this.get('payment.id') + '/invoice';
        }),
        detailsShown: false,

        paymentDateFormat: [],

        didInsertElement: function didInsertElement() {
            this.set('paymentDateFormat', {
                date: this.get('user.data.dateFormat'),
                time: this.get('user.data.timeFormat'),
                time_zone: this.get('i18n').t('formats.time_zone').string
            });
        },
        hideDetails: function hideDetails() {
            this.set('detailsShown', false);
        },
        showDetails: function showDetails() {
            this.set('detailsShown', true);
        },

        actions: {
            toggleDetails: function toggleDetails() {
                if (this.get('detailsShown')) {
                    this.hideDetails();
                } else {
                    this.showDetails();
                }
            },
            hideDetails: function hideDetails() {
                this.hideDetails();
            }
        }

    });
});