define('frontend/validators/template-length', ['exports', 'ember-cp-validations/validators/base', 'frontend/utils/template-max-length'], function (exports, _base, _templateMaxLength) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;


    var TemplateLength = _base.default.extend({
        user: service(),
        maxLength: (0, _templateMaxLength.default)('user.isOnFreePlan'),
        validate: function validate(value, options /*, model, attribute*/) {
            if (value && value.length > this.get('maxLength')) {
                return options.message ? options.message : 'validation.template_length';
            }
            return true;
        }
    });

    exports.default = TemplateLength;
});