define('frontend/controllers/inner-app/contact-groups/contacts', ['exports', 'frontend/mixins/debounce-query-params'], function (exports, _debounceQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        queryParams: ['search'],

        session: service(),
        selectedItems: service(),
        specialItems: service(),

        bulkChangePopoverOpen: false,
        disableFocus: false,

        checkAndRemoveFromList: function checkAndRemoveFromList(contact) {
            // Skip removal for all/ungrouped
            if (isNaN(parseInt(this.get('groupId')))) {
                return;
            }

            if (!contact.get('contactGroups').mapBy('id').includes(this.get('groupId'))) {
                this.get('model').removeObject(contact);
            }
        },


        shouldAllowExport: computed('model', 'model.[]', function () {
            return this.get('model').filter(function (contact) {
                return !contact.get('isNew');
            }).length > 0;
        }),

        shouldAllowMovingToGroup: computed('selectedItems.updated', 'specialItems.updated', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            var search = 0;
            var itemsIds = void 0;

            if (specialItems.search) {
                search = 1;
            }

            var groupId = this.get('groupId');
            groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

            itemsIds = specialItems.getIdsArray('contact', groupId, search);
            if (itemsIds.length) {
                return selectedItems.someSelected(itemsIds);
            }

            return false;
        }),

        unsavedContacts: computed('model', 'model.[]', function () {
            return this.get('model').filter(function (contact) {
                return contact.get('id') === null;
            });
        }),

        allChecked: computed('selectedItems.updated', 'specialItems.updated', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            var search = 0;
            var itemsIds = void 0;

            if (specialItems.search) {
                search = 1;
            }

            var groupId = this.get('groupId');
            groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

            itemsIds = specialItems.getIdsArray('contact', groupId, search);
            if (itemsIds.length) {
                return selectedItems.areSelected(itemsIds);
            }
            return false;
        }),

        actions: {
            changeSearchTo: function changeSearchTo(value) {
                this.set('disableFocus', true);
                this.debounceSetQueryParam('search', value);
            },
            disableFocus: function disableFocus() {
                this.set('disableFocus', false);
            }
        }
    });
});