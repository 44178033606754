define('frontend/adapters/application', ['exports', 'ember-data/adapters/json-api', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _jsonApi, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _jsonApi.default.extend(_dataAdapterMixin.default, {
        namespace: 'api/v1',
        authorizer: 'authorizer:application',
        flashMessages: Ember.inject.service(),
        i18n: Ember.inject.service(),

        coalesceFindRequests: true,

        urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
            var url = this._super.apply(this, arguments);
            var query = Ember.get(snapshot, 'adapterOptions.query');
            if (query) {
                url += '?' + Ember.$.param(query); // assumes no query params are present already
            }
            return url;
        },
        urlForQueryRecord: function urlForQueryRecord(query) {
            if (query.me) {
                delete query.me;
                return this._super.apply(this, arguments) + '/me';
            }

            return this._super.apply(this, arguments);
        }
    });
});