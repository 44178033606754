define('frontend/components/flash-messages-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        flashMessages: service(),
        flashQueue: Ember.computed.filter('flashMessages.queue', function (flash) {
            return !flash.actionNotification;
        })
    });
});