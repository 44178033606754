define('frontend/routes/inner-app/contact-groups/contacts/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/unsaved-data', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _authenticatedRouteMixin, _unsavedData, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedData.default, _unsavedDataTransitionBlock.default, {
        notifications: service(),
        selectedItems: service(),
        specialItems: service(),
        modelName: 'contact',
        proceedToSaveAction: 'updatedHandler',

        model: function model(params) {
            var contact = this.get('store').peekRecord('contact', params.contactId);
            return contact ? contact : this.get('store').findRecord('contact', params.contactId).catch(function (error) {
                return error;
            });
        },
        beforeModel: function beforeModel() {
            var params = this.paramsFor(this.routeName);
            if (params.contactId === 'undefined') {
                this.transitionTo('inner-app.contact-groups.contacts');
            }

            this._super.apply(this, arguments);
        },

        keyUpGo: false,

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.set('controller.focusedFirstFormField', false);
        },


        actions: {
            updatedHandler: function updatedHandler() {
                var _this = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                var contact = this.modelFor(this.routeName);
                contact.get('contactGroups').then(function (groups) {
                    specialItems.setItemGroups('contact', contact.get('id'), groups.mapBy('id'));
                    //item is edited - removed from current search
                    specialItems.removeFromAllGroups('contact', contact.get('id'), 1);

                    var groupId = _this.paramsFor('inner-app.contact-groups.contacts').groupId;
                    groupId = groupId !== 'ungrouped' ? groupId : 'no-group';
                    var contactGroupsIds = specialItems.getItemGroups('contact', contact.get('id'));
                    //ako sme v nqkoq ot grupite na kontakta - deselektirame
                    if (groupId !== 'all') {
                        if (!contactGroupsIds.includes(groupId)) {
                            selectedItems.remove(contact.get('id'));
                        }
                    }
                    //ako sme v tursene - deselektirame
                    if (specialItems.search) {
                        selectedItems.remove(contact.get('id'));
                    }
                });
                this.get('notifications').showSuccess(this.get('i18n').t('notifications.success-update-contacts'));
            }
        }
    });
});