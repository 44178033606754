define('frontend/services/ajax', ['exports', 'ember-ajax/services/ajax', 'frontend/config/environment'], function (exports, _ajax, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = _ajax.default.extend({
        session: service(),
        headerLoadingIndicator: service(),

        isRunning: false,
        namespace: '/api/v1',
        headers: computed('session.data.authenticated.token', {
            get: function get() {
                var headers = {};
                var authToken = this.get('session.data.authenticated.token');
                if (authToken) {
                    headers[_environment.default.authorizationHeaderName] = 'Bearer ' + authToken;
                }
                headers['Content-Type'] = 'application/vnd.api+json';
                headers['Accept'] = 'application/vnd.api+json';
                return headers;
            }
        }),
        _makeRequest: function _makeRequest() {
            var _this = this;

            this.set('isRunning', true);
            this.get('headerLoadingIndicator').startAjax();
            return this._super.apply(this, arguments).finally(function () {
                _this.set('isRunning', false);
                _this.get('headerLoadingIndicator').stopAjax();
            });
        }
    });
});