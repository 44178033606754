define('frontend/models/help-entry', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _attr, _relationships) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: (0, _attr.default)(),
        video: (0, _attr.default)(),
        description: (0, _attr.default)(),
        anchor: (0, _attr.default)(),
        isHome: (0, _attr.default)('boolean', { defaultValue: false }),

        helpCategory: (0, _relationships.belongsTo)('help-category')
    });
});