define("frontend/router", ["exports", "frontend/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType
    });

    // TODO:

    Router.map(function () {
        // Routes using the main povikvane template

        this.route('inner-app', { path: '/' }, function () {
            this.route('welcome');
            this.route('accept-terms');

            //this.route('dashboard', {path: 'dashboard'});
            this.route('contact-groups', function () {
                this.route('contacts', { path: ':groupId/contacts' }, function () {
                    this.route('create', { path: 'create' });
                    this.route('edit', { path: ':contactId' });
                });
            });
            this.route('template-groups', function () {
                this.route('templates', { path: ':templateGroupId/templates' }, function () {
                    this.route('edit', { path: ':templateId' });
                    this.route('create', { path: 'create/:templateType' });
                });
            });
            this.route('import');

            this.route('import-google');

            this.route('reports', function () {
                this.route('edit', { path: ':campaignId' });
            });

            this.route('api-reports', function () {
                this.route('show', { path: ':period' });
            });

            this.route('events', function () {
                this.route('edit', { path: ':campaignId' }, function () {
                    this.route('contact-groups', function () {
                        this.route('contacts', { path: ':groupId/contacts' });
                    });
                });

                this.route('contact-groups', function () {
                    this.route('contacts', { path: ':groupId/contacts' });
                });
            });
            this.route('not-accepted-terms');
        });

        // Routes using the login/register/etc design
        // We could wrap those in a invisible route with resetNamespace too

        this.route('login');

        this.route('password', function () {
            this.route('reset', { path: 'reset/:reset_token' });
            this.route('forgotten');
        });

        this.route('register');
        this.route('tos');
        this.route('verification', { path: 'verification/:code' });

        this.route('not-found', { path: '*path' });
    });

    exports.default = Router;
});