define('frontend/controllers/inner-app/import-google', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        headerLoadingIndicator: Ember.inject.service(),
        ajax: Ember.inject.service(),
        columnDefinitions: Ember.A([]),
        errorTableConfigs: Ember.A([]),
        currentImportedGroupId: null,
        finishedImport: false,
        startedGoogleLogin: false,

        availableFields: ['first-name', 'last-name', 'phone-number', 'email', 'company', 'website', 'address', 'note'],

        googleLogin: function googleLogin(code) {
            var _this = this;

            this.set('startedGoogleLogin', true);
            this.get('ajax').post('google-access', {
                data: {
                    code: code
                }
            }).then(function (result) {
                if (result.has_token) {
                    _this.importFromGoogle();
                } else {
                    _this.transitionToRoute('/import');
                }
            });
        },
        checkGoogleLogin: function checkGoogleLogin() {
            var _this2 = this;

            this.set('startedGoogleLogin', true);
            this.get('ajax').post('google-access').then(function (result) {
                if (result.has_token) {
                    _this2.importFromGoogle();
                } else {
                    window.location.replace(result.redirect_url);
                }
            });
        },
        importFromGoogle: function importFromGoogle() {
            var _this3 = this;

            this.get('headerLoadingIndicator').start();

            this.get('store').queryRecord('google-contact', { reload: true }).then(function (googleImport) {
                _this3.set('model', googleImport);
                if (googleImport.get('invalid').length > 0) {
                    var headers = _this3.get('availableFields').map(function (col) {
                        col = _this3.get('i18n').t('contacts.import.' + col).string;
                        return col;
                    });
                    headers = headers.map(function (header, index) {
                        return { 'label': header, 'valuePath': '' + index, 'mappedField': '' };
                    });

                    _this3.set('columnDefinitions', headers);

                    _this3.set('errorTableConfigs', _this3.get('columnDefinitions').map(function (col, index) {
                        col.valuePath = _this3.get('availableFields')[index];
                        return col;
                    }));
                }
                _this3.set('currentImportedGroupId', googleImport.get('meta.imported-group-id'));
                _this3.set('finishedImport', true);
                _this3.get('headerLoadingIndicator').stop();
            }).catch(function () {
                _this3.checkGoogleLogin();
            });
        },
        resetState: function resetState() {
            this.set('columnDefinitions', Ember.A([]));
            this.set('finishedImport', false);
        },


        actions: {
            resetState: function resetState() {
                this.resetState();
            }
        }
    });
});