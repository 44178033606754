define('frontend/controllers/inner-app/index', ['exports', 'frontend/utils/parse-error-status'], function (exports, _parseErrorStatus) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend({
        user: service(),
        store: service(),
        notifications: service(),
        specialItems: service(),
        modal: service(),
        moment: service(),

        initialContactCount: 0,
        initialTemplateCount: 0,

        countContacts: computed('specialItems.updated', {
            get: function get() {
                var upToDateCount = this.get('specialItems').count('contact', 'all');
                return upToDateCount === 0 ? this.get('initialContactCount') : upToDateCount;
            },
            set: function set(oldVal, newVal) {
                this.set('initialContactCount', newVal);
                return newVal;
            }
        }),
        countTemplates: computed('specialItems.updated', {
            get: function get() {
                var upToDateCount = this.get('specialItems').count('template', 'all');
                return upToDateCount === 0 ? this.get('initialTemplateCount') : upToDateCount;
            },
            set: function set(oldVal, newVal) {
                this.set('initialTemplateCount', newVal);
                return newVal;
            }
        }),
        showCalendarEvents: false,
        selectedDate: null,

        datesWithEvents: computed('model.events.[]', 'user.data.timezone', function () {
            return this.get('model.events').mapBy('sendDate').uniq();
        }),

        eventsList: computed('selectedDate', 'model.events.[]', 'model.events.@each.isReady', 'user.data.timezone', function () {
            var filterDate = this.get('moment').moment();

            if (this.get('selectedDate')) {
                filterDate = this.get('selectedDate');
            }

            return this.get('model.events').filter(function (campaign) {
                return filterDate.isSame(campaign.get('sendDate'), 'day');
            });
        }),

        nextEvent: computed('model.events.[]', 'selectedDate', 'user.data.timezone', function () {
            var _this = this;

            var filterDate = this.get('moment').moment();

            if (this.get('selectedDate')) {
                filterDate = this.get('selectedDate');
            }

            return this.get('model.events').filter(function (campaign) {
                var isSameDay = filterDate.isSame(campaign.get('sendDate'), 'day');
                var nextEventToday = _this.get('moment').moment().isBefore(campaign.get('sendDate')) && isSameDay;
                return nextEventToday ? nextEventToday : isSameDay;
            }).sortBy('sendDate', 'asc').get('firstObject');
        }),

        reportsSmsStatistic: computed('selectedDate', 'model.events.[]', function () {
            var filterDate = this.get('moment').moment();

            if (this.get('selectedDate')) {
                filterDate = this.get('selectedDate');
            }

            var currentData = this.get('model.events.meta.smsSnapshots');

            if (currentData !== undefined) {
                return currentData.filter(function (campaign) {
                    return filterDate.isSame(campaign.send_at, 'day');
                });
            }
            return [];
        }),

        hasFailed: function hasFailed(snapshot, type) {
            var statuses = type === 'sms' ? ['not_delivered_to_handset', 'not_accepted_from_smsc', 'send_failed', 'transport_server_failed'] : ['failed', 'transport_server_failed', 'busy', 'no_answer'];
            return statuses.contains(snapshot.status);
        },

        isProcessing: function isProcessing(snapshot, type) {
            var statuses = type === 'sms' ? ['queued_on_smsc', 'sent_to_transport', 'delivered_to_smsc'] : ['queued', 'ringing', 'in_progress'];
            return statuses.contains(snapshot.status);
        },
        isDelivered: function isDelivered(snapshot, type) {
            var statuses = type === 'sms' ? ['delivered_to_handset'] : ['ready', 'initiated'];
            return statuses.contains(snapshot.status);
        },
        setStatistics: computed('reportsSmsStatistic.[]', function () {
            var _this2 = this;

            this.resetProperties();
            var total = 0;
            var hasStatistic = this.get('reportsSmsStatistic').length;
            var successDelivered = 0;
            var notDelivered = 0;
            var queuedSms = 0;

            if (hasStatistic > 0) {
                this.get('reportsSmsStatistic').forEach(function (record) {
                    var type = record.type;
                    record.snapshots.forEach(function (snapshot) {
                        total += snapshot.countByType;
                        if (_this2.isDelivered(snapshot, type)) {
                            successDelivered += snapshot.countByType;
                        }
                        if (_this2.isProcessing(snapshot, type)) {
                            queuedSms += snapshot.countByType;
                        }
                        if (_this2.hasFailed(snapshot, type)) {
                            notDelivered += snapshot.countByType;
                        }
                    });
                });

                this.set('smsStatusesData.successfullDeliveries', successDelivered);
                this.set('smsStatusesData.failedDeliveries', notDelivered);
                this.set('smsStatusesData.inProgressDeliveries', queuedSms);
            }

            var deliveredPercent = Math.round(this.get('smsStatusesData.successfullDeliveries') / total * 100);
            var notDeliveredPercent = Math.round(this.get('smsStatusesData.failedDeliveries') / total * 100);
            var inProcessPercent = Math.round(this.get('smsStatusesData.inProgressDeliveries') / total * 100);

            this.set('smsStatusesPercents.successfullDeliveries', this.checkDataIsNan(deliveredPercent));
            this.set('smsStatusesPercents.failedDeliveries', this.checkDataIsNan(notDeliveredPercent));
            this.set('smsStatusesPercents.inProgressDeliveries', this.checkDataIsNan(inProcessPercent));

            return {
                smsStatusesData: this.get('smsStatusesData'),
                smsStatusesPercents: this.get('smsStatusesPercents'),
                smsCount: total
            };
        }),

        smsStatusesData: {
            successfullDeliveries: 0,
            failedDeliveries: 0,
            inProgressDeliveries: 0
        },

        smsStatusesPercents: {
            successfullDeliveries: 0,
            failedDeliveries: 0,
            inProgressDeliveries: 0
        },

        checkDataIsNan: function checkDataIsNan(data) {
            return isNaN(data) ? 0 : data;
        },
        resetProperties: function resetProperties() {
            this.set('smsStatusesData.successfullDeliveries', 0);
            this.set('smsStatusesData.failedDeliveries', 0);
            this.set('smsStatusesData.inProgressDeliveries', 0);
            this.set('smsStatusesPercents.successfullDeliveries', 0);
            this.set('smsStatusesPercents.failedDeliveries', 0);
            this.set('smsStatusesPercents.inProgressDeliveries', 0);
        },
        setEventsListVisibility: function setEventsListVisibility(visible) {
            this.set('showCalendarEvents', visible);

            if (!visible) {
                this.set('selectedDate', null);
            }
        },
        getMonthDateRange: function getMonthDateRange(newCenter) {
            var now = this.get('moment').moment();
            var day = newCenter.clone().startOf('month').startOf('isoWeek');
            var lastDay = newCenter.clone().endOf('month').endOf('isoWeek');
            var days = [];
            while (day.isBefore(lastDay)) {
                var copy = day.clone();
                var isCurrentMonth = copy.month() === now.month();
                var isCurrentDay = day.isSame(this.get('moment').moment(), 'day');
                days.push({ date: copy.toDate(), moment: copy, isCurrentMonth: isCurrentMonth, isCurrentDay: isCurrentDay });
                day.add(1, 'day');
            }
            this.set('days', days);
        },


        days: computed('user.data.timezone', function () {
            var now = this.get('moment').moment();
            var day = now.clone().startOf('month').startOf('isoWeek');
            var lastDay = now.clone().endOf('month').endOf('isoWeek');
            var days = [];
            while (day.isBefore(lastDay)) {
                var copy = day.clone();
                var isCurrentMonth = copy.month() === now.month();
                var isCurrentDay = day.isSame(this.get('moment').moment(), 'day');
                days.push({ date: copy.toDate(), moment: copy, isCurrentMonth: isCurrentMonth, isCurrentDay: isCurrentDay });
                day.add(1, 'day');
            }
            return days;
        }),

        weeks: computed('days', function () {
            var days = this.get('days');
            var weeks = [];
            var i = 0;
            while (days[i]) {
                weeks.push({ days: days.slice(i, i + 7) });
                i += 7;
            }
            return weeks;
        }),

        actions: {
            changeStateCalendarEvents: function changeStateCalendarEvents() {
                this.setEventsListVisibility(!this.get('showCalendarEvents'));
            },
            calendarSelectDate: function calendarSelectDate(selectedDate) {
                this.set('selectedDate', selectedDate);
            },
            openCalendar: function openCalendar() {
                this.set('showCalendarEvents', true);
            },
            calendarCenterChange: function calendarCenterChange(newCenter) {
                var _this3 = this;

                this.set('calendarCenter', newCenter);
                this.getMonthDateRange(newCenter);
                this.get('store').query('campaign', {
                    include: 'template', filter: {
                        scheduled_after: newCenter.subtract(1, 'months').startOf('month').format(),
                        scheduled_before: newCenter.add(1, 'months').endOf('month').format(),
                        useStatistic: "yes"
                    }
                }).then(function (result) {
                    _this3.set('model.events', result);
                });
            },
            resetCalendar: function resetCalendar() {
                this.set('calendarCenter', this.get('moment').moment());
            },
            deleteEvent: function deleteEvent(campaign) {
                var _this4 = this;

                if (!campaign.get('isDeleted')) {
                    this.get('dialog').show('dialogs/layouts/confirm', 'dialogs/messages/delete-model', {
                        title: this.get('i18n').t('confirm.event-delete-title'),
                        message: this.get('i18n').t('confirm.event-delete')
                    }).then(function () {
                        return campaign.destroyRecord().then(function () {
                            _this4.get('user').reloadCompany();
                            _this4.get('notifications').showSuccess(_this4.get('i18n').t('notifications.success-delete-campaign'));
                        });
                    }).catch(function (response) {
                        if (response.errors && !(0, _parseErrorStatus.default)(response.errors.get('firstObject').status, 204)) {
                            if (!campaign.get('isReloading')) {
                                campaign.rollbackAttributes();
                                campaign.reload();
                            }
                            _this4.get('notifications').showWarning(_this4.get('i18n').t('notifications.error-delete-campaign'));
                        }
                    });
                }
            },
            changeStateCalendarTransition: function changeStateCalendarTransition() {
                this.setEventsListVisibility(false);
            },
            creditsSettings: function creditsSettings(templatePath) {
                this.get('modal').openSettingsModal(templatePath);
            }
        }

    });
});