define('frontend/services/queue', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        headerLoadingIndicator: service(),

        queues: {},

        /**
         * Add code to be executed in given queueId and return a promise
         * @param methodToExecute
         * @param queueId
         */
        add: function add(methodToExecute) {
            var _this2 = this;

            var queueId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'defaultQueue';

            //start loading indicator if this is the first task
            this._loadingStart();

            this._createQueueIfNotExist(queueId);

            //create new promise
            var newTaskPromise = new Ember.RSVP.Promise(function (resolve, reject) {
                var _this = this;

                var currentQueue = this.get('queues.' + queueId);
                //which will wait for the previous promise in the queue if there is such
                if (Object.keys(currentQueue).length) {
                    //then will execute the passed anonemous function
                    currentQueue[Object.keys(currentQueue).length - 1].then(function () {
                        _this._runTask(resolve, reject, methodToExecute);
                    });
                } else {
                    //or just execute the passed anonemous function
                    this._runTask(resolve, reject, methodToExecute);
                }
            }.bind(this));

            //add the new promise to queue
            this.get('queues.' + queueId).push(newTaskPromise);

            //when the new promise resolves - delete it from beginning of queue (and delete queue if this is the last promise in it)
            newTaskPromise.catch(function (e) {
                //delete crashed queue
                delete _this2.get('queues')[queueId];

                throw e;
            }).finally(function () {
                _this2._trimQueue(queueId);

                //stop loading indicator if this was the last task
                _this2._loadingStop();
            });

            //return the new promise
            return newTaskPromise;
        },


        /**
         * Runs the task
         * @param resolve - method of the promise
         * @param methodToExecute - anonemous function to run
         * @private
         */
        _runTask: function _runTask(resolve, reject, methodToExecute) {
            Ember.run.later(this, function () {
                try {
                    resolve(methodToExecute());
                } catch (e) {
                    reject(e);
                }
            }, 0);
        },
        _exist: function _exist(queueId) {
            return this.get('queues').hasOwnProperty(queueId);
        },
        _createQueueIfNotExist: function _createQueueIfNotExist(queueId) {
            //check if queue exists
            if (!this._exist(queueId)) {
                //add new empty queue
                this.set('queues.' + queueId, []);
            }
        },


        /**
         * Delete a promise from queue. Delete the queue if empty
         * @param queueId
         * @private
         */
        _trimQueue: function _trimQueue(queueId) {
            if (!this._exist(queueId)) {
                return;
            }

            //if there is 1 promise in the queue - delete it
            if (this.get('queues.' + queueId).length === 1) {
                delete this.get('queues')[queueId];
            } else {
                //else delete only the first promise in it
                this.get('queues.' + queueId).splice(0, 1);
            }
        },


        /**
         * Start the header loading indicator
         * @private
         */
        _loadingStart: function _loadingStart() {
            //if there are no queues yet - start the loading
            if (Object.keys(this.get('queues')).length === 0) {
                this.get('headerLoadingIndicator').startQueue();
            }
        },


        /**
         * Stop the header loading indicator
         * @private
         */
        _loadingStop: function _loadingStop() {
            //if there are no queues left - stop the loading
            if (Object.keys(this.get('queues')).length === 0) {
                this.get('headerLoadingIndicator').stopQueue();
            }
        }
    });
});