define('frontend/components/actions-notifications', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        flashMessages: service(),
        notifications: Ember.computed.filterBy('flashMessages.queue', 'actionNotification', true)
    });
});