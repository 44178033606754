define('frontend/routes/inner-app/api-reports', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/infinite-scroll-route'], function (exports, _authenticatedRouteMixin, _infiniteScrollRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _infiniteScrollRoute.default, {

    queryParams: {
      fromDate: {
        refreshModel: true
      },
      toDate: {
        refreshModel: true
      }
    },

    requestParams: {
      page: 1,
      per_page: 50,
      filter: {}
    },

    moment: service(),

    modelType: 'api-report',
    modelGroupType: '',
    modelGroupRelation: '',

    model: function model(params) {
      var _this = this;

      this.set('requestParams.filter', {
        dateFrom: params.fromDate,
        dateTo: params.toDate
      });

      return this.getPage(this.get('requestParams')).then(function (result) {
        _this.set('totalPages', result.get('meta.pagination.total_pages'));

        var periods = result.toArray().map(function (period) {
          return {
            month: period.get('month'),
            year: period.get('year'),
            period: period.get('period'),
            periodFormat: _this.get('moment').moment(period.get('period'), 'M-YYYY'),
            isChecked: period.get('isChecked')
          };
        }).uniq();

        return periods;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.initDebouncedQueryParams(['fromDate', 'toDate']);
      model.setEach('isChecked', false);
    },
    getNextPage: function getNextPage() {
      var _this2 = this;

      var allChecked = this.controller.get('allChecked');

      return this._super.apply(this, arguments).then(function () {
        if (allChecked) {
          _this2.getModel().setEach('isChecked', true);
        }
      });
    },


    actions: {
      checkAllReports: function checkAllReports(checked) {
        this.modelFor(this.routeName).setEach('isChecked', checked);
      },
      checkCampaign: function checkCampaign(report, checked) {
        Ember.set(report, 'isChecked', checked);
      }
    }

  });
});