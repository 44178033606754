define('frontend/components/events/sms-event', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        store: service(),
        moment: service(),
        tagName: 'li',
        classNames: ['tab_content', 'add-new-event-tabs', 'content_active-tab'],
        templates: null,
        selectedTemplate: null,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('store').query('template', {
                filter: {
                    allItems: true,
                    templateableType: "App\\SmsTemplate"
                } }).then(function (result) {
                return _this.set('templates', result);
            });
        },


        actions: {
            changeDate: function changeDate(date) {
                var now = this.get('moment').moment();
                if (!this.get('campaign.sendDate')) {
                    date = this.get('moment').moment(date, 'DD/MM/YYYY').hours(now.hours()).minutes(now.minutes() + 30);
                }
                this.get('campaign').setDate(date);
            },
            changeTime: function changeTime(time) {
                this.get('campaign').setTime(time);
            }
        }
    });
});