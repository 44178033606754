define('frontend/components/datepicker-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        user: service(),
        moment: service(),
        tagName: '',
        allowPastDates: true,
        dateInputValue: '',
        startOfWeek: computed.alias('user.data.weekdayStart'),

        selectedDate: Ember.computed('selected', 'dateInputValue', function () {
            if (this.get('selected') && this.get('selected').isValid()) {
                // Side effect, but works for now
                this.set('center', this.get('selected'));
                return this.get('selected');
            }

            return '';
        }),

        init: function init() {
            this._super.apply(this, arguments);
            if (this.get('selectedDate')) {
                this.set('dateInputValue', this.get('selectedDate').format('DD/MM/YYYY'));
            }
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('selectedDate')) {
                this.set('dateInputValue', this.get('selectedDate').format('DD/MM/YYYY'));
            }
        },


        actions: {
            updateInput: function updateInput(newValue) {
                this.set('dateInputValue', newValue);
            },
            changeDate: function changeDate(newDate) {
                if (typeof newDate === 'string') {
                    newDate = this.get('moment').moment(newDate, 'DD/MM/YYYY');
                }

                this.set('dateInputValue', newDate.format('DD/MM/YYYY'));

                if (newDate.isValid()) {
                    this.sendAction('changeDate', newDate);
                }
            },
            changeInputDateFrom: function changeInputDateFrom() {
                this.sendAction('changeInputDateFrom');
            },
            changeInputDateTo: function changeInputDateTo() {
                this.sendAction('changeInputDateTo');
            }
        }

    });
});