define('frontend/components/contacts/import-table', ['exports', 'ember-light-table'], function (exports, _emberLightTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        classNames: ['import_contacts__mapping'],

        columns: [], // passed in

        model: null, // passed in

        enableFieldSelection: true,

        availableBasicFields: [{
            label: 'contacts.import.first-name',
            propertyName: 'first-name',
            disabled: false
        }, {
            label: 'contacts.import.last-name',
            propertyName: 'last-name',
            disabled: false
        }, {
            label: 'contacts.import.phone-number',
            propertyName: 'phone-number',
            disabled: false
        }, {
            label: 'contacts.import.email',
            propertyName: 'email',
            disabled: false
        }, {
            label: 'contacts.import.company',
            propertyName: 'company',
            disabled: false
        }, {
            label: 'contacts.import.website',
            propertyName: 'website',
            disabled: false
        }, {
            label: 'contacts.import.address',
            propertyName: 'address',
            disabled: false
        }, {
            label: 'contacts.import.note',
            propertyName: 'note',
            disabled: false
        }],
        availableFields: [],

        /**
         * Return a new table object every time columns or data is changed.
         */
        table: Ember.computed('model', 'columns.[]', function () {
            return new _emberLightTable.default(this.get('columns'), this.get('model'), { enableSync: true });
        }),

        init: function init() {
            var _this = this;

            this.set('availableFields', Ember.copy(this.get('availableBasicFields')));
            this.get('store').findAll('custom-field').then(function (customFields) {
                customFields.forEach(function (customField) {
                    _this.get('availableFields').pushObject({
                        label: customField.get('name'),
                        propertyName: customField.get('id'),
                        disabled: false
                    });
                });
                _this.get('availableFields').map(function (field) {
                    Ember.set(field, 'disabled', false);
                });
            });

            this._super.apply(this, arguments);
        },


        actions: {
            changedMapping: function changedMapping(group, selected) {
                var _this2 = this;

                if (group.get('mappedField')) {
                    this.get('availableFields').forEach(function (field, index) {
                        if (field.propertyName === group.get('mappedField').propertyName) {
                            Ember.set(_this2.get('availableFields.' + index), 'disabled', false);
                        }
                    });
                }

                if (selected) {
                    Ember.set(selected, 'disabled', true);
                }

                if (selected) {
                    group.set('mappedField', selected);
                    Ember.set(this.get('columns').findBy('valuePath', group.get('valuePath')), 'mappedField', selected);
                } else {
                    group.set('mappedField', '');
                    Ember.set(this.get('columns').findBy('valuePath', group.get('valuePath')), 'mappedField', '');
                }
            }
        }
    });
});