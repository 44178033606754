define('frontend/models/google-contact', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        meta: (0, _attr.default)(),
        redirect_url: (0, _attr.default)('string'),
        has_token: (0, _attr.default)(),
        invalid: (0, _attr.default)({ defaultValue: function defaultValue() {
                return [];
            } })
    });
});