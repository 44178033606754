define('frontend/controllers/inner-app/contact-groups', ['exports', 'frontend/mixins/debounce-query-params'], function (exports, _debounceQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        session: service(),
        selectedItems: service(),
        specialItems: service(),

        queryParams: ['searchGroup'],

        countAllContacts: computed('specialItems.updated', function () {
            return this.get('specialItems').count('contact', 'all');
        }),
        countUngroupedContacts: computed('specialItems.updated', function () {
            return this.get('specialItems').count('contact', 'no-group');
        }),
        countGoogleContacts: computed('specialItems.updated', function () {
            return this.get('specialItems').count('contact', 'google');
        }),

        allGroupsChecked: computed('selectedItems.updated', 'model', 'model.[]', function () {
            var normalGroupsSelected = false;

            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            if (this.get('model').get('length') > 0) {
                normalGroupsSelected = this.get('model').reduce(function (carry, group) {
                    if (specialItems.count('contact', group.get('id'))) {
                        return carry && selectedItems.areSelected(specialItems.getIdsArray('contact', group.get('id')));
                    }
                    return carry && group.get('isChecked');
                }, true);
            }

            if (normalGroupsSelected && !this.searchGroup) {
                if (!selectedItems.areSelected(specialItems.getIdsArray('contact', 'google'))) {
                    return false;
                }
                if (!selectedItems.areSelected(specialItems.getIdsArray('contact', 'no-group'))) {
                    return false;
                }
            }

            return normalGroupsSelected;
        }),

        shouldAllowExport: Ember.computed('selectedItems.updated', 'model.[]', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            return this.get('model').filter(function (group) {
                if (specialItems.count('contact', group.get('id'))) {
                    return selectedItems.areSelected(specialItems.getIdsArray('contact', group.get('id')));
                }
                return false;
            }).length;
        })
    });
});