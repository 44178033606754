define('frontend/services/pusher', ['exports', 'frontend/config/environment', 'frontend/lib/pset'], function (exports, _environment, _pset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        callbackIds: null,
        events: null,
        channel: null,
        channelName: null,
        subscribedPromise: null,
        subscribedResolve: null,

        init: function init() {
            this.set('callbackIds', new _pset.default());
            this.set('events', new _pset.default());

            this.set('pusher', new Pusher(_environment.default.pusher.key, {
                cluster: _environment.default.pusher.cluster,
                encrypted: _environment.default.pusher.encrypted
            }));

            this.initSubscribePromise();
        },
        bind: function bind(event, callbackId, callback) {
            var _this = this;

            this.get('subscribedPromise').then(function () {
                if (!_this.get('callbackIds').has(callbackId)) {
                    _this.get('channel').bind(event, callback);
                    _this.get('callbackIds').add(callbackId);
                    _this.get('events').add(event);
                }
            });
        },
        unbind: function unbind(event, callbackId, callback) {
            this.get('channel').unbind(event, callback);
            this.get('callbackIds').delete(callbackId);
        },
        subscribe: function subscribe(userId) {
            if (this.get('channel') === null) {
                this.get('callbackIds').clear();
                this.get('events').clear();
                this.set('channelName', 'notifications-' + userId);
                this.set('channel', this.get('pusher').subscribe(this.get('channelName')));
                this.get('subscribedResolve')(true);
            }
        },
        unsubscribe: function unsubscribe() {
            this.get('pusher').unbind_all();
            this.get('pusher').unsubscribe(this.get('channelName'));
            this.set('channel', null);
            this.initSubscribePromise();
        },
        initSubscribePromise: function initSubscribePromise() {
            this.set('subscribedPromise', new Ember.RSVP.Promise(function (resolve) {
                this.set('subscribedResolve', resolve);
            }.bind(this)));
        }
    });
});