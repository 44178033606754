define('frontend/components/contacts/name-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didRender: function didRender() {
            if (!Ember.$('.focus-input input').is(":focus")) {
                this.$('.input-group-name.ember-text-field').focus();
            }
        },


        actions: {
            saveGroup: function saveGroup(contactGroup) {
                this.sendAction('saveGroup', contactGroup);
            }
        }

    });
});