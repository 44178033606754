define('frontend/components/settings/custom-fields-settings', ['exports', 'frontend/mixins/user-settings', 'frontend/utils/unique-group-name'], function (exports, _userSettings, _uniqueGroupName) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend(_userSettings.default, {

        user: service(),
        notifications: service(),
        modal: service(),
        i18n: service(),
        customFields: Ember.A([]),
        disabledAddButton: false,
        showAddFieldButton: false,

        validationUniqueField: null,
        contactFields: [{
            id: 'first-name',
            name: 'contacts.import.first-name'
        }, {
            id: 'last-name',
            name: 'contacts.import.last-name'
        }, {
            id: 'phone-number',
            name: 'contacts.import.phone-number'
        }, {
            id: 'email',
            name: 'contacts.import.email'
        }, {
            id: 'company',
            name: 'contacts.import.company'
        }, {
            id: 'website',
            name: 'contacts.import.website'
        }, {
            id: 'address',
            name: 'contacts.import.address'
        }, {
            id: 'note',
            name: 'contacts.import.note'
        }],

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.getCompanyCustomFields().then(function (fields) {
                var customFields = fields.get('length') > 0 ? fields.toArray() : Ember.A([]);
                _this.set('customFields', customFields);
                _this.set('showAddFieldButton', true);
                _this.get('contactFields').addObjects(customFields);
                _this.set('validationUniqueField', _this.get('contactFields').findBy('id', _this.get('user.data.currentCompany.contactsUniqueField')));
            });
        },


        actions: {
            addCustomField: function addCustomField() {
                var _this2 = this;

                this.set('disabledAddButton', true);
                var successMessage = this.get('i18n').t('settings.custom-fields.custom-field-create-notification');
                var customFieldName = (0, _uniqueGroupName.default)(this.get('customFields'), this.get('i18n').t('settings.custom-fields.custom-field-create-name').string, 0);
                var customField = this.get('store').createRecord('custom-field', { name: customFieldName });

                return customField.save().then(function (field) {
                    field.set('focus', true);
                    _this2.get('customFields').pushObject(field);
                    _this2.get('contactFields').pushObject(field);
                    _this2.get('notifications').showSuccess(successMessage);
                    return true;
                }).catch(function (response) {
                    _this2.get('modal').alert({
                        title: _this2.i18n.t('errors.validation-title'),
                        message: _this2.i18n.t(response.errors[0].detail)
                    });
                    customField.rollbackAttributes();
                    _this2.get('store').unloadRecord(customField);
                    _this2.get('customFields').removeObject(customField);
                    _this2.get('contactFields').removeObject(customField);
                    return true;
                }).finally(function () {
                    _this2.set('disabledAddButton', false);
                });
            },
            editCustomField: function editCustomField(fieldId) {
                var _this3 = this;

                var customField = this.get('store').peekRecord('custom-field', fieldId);
                if (customField && customField.get('hasDirtyAttributes')) {
                    var message = 'settings.custom-fields.custom-field-save-notification';

                    if (customField) {
                        return customField.save().then(function () {
                            _this3.get('notifications').showSuccess(_this3.get('i18n').t(message));
                            return true;
                        });
                    }
                }
            },
            deleteCustomField: function deleteCustomField(fieldId) {
                var _this4 = this;

                var customField = this.get('store').peekRecord('custom-field', fieldId);

                if (this.get('validationUniqueField.id') === fieldId) {
                    this.get('notifications').showWarning(this.get('i18n').t('settings.custom-fields.field-used-for-validation'));
                    return;
                }

                var message = 'settings.custom-fields.custom-field-delete-notification';
                this.get('modal').confirm({ message: this.get('i18n').t('confirm.custom-field-delete') }).then(function () {
                    if (customField) {
                        return customField.destroyRecord().then(function (field) {
                            _this4.get('customFields').removeObject(field);
                            _this4.get('contactFields').removeObject(field);
                            _this4.get('notifications').showSuccess(_this4.get('i18n').t(message));
                        });
                    }
                });
            },
            saveValidationUniqueField: function saveValidationUniqueField(field) {
                this.set('validationUniqueField', field);
                this.changeCompanyData({
                    name: 'contactsUniqueField',
                    value: field.id
                });
            }
        }

    });
});