define('frontend/routes/inner-app/template-groups/templates', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/infinite-scroll-route', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _authenticatedRouteMixin, _infiniteScrollRoute, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _infiniteScrollRoute.default, _unsavedDataTransitionBlock.default, {
        queryParams: {
            search: {
                refreshModel: true
            }
        },

        modelType: 'template',
        modelGroupType: 'template-groups',
        modelGroupRelation: 'templates',

        selectedItems: service(),
        specialItems: service(),
        queue: service(),
        modal: service(),
        ajax: service(),
        notifications: service(),

        setupController: function setupController(controller, model) {
            this._super(controller, model);

            var params = this.getParams();
            controller.set('templateGroupId', params.templateGroupId);
            controller.set('searchTmplGroup', this.paramsFor('inner-app.template-groups').searchTmplGroup);
            controller.initDebouncedQueryParam('search');
        },

        getNextPage: function getNextPage() {
            var _this = this;

            return this._super.apply(this, arguments).then(function (results) {
                if (_this.get('controller.allChecked')) {
                    _this.get('selectedItems').addMany(results.mapBy('id'));
                }
                return results;
            });
        },
        modelLoad: function modelLoad(params) {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');
            this.get('queue').add(function () {
                return specialItems.loadSearch('template', params).then(function (result) {
                    if (result) {
                        selectedItems.flush();
                    }
                    return true;
                });
            }, 'template');
        },
        closeDropdown: function closeDropdown(dropdown) {
            dropdown.actions.close();
        },


        actions: {
            checkAll: function checkAll(checked) {
                var _this2 = this;

                var groupId = this.getParams().templateGroupId;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    var promises = [];
                    if (_this2.shouldDoGeneralQuery(groupId)) {
                        if (groupId === 'all') {
                            var groups = _this2.modelFor('inner-app.template-groups');
                            if (groups) {
                                groups.forEach(function (group) {
                                    promises.push(specialItems.getIds('template', group.get('id'), 1).then(function (ids) {
                                        if (checked) {
                                            selectedItems.addMany(ids);
                                        } else {
                                            selectedItems.removeMany(ids);
                                        }
                                    }));
                                });
                            }
                        }
                        if (groupId === 'ungrouped' || groupId === 'all') {
                            promises.push(specialItems.getIds('template', 'no-group', 1).then(function (ids) {
                                if (checked) {
                                    selectedItems.addMany(ids);
                                } else {
                                    selectedItems.removeMany(ids);
                                }
                            }));
                        }
                    } else {
                        promises.push(specialItems.getIds('template', groupId, 1).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        }));
                    }
                    return Ember.RSVP.all(promises);
                }, 'template');
            },
            bulkChangeGroups: function bulkChangeGroups(group, dropdown) {
                var _this3 = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    if (!group) {
                        return false;
                    }

                    _this3.closeDropdown(dropdown);

                    var search = 0;
                    if (specialItems.search) {
                        search = 1;
                    }

                    var params = _this3.paramsFor(_this3.routeName);
                    var groupId = params.templateGroupId;
                    groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

                    return specialItems.getIds('template', groupId, search).then(function (ids) {
                        var checkedTemplatesId = selectedItems.getIds(ids);

                        return _this3.get('ajax').patch('templates', {
                            data: {
                                ids: JSON.stringify(checkedTemplatesId),
                                groupId: group.get('id')
                            }
                        }).then(function () {
                            //resync groups
                            return specialItems.syncGroups('template').then(function () {
                                //remove ember models
                                var promiseModelAddGroup = void 0;
                                var models = _this3.getModel();
                                var modelChunks = [];
                                for (var i = 0; i < models.length; i += 50) {
                                    modelChunks.push(models.slice(i, i + 50));
                                }
                                if (modelChunks.length) {
                                    modelChunks.forEach(function (modelChunk) {
                                        promiseModelAddGroup = _this3.get('queue').add(function () {
                                            modelChunk.forEach(function (template) {
                                                if (checkedTemplatesId.indexOf(template.get('id')) >= 0) {
                                                    if (params.templateGroupId === 'ungrouped') {
                                                        //record is undefined fix
                                                        _this3.get('store').unloadRecord(template);
                                                        _this3.getModel().removeObject(template);
                                                    } else {
                                                        template.get('templateGroups').addObject(group);
                                                    }
                                                }
                                            });
                                            return true;
                                        }, 'addGroupToTemplateModels');
                                    });
                                }

                                return promiseModelAddGroup.then(function () {
                                    _this3.get('notifications').showSuccess(_this3.get('i18n').t('notifications.success-add-templates-to-group'));
                                    if (params.templateGroupId === 'ungrouped') {
                                        _this3.transitionTo('inner-app.template-groups.templates', params.templateGroupId);
                                    }
                                });
                            });
                        });
                    });
                }, 'template');
            },
            deleteTemplate: function deleteTemplate(template) {
                var _this4 = this;

                var specialItems = this.get('specialItems');

                var params = this.paramsFor(this.routeName);

                this.get('modal').confirm({ message: this.i18n.t('confirm.template-delete') }).then(function () {
                    _this4.get('queue').add(function () {
                        var templateIndex = _this4.getModel().indexOf(template);
                        if (templateIndex === -1) {
                            return false;
                        }
                        var templateId = template.get('id');

                        _this4.getModel().removeAt(templateIndex, 1);
                        return template.destroyRecord().then(function () {
                            specialItems.removeFromAllGroups('template', templateId);
                            _this4.get('notifications').showSuccess(_this4.get('i18n').t('notifications.success-delete-templates'));
                            return _this4.transitionTo('inner-app.template-groups.templates', params.templateGroupId);
                        });
                    }, 'template');
                });
            },
            batchDeleteTemplates: function batchDeleteTemplates() {
                var _this5 = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                var search = 0;
                if (specialItems.search) {
                    search = 1;
                }

                var params = this.paramsFor(this.routeName);
                var groupId = params.templateGroupId;
                groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

                return specialItems.getIds('template', groupId, search).then(function (ids) {
                    var templateIds = selectedItems.getIds(ids);

                    if (templateIds.length === 0) {
                        _this5.get('modal').alert({
                            template: 'dialogs/messages/delete-model',
                            message: _this5.i18n.t('confirm.select-templates-to-delete')
                        });
                        return false;
                    }

                    return _this5.get('modal').confirm({ message: _this5.i18n.t('confirm.templates-delete') }).then(function () {
                        _this5.get('queue').add(function () {
                            return _this5.get('ajax').del('templates', { data: { ids: JSON.stringify(templateIds) } }).then(function () {
                                return specialItems.syncGroups('template').then(function () {
                                    return specialItems.removeManyFromAllGroups('contact', templateIds).then(function () {
                                        templateIds.forEach(function (id) {
                                            var template = _this5.getModel().findBy('id', id);
                                            if (template) {
                                                _this5.get('store').unloadRecord(template);
                                                _this5.getModel().removeObject(template);
                                            }
                                        });

                                        selectedItems.flush();
                                        _this5.modelFor('inner-app/template-groups').forEach(function (group) {
                                            group.set('isChecked', false);
                                        });

                                        _this5.get('notifications').showSuccess(_this5.get('i18n').t('notifications.success-delete-many-templates'));
                                        return _this5.transitionTo('inner-app.template-groups.templates', params.templateGroupId);
                                    });
                                });
                            });
                        }, 'template');
                    });
                });
            },
            saveTemplate: function saveTemplate(tmpl) {
                var _this6 = this;

                this.get('queue').add(function () {
                    return tmpl.save().then(function () {
                        var params = _this6.paramsFor(_this6.routeName);
                        _this6.get('notifications').showSuccess(_this6.get('i18n').t('notifications.success-update-templates'));
                        _this6.transitionTo('inner-app.template-groups.templates', params.templateGroupId);
                        return true;
                    });
                }, 'template');
            },
            willTransition: function willTransition() {
                this.get('selectedItems').flush();
                this.modelFor('inner-app/template-groups').forEach(function (group) {
                    group.set('isChecked', false);
                });
            }
        }

    });
});