define('frontend/components/templates/template-form', ['exports', 'frontend/utils/template-max-length'], function (exports, _templateMaxLength) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        tagName: 'div',
        groups: null,
        template: null,
        fields: null,
        hasCustomFields: computed('fields', function () {
            return this.get('fields.length') > 0;
        }),
        showErrorMessage: false,
        focusedFirstField: false,
        disableFocus: false, //passed in
        store: service(),
        user: service(),
        queue: service(),
        hifi: service(),
        recording: false,
        notifications: service(),
        headerLoadingIndicator: service(),
        maxLength: (0, _templateMaxLength.default)('user.isOnFreePlan'),
        canRecordAudio: Ember.computed(function () {
            return typeof navigator.mediaDevices.getUserMedia !== 'undefined';
        }),
        isVoiceUpload: true,
        progressVoice: computed('hifi.duration', 'hifi.position', function () {
            if (!isNaN(this.get('hifi.position')) || !isNaN(this.get('hifi.duration'))) {
                return (this.get('hifi.position') / this.get('hifi.duration') * 100).toFixed(2);
            }
            return;
        }),

        didRender: function didRender() {
            this._super.apply(this, arguments);
            if (!this.get('focusedFirstField')) {
                if (!this.get('disableFocus')) {
                    this.$('.focus-input input').focus();
                }
                this.set('focusedFirstField', true);
            }
        },
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('store').findAll('custom-field').then(function (fields) {
                _this.set('fields', fields);
            });
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('template.isSms')) {
                var body = this.transliterate(this.get('template.body'));
                this.set('template.transliteratedBody', body);
            }
        },


        /**
         * VoiceCom counts the characters \ | ^ [ ] € { } ~ as 2 symbols,
         * so we need to reflect that in the counter.
         *
         * NOTE: expected problems if another provider is added
         */
        doubleCountedCharacters: Ember.computed('template.transliteratedBody', function () {
            var result = this.get('template.transliteratedBody').replace('[*', '(*').replace('*]', '*)').match(/([\\\|\^\[\]€\{\}~])/ig);
            if (!result) {
                return [];
            }
            return result;
        }),

        hasDoubleCountedCharacters: Ember.computed.gt('doubleCountedCharacters', 0),

        characterCount: Ember.computed('template.transliteratedBody.length', 'doubleCountedCharacters', function () {
            return this.get('template.transliteratedBody.length') + this.get('doubleCountedCharacters').length;
        }),

        previews: Ember.computed('template.transliteratedBody', function () {
            var body = this.get('template.transliteratedBody');
            var parts = [];
            var startIndex = 0;
            var part = body.substr(startIndex, 160);

            while (part) {
                part = body.substr(startIndex, 160);
                if (part.length > 0) {
                    parts.push(part);
                    startIndex += 160;
                }
            }

            return parts;
        }),

        bodyHasTags: Ember.computed('template.transliteratedBody', function () {
            return this.get('template.transliteratedBody').match(/\[\*.+\*\]/igm);
        }),

        invalidTransliteratedBody: Ember.computed.alias('template.validations.attrs.transliteratedBody.isInvalid'),

        voiceUploadPath: Ember.computed('template', function () {
            var templateId = this.get('template.id');
            return 'templates/' + templateId + '/upload';
        }),

        voiceHasUploadedFile: Ember.computed.notEmpty('template.path'),

        keyUp: function keyUp() /**event**/{
            if (this.get('template.isSms')) {
                var translBody = this.transliterate(this.get('template.body'));
                this.set('template.transliteratedBody', translBody);
            }
        },

        transliterate: function transliterate(text) {
            var body = '';

            if (!text) {
                body = '';
            } else {
                body = transl(text, {
                    replace: {
                        'ъ': 'a',
                        'ь': 'y',
                        'й': 'y',
                        'щ': 'sht',
                        'ще': 'shte',
                        'Ще': 'Shte'
                    }
                });
            }

            return body;
        },
        saveTmpl: function saveTmpl(groups) {
            var _this2 = this;

            this.get('queue').add(function () {
                var template = _this2.get('template');
                if (!template) {
                    return false;
                }
                var templateHasDirtyAttr = template.get('hasDirtyAttributes');
                var hasChangedGroups = groups !== undefined;

                if (!_this2.get('template.isSaving') && (templateHasDirtyAttr || hasChangedGroups)) {
                    return template.validate().then(function (_ref) {
                        var validations = _ref.validations;

                        if (validations.get('isValid')) {
                            return template.save().then(function () {
                                _this2.get('action')();
                            });
                        }
                    });
                }
                return true;
            }, 'template');
        },
        getCursorPosition: function getCursorPosition() {
            var el = this.$('textarea').get(0);
            var selLength = 0;

            var pos = 0;

            if ('selectionStart' in el) {
                pos = el.selectionStart;
                selLength = el.selectionEnd - pos;
            } else if ('selection' in document) {
                el.focus();
                var Sel = document.selection.createRange();
                selLength = document.selection.createRange().text.length;
                Sel.moveStart('character', -el.value.length);
                pos = Sel.text.length - selLength;
            }

            return [pos, selLength];
        },


        actions: {
            focusOutSaveForm: function focusOutSaveForm() {
                this.set('disableFocus', false);
                this.saveTmpl();
            },
            changedGroups: function changedGroups(groups) {
                this.get('template').get('templateGroups').addObjects(groups);
                this.saveTmpl(groups);
            },
            saveVoiceTemplate: function saveVoiceTemplate() {
                var tmpl = this.get('template');
                this.sendAction('action', tmpl);
            },
            addThisTagToTextarea: function addThisTagToTextarea(tag) {
                var _getCursorPosition = this.getCursorPosition(),
                    _getCursorPosition2 = _slicedToArray(_getCursorPosition, 2),
                    pos = _getCursorPosition2[0],
                    selLength = _getCursorPosition2[1];

                var body = this.get('template.body');
                var firstHalf = body.substring(0, pos);
                var secondHalf = body.substring(pos + selLength);

                this.set('template.body', firstHalf + tag + secondHalf);
                this.$('.divTextarea').focus();
            },
            updateBody: function updateBody(value) {
                if (value.length > this.get('maxLength')) {
                    value = value.substr(0, this.get('maxLength'));
                }
                this.get('template').set('body', value);
            },
            uploadedFile: function uploadedFile(response) {
                var template = response.body.data;
                this.set('template.path', template.attributes.path);
                this.set('template.duration', template.attributes.duration);
                this.get('notifications').showSuccess(this.get('i18n').t('notifications.success-upload-voice-file'));
                this.get('headerLoadingIndicator').stop();
            },
            uploadError: function uploadError() /** response */{
                this.get('headerLoadingIndicator').stop();
            },
            play: function play() {
                var src = '/' + this.get('template.path');
                var hifi = this.get('hifi');
                hifi.set('volume', 100);
                hifi.play(src);
            },
            stop: function stop() {
                var hifi = this.get('hifi');
                hifi.pause();
                hifi.set('position', 0);
            },
            delete: function _delete() {
                this.set('template.path', '');
            }
        }
    });
});