define('frontend/routes/inner-app/contact-groups', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/utils/unique-group-name', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _authenticatedRouteMixin, _uniqueGroupName, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedDataTransitionBlock.default, {
        queryParams: {
            searchGroup: {
                refreshModel: true
            }
        },

        selectedItems: service(),
        specialItems: service(),
        queue: service(),
        modal: service(),
        notifications: service(),
        pusher: service(),
        groupIsSaving: false,

        model: function model(params) {
            var _this = this;

            var specialItems = this.get('specialItems');

            this.get('selectedItems').flush();
            return this.get('queue').add(function () {
                return specialItems.initialize('contact').then(function () {
                    return _this.loadModel(params);
                });
            }, 'contact');
        },
        loadModel: function loadModel(params) {
            var specialItems = this.get('specialItems');
            var searchGroup = params.searchGroup ? params.searchGroup : '';
            searchGroup = searchGroup.toString().replace(/\s+/g, ' ').trim();

            //start loading counters
            var loadCounters = specialItems.loadGroupCounters('contact');
            return this.get('store').query('contact-group', {
                reload: true,
                filter: { name: searchGroup }
            }).then(function (groups) {
                groups.forEach(function (group) {
                    group.set('isChecked', false);
                });

                return loadCounters.then(function (counters) {
                    return specialItems.syncGroups('contact', counters).then(function () {
                        return groups.toArray();
                    });
                });
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            controller.initDebouncedQueryParam('searchGroup');

            this.get('pusher').bind('import-finished', 'contact-groups-onImportFinished', this.get('onImportFinished').bind(this));
        },
        onImportFinished: function onImportFinished(data) {
            var _this2 = this;

            if (data.imported > 0) {
                this.get('store').findRecord('contact-group', data.group_id).then(function (newGroup) {
                    _this2.modelFor(_this2.routeName).addObject(newGroup);
                    _this2.get('specialItems').syncGroups('contact');
                });
            }
        },


        actions: {
            checkAllGroups: function checkAllGroups(checked) {
                var _this3 = this;

                var params = this.paramsFor(this.routeName);

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    _this3.modelFor(_this3.routeName).forEach(function (group) {
                        group.set('isChecked', checked);
                        specialItems.getIds('contact', group.get('id')).then(function (ids) {
                            if (checked) {
                                selectedItems.addMany(ids);
                            } else {
                                selectedItems.removeMany(ids);
                            }
                        });
                    });

                    if (!params.searchGroup) {
                        if (checked) {
                            specialItems.getIds('contact', 'no-group').then(function (ids) {
                                selectedItems.addMany(ids);
                            });
                            specialItems.getIds('contact', 'google').then(function (ids) {
                                selectedItems.addMany(ids);
                            });
                        } else {
                            specialItems.getIds('contact', 'no-group').then(function (ids) {
                                selectedItems.removeMany(ids);
                            });
                            specialItems.getIds('contact', 'google').then(function (ids) {
                                selectedItems.removeMany(ids);
                            });
                        }
                    }
                    return true;
                }, 'contact');
            },
            checkContactsForGroup: function checkContactsForGroup(group, checked) {
                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                this.get('queue').add(function () {
                    if (!group) {
                        return false;
                    }
                    group.set('isChecked', checked);
                    if (specialItems.count('contact', group.get('id')) === 0) {
                        return false;
                    }

                    return specialItems.getIds('contact', group.get('id')).then(function (ids) {
                        if (checked) {
                            selectedItems.addMany(ids);
                        } else {
                            selectedItems.removeMany(ids);
                        }
                    });
                }, 'contact');
            },
            addContactGroup: function addContactGroup() {
                var _this4 = this;

                var specialItems = this.get('specialItems');
                this.get('queue').add(function () {
                    if (!_this4.get('groupIsSaving')) {
                        _this4.set('groupIsSaving', true);
                        var groupName = (0, _uniqueGroupName.default)(_this4.modelFor(_this4.routeName), _this4.i18n.t('general.new-contact-group').string, 0);
                        var newGroup = _this4.store.createRecord('contact-group', {
                            name: groupName
                        });
                        return newGroup.save().then(function (group) {
                            _this4.modelFor(_this4.routeName).pushObject(newGroup);
                            _this4.get('notifications').showSuccess(_this4.get('i18n').t('notifications.success-create-contact-groups'));
                            newGroup.set('isEditing', true);
                            _this4.set('groupIsSaving', false);
                            return specialItems.createGroup('contact', group.get('id'));
                        });
                    }
                    return false;
                }, 'contact');
            },
            deleteGroups: function deleteGroups(groups) {
                var _this5 = this;

                var checked = groups.filterBy('isChecked');
                var numberCheckedGroups = checked.get('length');
                var specialItems = this.get('specialItems');

                if (numberCheckedGroups > 0) {
                    this.get('modal').confirm({ message: this.i18n.t('confirm.groups-delete') }).then(function () {
                        _this5.get('queue').add(function () {
                            var deletePromise = void 0;
                            checked.forEach(function (group) {
                                deletePromise = _this5.get('queue').add(function () {
                                    return group.destroyRecord().then(function () {
                                        _this5.modelFor(_this5.routeName).removeObject(group);
                                        return true;
                                    });
                                }, 'contactDeleteGroup');
                            });

                            return deletePromise.then(function () {
                                return specialItems.syncGroups('contact').then(function () {

                                    _this5.get('notifications').showSuccess(_this5.get('i18n').t('notifications.success-delete-many-contact-groups'));
                                    _this5.transitionTo("inner-app.contact-groups.contacts", "all");
                                });
                            });
                        }, 'contact');
                    });
                }
            },
            deleteGroup: function deleteGroup(contactGroup) {
                var _this6 = this;

                var specialItems = this.get('specialItems');
                this.get('modal').confirm({ message: this.i18n.t('confirm.group-delete') }).then(function () {
                    _this6.get('queue').add(function () {
                        if (!contactGroup || contactGroup.get('isDeleted')) {
                            return false;
                        }
                        return contactGroup.destroyRecord().then(function (group) {
                            return specialItems.syncGroups('contact').then(function () {
                                _this6.modelFor(_this6.routeName).removeObject(group);
                                _this6.get('notifications').showSuccess(_this6.get('i18n').t('notifications.success-delete-contact-groups'));
                                _this6.transitionTo("inner-app.contact-groups.contacts", "all");
                            });
                        });
                    }, 'contact');
                });
            },
            saveGroup: function saveGroup(contactGroup) {
                var _this7 = this;

                return this.get('queue').add(function () {
                    return contactGroup.validate().then(function (_ref) {
                        var model = _ref.model,
                            validations = _ref.validations;

                        if (validations.get('isValid')) {
                            return contactGroup.save().then(function () {
                                _this7.get('notifications').showSuccess(_this7.get('i18n').t('notifications.success-update-contact-groups'));
                                return true;
                            }).catch(function (response) {
                                _this7.get('modal').alert({
                                    title: _this7.i18n.t('errors.validation-title'),
                                    message: _this7.i18n.t(response.errors[0].detail)
                                });
                                contactGroup.rollbackAttributes();
                                return true;
                            });
                        } else {
                            _this7.get('modal').alert({
                                title: _this7.i18n.t('errors.validation-title'),
                                message: _this7.i18n.t(model.get('validations.errors').get('firstObject').get('message'))
                            });
                            contactGroup.rollbackAttributes();
                            return true;
                        }
                    });
                }, 'contact');
            }
        }
    });
});