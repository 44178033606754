define('frontend/routes/inner-app/template-groups/templates/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/unsaved-data', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _authenticatedRouteMixin, _unsavedData, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedData.default, _unsavedDataTransitionBlock.default, {
        selectedItems: service(),
        specialItems: service(),
        notifications: service(),
        modelName: 'template',
        proceedToSaveAction: 'updatedHandler',

        model: function model(params) {
            var template = this.get('store').peekRecord('template', params.templateId);
            return template ? template : this.get('store').findRecord('template', params.templateId).catch(function (error) {
                return error;
            });
        },
        beforeModel: function beforeModel() {
            var params = this.paramsFor(this.routeName);
            if (params.templateId === 'undefined') {
                this.transitionTo('inner-app.template-groups.templates');
            }

            this._super.apply(this, arguments);
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.set('controller.focusedFirstFormField', false);
        },


        actions: {
            updatedHandler: function updatedHandler() {
                var _this = this;

                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                var template = this.modelFor(this.routeName);
                template.get('templateGroups').then(function (groups) {
                    specialItems.setItemGroups('template', template.get('id'), groups.mapBy('id'));
                    //item is edited - removed from current search
                    specialItems.removeFromAllGroups('template', template.get('id'), 1);

                    var templateGroupId = _this.paramsFor('inner-app.template-groups.templates').templateGroupId;
                    templateGroupId = templateGroupId !== 'ungrouped' ? templateGroupId : 'no-group';
                    var templateGroupsIds = specialItems.getItemGroups('template', template.get('id'));
                    //ako sme v nqkoq ot grupite na template-a - deselektirame
                    if (templateGroupId !== 'all') {
                        if (!templateGroupsIds.includes(templateGroupId)) {
                            selectedItems.remove(template.get('id'));
                        }
                    }
                    //ako sme v tursene - deselektirame
                    if (specialItems.search) {
                        selectedItems.remove(template.get('id'));
                    }
                });
                this.get('notifications').showSuccess(this.get('i18n').t('notifications.success-update-templates'));
            }
        }
    });
});