define('frontend/components/language-toggler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        locale: Ember.inject.service(),

        curentLocale: Ember.computed.oneWay('locale.curentLocale'),
        languages: Ember.computed.oneWay('locale.languages'),

        actions: {
            changeLocale: function changeLocale(locale) {
                this.get('locale').changeLocale(locale);
            }
        }
    });
});