define('frontend/components/payments/braintree-form', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_authenticatedRouteMixin.default, {
        ajax: Ember.inject.service(),
        notifications: Ember.inject.service(),

        didRender: function didRender() {
            this._super.apply(this, arguments);

            this.getClientTokenAndInitBraintree();
        },
        showWarningNotification: function showWarningNotification() {
            this.get('notifications').showWarning(this.get('i18n').t('errors.busy-payment-gateway'), { timeout: 6000 });

            this.sendAction('closePaymentMethodForm');
        },
        getClientTokenAndInitBraintree: function getClientTokenAndInitBraintree() {
            var _this = this;

            this.get('ajax').request('payments/client-token').then(function (data) {
                braintree.client.create({
                    authorization: data.token
                }, function (err, clientInstance) {
                    if (err) {
                        _this.showWarningNotification();
                        return;
                    }

                    _this.createHostedFields(clientInstance);
                });
            }).catch(function () {
                _this.showWarningNotification();
            });
        },
        createHostedFields: function createHostedFields(clientInstance) {
            var _this2 = this;

            braintree.hostedFields.create({
                client: clientInstance,
                styles: {
                    'input': {
                        'font-size': '14px',
                        'font-family': 'courier, monospace',
                        'font-weight': 'lighter',
                        'color': '#ccc',
                        'height': 'auto',
                        'width': '100%',
                        '-webkit-appearance': 'none',
                        'padding': '10px 20px',
                        'border': '1px solid #ccc',
                        'border-left': '3px solid #ccc',
                        'border-radius': '3px'
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    '.valid': {
                        'color': '#2C74E2'
                    }
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: '4111 1111 1111 1111'
                    },
                    cvv: {
                        selector: '#cvv',
                        placeholder: '123'
                    },
                    expirationDate: {
                        selector: '#expiration-date',
                        placeholder: 'MM/YY'
                    },
                    postalCode: {
                        selector: '#postal-code',
                        placeholder: '1111'
                    }
                }
            }, function (err, hostedFieldsInstance) {
                var form = _this2.$('#cardForm');
                var button = _this2.$('#addPaymentMethodButton');

                var submit = function submit(event) {
                    event.preventDefault();
                    button.attr('disabled', 'disabled');

                    hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                            button.removeAttr('disabled');
                            /*console.error(tokenizeErr);*/
                            return;
                        }

                        _this2.sendPayloadToServer(payload);
                    });
                };

                form.on('submit', submit);
            });
        },
        sendPayloadToServer: function sendPayloadToServer(payload) {
            var _this3 = this;

            this.get('ajax').post('payments/methods', {
                data: {
                    payload: payload
                }
            }).then(function () {
                _this3.sendAction('paymentMethodAdded');
            }).catch(function (error) {
                var msg = void 0;

                if (error.payload.errors[0].meta.type === 'invalid_payment_method') {
                    msg = _this3.get('i18n').t('errors.invalid-card-details');
                } else {
                    msg = _this3.get('i18n').t('errors.busy-payment-gateway');
                }

                _this3.get('notifications').showWarning(msg, { timeout: 6000 });
                _this3.$('#addPaymentMethodButton').removeAttr('disabled');
            });
        }
    });
});