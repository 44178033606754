define('frontend/instance-initializers/add-rollbar-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(appInstance) {
        var rollbarService = appInstance.lookup('service:rollbar');

        rollbarService.get('notifier').configure({
            checkIgnore: function checkIgnore(isUncaught, args) {
                // See Rollbar docs for full argument list and description
                // https://rollbar.com/docs/notifier/rollbar.js/#context-1
                var error = args[0];
                var errorsArr = Array.isArray(error.errors) ? Ember.A(error.errors) : Ember.A([]);

                if (!errorsArr.get('0.status')) {
                    return false;
                }

                return [422, 400, 401, 404, 409].indexOf(parseInt(errorsArr.get('0.status'))) !== -1;
            }
        });
    }

    exports.default = {
        name: 'add-rollbar-filter',
        initialize: initialize
    };
});