define('frontend/services/selected-items', ['exports', 'frontend/lib/pset'], function (exports, _pset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Service.extend({
        store: service(),
        ajax: service(),
        contacts: null,
        updated: false,
        allowUpdate: true,
        disableUpdate: function disableUpdate() {
            this.set('allowUpdate', false);
        },
        enableUpdate: function enableUpdate() {
            this.set('allowUpdate', true);
        },
        updateEnabled: function updateEnabled() {
            return this.get('allowUpdate');
        },
        updateService: function updateService() {
            if (this.updateEnabled()) {
                this.toggleProperty('updated');
            }
        },
        init: function init() {
            this.set('contacts', new _pset.default());

            this.updateService();
        },
        getIds: function getIds() {
            var _this = this;

            var idsToCheck = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            if (idsToCheck.length === 0) {
                return this.get('contacts').items();
            } else {
                var selectedIds = new _pset.default();
                idsToCheck.forEach(function (id) {
                    if (_this.isSelected(id)) {
                        selectedIds.add(id);
                    }
                });
                return selectedIds.items();
            }
        },


        count: function () {
            return computed.alias('contacts.size');
        }(),
        isSelected: function isSelected(id) {
            return this.get('contacts').has(id);
        },
        areSelected: function areSelected(ids) {
            return ids.every(function (id) {
                return this.isSelected(id);
            }, this);
        },
        someSelected: function someSelected(ids) {
            return ids.any(function (id) {
                return this.isSelected(id);
            }, this);
        },
        add: function add(id) {
            if (!this.get('contacts').has(id)) {
                this.get('contacts').add(id);
                this.updateService();
            }
        },
        addMany: function addMany(ids) {
            var _this2 = this;

            if (ids.length === 0) {
                return;
            }
            this.disableUpdate();

            ids.forEach(function (id) {
                return _this2.add(id);
            });

            this.enableUpdate();
            this.updateService();
        },
        remove: function remove(id) {
            this.get('contacts').delete(id);

            this.updateService();
        },
        removeMany: function removeMany(ids) {
            var _this3 = this;

            if (ids.length === 0) {
                return;
            }
            this.disableUpdate();

            ids.forEach(function (id) {
                return _this3.remove(id);
            });

            this.enableUpdate();
            this.updateService();
        },
        flush: function flush() {
            this.get('contacts').clear();

            this.updateService();
        }
    });
});