define('frontend/models/custom-field', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _emberData, _attr, _relationships, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Validators = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('ds-error')]
    }, {
        debounce: 500
    });

    exports.default = _emberData.default.Model.extend(Validators, {
        name: (0, _attr.default)('string'),
        company: (0, _relationships.belongsTo)('company', { async: true })
    });
});