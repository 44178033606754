define("frontend/routes/inner-app/contact-groups/index", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            this.transitionTo("inner-app.contact-groups.contacts", "all");
        }
    });
});