define('frontend/components/events/voice-event', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        moment: Ember.inject.service(),
        tagName: 'li',
        classNames: ['tab_content', 'add-new-event-tabs', 'content_active-tab'],
        templates: null,
        selectedTemplate: null,
        repeatIntervals: [{
            label: 'events.intervals.do-not-repeat',
            value: 'Do not Repeat'
        }, {
            label: 'events.intervals.daily',
            value: 'Daily'
        }, {
            label: 'events.intervals.weekly',
            value: 'Weekly'
        }, {
            label: 'events.intervals.monthly',
            value: 'Monthly'
        }, {
            label: 'events.intervals.annually',
            value: 'Annualy'
        }],

        campaignRepeatInterval: {
            label: 'events.intervals.do-not-repeat',
            value: 'Do not Repeat'
        },

        RepeatOnFailureArray: [{
            label: 'events.repeat-on-failure',
            value: '0'
        }, {
            label: '1',
            value: '1'
        }, {
            label: '2',
            value: '2'
        }, {
            label: '3',
            value: '3'
        }, {
            label: '5',
            value: '5'
        }, {
            label: '10',
            value: '10'
        }],

        campaignRepeatOnFailure: {
            label: 'events.repeat-on-failure',
            value: '0'
        },

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('store').query('template', {
                filter: {
                    templateableType: "App\\VoiceTemplate",
                    allItems: true
                } }).then(function (result) {
                return _this.set('templates', result);
            });

            var repeatInterval = this.get('repeatIntervals').filterBy('value', this.get('campaign.repeatInterval'))[0];
            if (repeatInterval !== undefined) {
                this.set('campaignRepeatInterval', repeatInterval);
            }
        },


        actions: {
            changeDate: function changeDate(date) {
                var now = this.get('moment').moment();
                if (!this.get('campaign.sendDate')) {
                    date = this.get('moment').moment(date, 'DD/MM/YYYY').hours(now.hours()).minutes(now.minutes() + 30);
                }
                this.get('campaign').setDate(date);
            },
            changeTime: function changeTime(time) {
                this.get('campaign').setTime(time);
            },
            changeRepeatInterval: function changeRepeatInterval(item) {
                this.set('campaignRepeatInterval', item);
                this.get('campaign').set('repeatInterval', item.value);
                if (item.value === 'Do not Repeat') {
                    this.get('campaign').set('repeatable', false);
                } else {
                    this.get('campaign').set('repeatable', true);
                }
            },
            changeRepeatOnFailure: function changeRepeatOnFailure(item) {
                this.set('campaignRepeatOnFailure', item);
                this.get('campaign').set('repeatOnFailure', item);
            }
        }
    });
});