define('frontend/components/flash-message-success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    message: '',
    showMessage: false

  });
});