define('frontend/controllers/inner-app/events/contact-groups/contacts', ['exports', 'frontend/mixins/debounce-query-params'], function (exports, _debounceQueryParams) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        service = Ember.inject.service;
    exports.default = Ember.Controller.extend(_debounceQueryParams.default, {
        selectedItems: service(),
        specialItems: service(),
        ajax: service(),

        queryParams: ['search'],
        searchGroups: null,
        ongoingRequest: computed.or('ajax.isRunning', 'campaign.isSaving'),

        allChecked: computed('selectedItems.updated', 'specialItems.updated', function () {
            var selectedItems = this.get('selectedItems');
            var specialItems = this.get('specialItems');

            var search = 0;
            var itemsIds = void 0;

            if (specialItems.search) {
                search = 1;
            }

            var groupId = this.get('groupId');
            groupId = groupId !== 'ungrouped' ? groupId : 'no-group';

            itemsIds = specialItems.getIdsArray('contact', groupId, search);

            if (itemsIds.length) {
                return selectedItems.areSelected(itemsIds);
            }

            return false;
        }),
        disabledEditing: computed.or('campaign.hasRun', 'ongoingRequest')
    });
});