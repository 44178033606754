define('frontend/components/contacts/export-document', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),
        selectedItems: service(),
        specialItems: service(),
        exportPath: '/api/v1/contacts/export',
        type: '',
        searchGroups: '',
        searchContacts: '',
        groupId: '',
        token: '',
        exportGroups: '',
        tokenValue: Ember.computed('session.data.authenticated.token', function () {
            return this.get('session.data.authenticated.token');
        }),
        actions: {
            triggerExport: function triggerExport(type) {
                var selectedItems = this.get('selectedItems');
                var specialItems = this.get('specialItems');

                if (this.get('exportGroups') === '') {
                    this.$().find('input[name="exportGroups"]').val('');
                } else {
                    var groups = this.get('exportGroups') ? this.get('exportGroups').filter(function (group) {
                        return selectedItems.areSelected(specialItems.getIdsArray('contact', group.get('id')));
                    }).mapBy('id') : '';

                    groups = groups ? groups : "none";
                    this.$().find('input[name="exportGroups"]').val(groups);
                }
                this.$().find('input[name="type"]').val(type);
                this.$().find('input[name="token"]').val(this.get('tokenValue'));
                this.$().find('form').trigger('submit');
            }
        }

    });
});