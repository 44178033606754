define('frontend/mixins/routable-body-class', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        bodyClass: '',
        activate: function activate() {
            Ember.$('body').addClass(this.bodyClass);
        },
        deactivate: function deactivate() {
            Ember.$('body').removeClass(this.bodyClass);
        }
    });
});