define("frontend/locales/en/translations", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        "general": {
            "submit": "Submit",
            "login": "Log in",
            "logout": "Log Out",
            "ok": "Ok",
            "yes": "yes",
            "no": "no",
            "confirm": "Confirm",
            "decline": "Decline",
            "email": "E-mail",
            "password": "Password",
            "password-confirmation": "Confirm Password",
            "register": "Register",
            "the-terms-of-service": "the terms of service",
            "the-terms-of-service-href": "http://povikvane.io/terms-of-use",
            "agree-to": "Agree to",
            "success": "Success",
            "error": "Wrong password and/or username",
            "send": "Send",
            "dont-have-contacts": "You do not have any Contacts in this Group. To create one press \"Add Contact\" button",
            "contacts-list-empty": "You do not have any Contacts in this Group. To create one press \"Add Contact\" button",
            "templates-list-empty": "You do not have any Templates in this Group. To create one press \"SMS Template\" or \"Voice Template\" buttons",
            "please-select-contact": "Please select contact to edit",
            "please-select-template": "Please select template to edit",
            "save": "Save",
            "changes-saved": "Changes saved successfully",
            "error-msg": "Error message",
            "notice": "Do you know that?",
            "info": "do you know that?",
            "file-upload-error": "There was an error while uploading. Please try again!",
            "file-upload-formats": "The allowed audio formats for upload are .wav / .mp3",
            "choose-file": "Choose file",
            "uploading": "Uploading",
            "files": "files",
            "name": "Name",
            "last-name": "Last Name",
            "phone": "Phone Number",
            "organization": "Company",
            "tags": "Tags",
            "symbol-count": "Number of symbols",
            "groups": "Groups",
            "group": "Group",
            "record-voice-msg": "Record a voice message",
            "voice-msg-recorded": "The voice message was successfully recorded",
            "record": "Recording",
            "play": "Play",
            "stop": "Stop",
            "upload-file": "Upload File",
            "no-templates-in-group": "You do not have any Templates in this Group.",
            "contacts": "Contacts",
            "add-contact": "Add Contact",
            "import-contacts": "Import Contacts",
            "move-contacts-to-group": "Move Selected Contacts to Group",
            "select-contacts-first": "Select contacts first",
            "export-selected-contacts": "Export Selected Contacts",
            "delete-selected-contacts": "Delete Selected Contacts",
            "edit": "Edit",
            "templates": "Templates",
            "sms-template": "SMS Template",
            "voice-template": "Voice Template",
            "delete-selected-templates": "Delete Selected Templates",
            "move-selected-templates-to-group": "Move Selected Templates to Group",
            "select-templates-first": "Select templates first",
            "edit-template": "Edit Template",
            "create-template": "Create Template",
            "create-contact": "Create Contact",
            "export-selected-groups": "Export Selected Groups",
            "delete-selected-groups": "Delete Selected Groups",
            "all-templates": "All Templates",
            "ungrouped-templates": "Ungrouped Templates",
            "add-group": "Add Group",
            "delete-selected-template-groups": "Delete Selected Template Groups",
            "settings": "Settings",
            "main": "Main",
            "profile": "Profile",
            "users": "Users",
            "emails": "Emails",
            "credits": "Credits",
            "notifications": "Notifications",
            "visualization": "Visualization",
            "information": "Information",
            "done": "Done",
            "total": "Total",
            "contacts-subcaption": "Find all your contacts and groups.",
            "templates-subcaption": "See all await your templates or create new.",
            "reports": "Reports",
            "view-all-reports": "View all Reports",
            "successfull-deliveries": "Successful deliveries",
            "unsuccessfull-deliveries": "Unsuccessful deliveries",
            "processing": "Processing",
            "card-type": "Card type",
            "card-number": "Card number",
            "price": "Price",
            "status": "Status",
            "payments": "Payments",
            "export-contacts-tooltip": "Export",
            "export-contacts-tooltip-msg": "Please select contacts or group to export",
            "export-contact-groups-tooltip": "Export",
            "export-contact-groups-tooltip-msg": "Please select contacts or group to export",
            "security": "Security",
            "change-password": "Change password",
            "delete": "Delete",
            "credits-info": "<p><span class=\"bold\">11 credit=1 SMS / 90 sec. voice recording</span></p><p>If you need a larger subsciption package reach out to us at:</p><p><a href=\"tel:+35924372202\">+359 (2) 437 2202</a><a href=\"mailto: info@shtrak.bg\">info@shtrak.bg</a></p>",
            "retry-upload": "Upload",
            "reset-upload": "Upload a new file",
            "home": {
                "your_credits": "Your Credits"
            },
            "new-contact-group": "Untitled Contact Group",
            "new-template-group": "Untitled Template Group",
            "no-groups-found": "No groups found",
            "coming-soon": "Coming soon...",
            "unsupported-device": "Hi there! <br><br>We apologize for the inconvenience <span class=\"bold\">Povikvane</span> cannot be used via this device or operating system.",
            "unsupported-resolution": "Hi there! <br><br>We apologize for the inconvenience but<span class=\"bold\">Povikvane</span> cannot display properly under this screen resolution.",
            "website": "Website",
            "address": "Address",
            "note": "Note",
            "no-timezone-found": "No timezone found",
            "reset-password-success-mobile": "За да използвате системата Povikvane, моля, преминете на устройство с големина и резолюция на екрана над 1024х768 пиксела.",
            "view-api-reports": "View all API reports"
        },
        "guest": {
            "forgotten-password-heading": "Forgotten password",
            "password-reset-button": "Send Password Reset Link",
            "forgot-your-password": "Forgot?",
            "remember-me": "Remember Me",
            "dont-have-account": "Don't have an account?",
            "sign-up-for-free": "Sign Up for Free",
            "reset-password": "Set Password",
            "verification-success": "Your account was verified, you can now log in.",
            "verification-link-sent": "Verification link sent",
            "resend-verification": "An error occurred or the link has already been used. Do you want to receive another?",
            "password-reset-success": "Your new password was saved successfully.",
            "back-to-login": "Back",
            "account-blocked": "This account is blocked.",
            "login": "Login",
            "success": {
                "register": "Thank You! We sent a link for activation of your account to the provided email."
            }
        },
        "validation": {
            "register-email-present": "This field can't be blank",
            "register-email-format": "The field must be a valid email address",
            "register-email-length": "This field is too long (maximum is {{max}} characters)",
            "register-password-present": "This field can't be blank",
            "register-password-format": "Password must include at least one upper case letter, one lower case letter, and a number. Allowed symbols: !@#$%^&*_+",
            "register-password-length": "This field is too short (minimum is {{min}} characters)",
            "register-agree-terms": "You must agree to the terms of service.",
            "login-email-present": "This field can't be blank",
            "login-email-format": "The field must be a valid email address",
            "login-password-present": "This field can't be blank",
            "forgotten-email-present": "This field can't be blank",
            "forgotten-email-format": "The field must be a valid email address",
            "forgotten-email-length": "This field is too long (maximum is {{max}} characters)",
            "reset-email-present": "This field can't be blank",
            "reset-email-format": "The field must be a valid email address",
            "reset-email-length": "This field is too long (maximum is {{max}} characters)",
            "reset-password-present": "This field can't be blank",
            "reset-password-format": "Password must include at least one upper case letter, one lower case letter, and a number",
            "reset-password-length": "This field is too short (minimum is {{min}} characters)",
            "password-mismatch": "The password confirmation does not match",
            "contact-email-present": "This field can't be blank",
            "contact-email-format": "The field must be a valid email address",
            "contact-firstname-format": "This field can't be blank",
            "contact-phonenumber-present": "This field can't be blank",
            "template-name-present": "Field Template name can't be blank",
            "phone_number": "The field must be a valid telephone number",
            "unique_for_company": "The filed must be unique",
            "unique_phone": "This phone number has already been used",
            "date_in_future": "The date must be in the future",
            "template_length": "You've reached the maximum length for a template.",
            "template-body-min-length": "The template cannot be empty.",
            "template-name-max-length": "You've reached the maximum length of 250 characters for a template name",
            "time_dnd": "The time you selected is in your \"Do not disturb\" interval",
            "event": {
                "name-present": "Name is missing",
                "send-date-present": "Date is missing",
                "send-time-present": "Time is missing",
                "template-present": "Template is missing",
                "invalid-date-past": "Please enter future date",
                "invalid-date-format": "Wrong format (dd/mm/yyyy)",
                "invalid-time-past": "Time must be in the future"
            },
            "group-name-unique": "Group name field should be unique",
            "group-name-not-blank": "Group name field can't be blank"
        },
        "confirm": {
            "contact-delete": "Are you sure you want to delete this contact?",
            "contacts-delete": "Are you sure you want to delete selected contacts?",
            "select-contacts-to-delete": "Please select contacts first",
            "select-contact-groups-to-delete": "Please select contact groups first",
            "select-template-groups-to-delete": "Please select template groups first",
            "select-templates-to-delete": "Please select templates first",
            "group-delete": "Are you sure you want to delete selected group?",
            "groups-delete": "Are you sure you want to delete selected groups?",
            "template-delete": "Are you sure you want to delete selected template?",
            "templates-delete": "Are you sure you want to delete selected {{templatesCount}} templates?",
            "template-groups-delete": "Are you sure you want to delete selected {{groupCount}} template groups?",
            "select-template-to-delete": "Please select templates first",
            "event-delete-title": "Delete event",
            "event-delete": "Are you sure you want to delete this event?",
            "payment-method-delete": "Are you sure you want to delete this payment method?",
            "custom-field-delete": "Are you sure you want to delete this custom field?"
        },
        "tooltip": {
            "dashboard-reports-successful": "These are Successful deliveries.",
            "dashboard-reports-unsuccessful": "These are Unsuccessful deliveries.",
            "dashboard-reports-processing": "These are Processing.",
            "template-body-has-tags": "The template contains dynamic fields. This will change the length of the sent SMS and can lead to sending additional SMS-s."
        },
        "contacts": {
            "confirm-import": 'Import',
            "not-imported-contacts": "<span>{{invalid}}</span> contacts have invalid phone numbers.",
            "duplicated-contacts": "<span>{{duplicated}}</span> contacts have duplicate phone number in the system or in the document.",
            "imported-contacts": "<span>{{imported}}</span> contacts were successfully imported.",
            "import": {
                "heading": "Contact import",
                "file-type-hint": "Please chose a file (<span class=\"bold\">CSV, XLS, XLSX</span>) or download a template",
                "download-template": "Download template",
                "back-to-contacts": "Back to contacts",
                "back": "Back",
                "new-import": "New import",
                "encoding-utf8": "UTF-8",
                "encoding-cp1251": "Windows 1251",
                "encoding-latin": "Latin 1 (ISO-8859-1)",
                "delimiter-comma": ",",
                "delimiter-pipe": "|",
                "delimiter-tab": "tab",
                "no-column-found": "No column found",
                "loading": "Loading...",
                "not-found": "No contacts found.",
                "invalid-contacts": "Unsuccessfully imported contacts",
                "successful-import": "Successfully imported contacts",
                "mapping": "Mapping",
                "first-name": "First Name",
                "last-name": "Last Name",
                "phone-number": "Phone Number",
                "email": "Email",
                "company": "Company",
                "website": "Website",
                "address": "Address",
                "note": "Note",
                "google": "Import contacts from Google",
                "google-button": "Import",
                "google-revoke": "Logout",
                "map-phone-field": "Please choose the column representing the phone number.",
                "map-unique-field": "Please choose the column representing the field for unique validation.",
                "background-processing-started": "Your file is being processed. You will receive an email when the operation is completed.",
                "error": {
                    "default": "Error while importing.",
                    "format": "The file format is not correct.",
                    "delimiter": "The delimiter might not be a comma."
                },
                "invalid-contacts-csv": "<a href=\"{{csvLink}}\" target='_blank'>File</a> with unsuccessfully imported" + " contacts"
            },
            "all": "All Contacts",
            "ungrouped": "Ungrouped Contacts",
            "google": "Imported from Google",
            "groups-select-all": "By choosing this option you will select<br>all groups, including the contacts in them",
            "contacts-select-all": "By choosing this option you will<br>select all contacts in the group",
            "continue-editing-contact": "Continue editing",
            "deny-editing-contact": "Cancel",
            "save-contact-message": "In order to save your contact, please continue by filling out the contact information"
        },
        "payments": {
            "subscription_canceled": "canceled",
            "subscription_charged_successfully": "charged",
            "subscription_charged_unsuccessfully": "failed charge",
            "subscription_went_past_due": "went past due",
            "payment-method": {
                "add": "Add payment method",
                "card-number": "Card number",
                "expiration-date": "Expiration Date",
                "cvv": "CVV",
                "postal-code": "Postal Code",
                "pay": "Pay"
            },
            "download-invoice": "DOWNLOAD INVOICE",
            "missing-payments": "You haven’t made any payments yet."
        },
        "plans": {
            "currency": "BGN",
            "currency_per_month": "BGN/m",
            "credits_per_month": "credits per month",
            "credits": "credits",
            "symbols_in_sms": "{{symbols}} symbols in SMS",
            "full_functionality": "Full functionality",
            "debit": "Debit",
            "online_invoice": "Online invoice",
            "choose": "choose",
            "chosen": "chosen"
        },
        "formats": {
            "date": "DD MMM YYYY",
            "date_short": "DD MM YYYY",
            "time": "HH:mm",
            "time_full": "HH:mm:ss Z",
            "datetime": "DD MMM YYYY, HH:mm:ss",
            "datetime_full": "DD MMM YYYY, HH:mm:ss Z",
            "time_zone": "Z"
        },
        "settings": {
            "credits": {
                "info": "<p><span class=\"bold\">1 credit=1 SMS / 1 automated phone call is 30 sec.</span></p><p>If you’d like to purchase a larger subscription package, please contact us at:</p><p><a href=\"tel:+35924372806\">+359 (2) 437 2806</a><a href=\"mailto:contact@povikvane.io\">contact@povikvane.io</a></p>",
                "more-information-required": "<p>In order to upgrade to another package, you’ll have to fill out your company details in subsection \"Profile\", so that we can update your invoice payment information.</p><p>After you fill it our, you can come back here and choose your subscription package.</p>",
                "change-system-plan": "<p>You are on a system package. In order to change to another package, please contact us.</p>",
                "change-plan": "Update plan",
                "change-plan-prompt": "Do you confirm the subscription plan update?<br><br>At the moment you have {{credits}} unused credits. They will not rollover to your new plan.",
                "credits": "Credits",
                "change-plan-payment-title": "Your request is being processed",
                "change-plan-payment-message": "Upon its approval, you will receive a confirmation email and the credits will be applied in your profile.",
                "change-plan-progress-message": "Your request is being processed. Please try again later."
            },
            "main": {
                "language": "Language",
                "languages": {
                    "en": {
                        "short": "En",
                        "long": "English"
                    },
                    "bg": {
                        "short": "Bg",
                        "long": "Bulgarian"
                    }
                },
                "timezone": "Time zone",
                "date-format": "Date format",
                "date-format-translate": "23st Jun 2016",
                "start-week": "Start week on",
                "days": {
                    "mo": "Monday",
                    "tu": "Tuesday",
                    "we": "Wednesday",
                    "th": "Thursday",
                    "fr": "Friday",
                    "sa": "Saturday",
                    "su": "Sunday",
                    "short": {
                        "mo": "Mon",
                        "tu": "Tue",
                        "we": "Wed",
                        "th": "Thu",
                        "fr": "Fri",
                        "sa": "Sat",
                        "su": "Sun"
                    }
                },
                "disturb": "Do not disturb time"
            },
            "information": {
                "content": "<h3>Information</h3><p>Copyright © 2010-2018 Shtrak Bg</p><h3>Licenses</h3>",
                "show-licenses": "Used software:",
                "show-licenses-action": "show"
            },
            "notifications": {
                "content": "<p>Povikvane will inform you about certain events via e-mail.</p><p>You may disable these notifications here:</p>",
                "upcoming-campaign": "Reminder about upcoming campaign start",
                "completed-campaign": "Report on completed campaign",
                "confirm-delete": "Confirm information delete"
            },
            "payments": {
                "history": "Payments history"
            },
            "profile": {
                "personal-info": "Personal Information",
                "company-details": "Company Details",
                "payment-methods": "Payment Methods",
                "payment-method-missing": "Currently, you don’t have any payment method selected. You can choose a payment method by visiting the \"Credits\" tab in the settings panel.",
                "payment-method-delete": "Delete",
                "welcome-screen": "Welcome screen",
                "welcome-screen-button": "Welcome screen"
            },
            "security": {
                "delete-account-title": "Delete profile",
                "delete-account": "Are you sure you want to delete your profile?",
                "delete-success": "Your account was deleted successfully!",
                "change-password": "Save"
            },
            "api-access": {
                "title": "API access",
                "keys": "API Keys",
                "service-id": "Service ID",
                "active-keys": "Active Keys",
                "revoke-key": "Revoke key",
                "key": "Key",
                "actions": "Actions",
                "generate-key": "Generate new API key",
                "webhook-title": "Webhook url",
                "webhook-description": "A URL address which we will use to send data about messages sent via the API.",
                "webhook-placeholder": "webhook url",
                "no-active-keys": "You don't have any active api keys right now.",
                "webhook-save-notification": "Webhook URL updated successfully",
                "download-docs": "Download documentation",
                "doc-header": "API Documentation"
            },
            "custom-fields": {
                "unique-validation-field-title": "Choose field for unique contact validation",
                "title": "Custom fields",
                "add-custom-field": "Add custom field",
                "custom-field-save-notification": "Field name updated successfully",
                "custom-field-create-notification": "Custom field created successfully",
                "custom-field-create-name": "Custom field",
                "custom-field-delete-notification": "Custom field deleted successfully",
                "no-custom-fields": "You don't have any custom fields"
            }
        },
        "placeholder": {
            "template": {
                "search": "Search...",
                "group-search": "Search...",
                "name": "Template name"
            },
            "profile": {
                "first-name": "First name",
                "last-name": "Last name",
                "email": "E-mail",
                "organization": "Organization",
                "eik": "EIK",
                "address": "Address",
                "city": "City",
                "zip-code": "Zip Code",
                "manager": "Manager",
                "phone": "Telephone Number"
            },
            "contact": {
                "first-name": "First Name",
                "last-name": "Last Name",
                "company": "Company",
                "website": "Website",
                "phone": "Phone number (359xxxxxxxx)",
                "email": "Email",
                "address": "Address",
                "group-search": "Search...",
                "search": "Search...",
                "note": "Note"
            },
            "login-form": {
                "email": "E-mail",
                "password": "Password"
            },
            "password-reset-form": {
                "email": "E-mail",
                "password": "Password",
                "password-confirmation": "Confirm Password"
            },
            "forgotten-password-form": {
                "email": "E-mail"
            },
            "register-form": {
                "email": "E-mail",
                "password": "Password",
                "registrations-closed": "We are sorry but the registrations are closed at the moment."
            },
            "security": {
                "current-password": "Current password",
                "new-password": "New password",
                "new-password-confirmation": "Confirm new password"
            },
            "help": {
                "search": "Search..."
            },
            "select": {
                "group": "Select a group please...",
                "groups": "Select some names...",
                "interval": "Select interval...",
                "template": "Select template...",
                "import-encoding": "Select encoding...",
                "import-delimiter": "Select CSV delimiter...",
                "add-to-group": "Add to group",
                "no-templates": "You do not have any templates.",
                "create-template": "Create template"
            },
            "reports": {
                "search": "Search...",
                "datepicker": {
                    "from": "From",
                    "to": "To"
                }
            },
            "events": {
                "group-search": "Search...",
                "contact-search": "Search...",
                "event-name": "Event name",
                "date": "Date"
            }
        },
        "reports": {
            "title": "Reports",
            "category-reports": "Campaigns",
            "sent-sms": "Sent SMS",
            "sent-voice": "Sent Voice",
            "export-campaign": "Export Selected Campaign",
            "choose": "Choose",
            "sms-template-type": "SMS",
            "voice-template-type": "Voice",
            "no-campaigns-to-show": "There are no matching campaigns.",
            "edit-campaign": "Choose campaign first.",
            "successfully-sent": "Successfully sent",
            "unsuccessfully-sent": "Unsuccessfully sent",
            "in-process": "In process",
            "failed": "Rejected by provider",
            "export-campaigns": "Export campaigns",
            "export-campaigns-msg": "There are no campaigns to export",
            "select-all": "By choosing this option you will select<br>all campaigns",
            "api-sms-report-header": "Sent sms for {{period}}"
        },
        "events": {
            "add-new": "Add new event",
            "event-receipt": "Event Receipt",
            "template-name": "Template Name",
            "when": "When",
            "repeat": "Repeat",
            "hour": "Hour",
            "contacts": "Contacts",
            "credits": "Credits",
            "send-to": "Send to",
            "will-spend": "Will spend",
            "available-credits": "Available credits",
            "voice-event-receipt": "Voice Event Receipt",
            "done": "Done",
            "prepare": "Calculate and prepare",
            "schedule": "Schedule campaign",
            "repeat-on-failure": "Repeat on failure",
            "select-event": "Select New Event",
            "sms-event": "SMS Event",
            "voice-event": "Voice Event (Comming soon...)",
            "ungrouped-contacts": "Ungrouped Contacts",
            "google-contacts": "Imported from Google",
            "campaign-scheduled": "Campaign scheduled",
            "campaign-edit": "Edit campaign",
            "campaign-delete": "Delete campaign",
            "campaign-stopped": "Your campaign was stopped",
            "no-contacts": "No contacts",
            "intervals": {
                "do-not-repeat": "Do not repeat",
                "daily": "Daily",
                "weekly": "Weekly",
                "monthly": "Monthly",
                "annually": "Annually"
            },
            "events-today": "Events Today",
            "no-events": "You do not have any events.",
            "credits-left": "Credits left:",
            "not-enough-credits": "You don't have enough credits to schedule this event. Please upgrade your plan.",
            "running_in_less_than_five_minutes-title": "The campaign cannot be cancelled",
            "running_in_less_than_five_minutes": "The campaign is going to run in less than five minutes or has already ran and cannot be cancelled.",
            "past-date_title": "Running campaign",
            "past-date": "The campaign has already ran",
            "change-plan": "Click here to upgrade",
            "set-event": "Set event",
            "groups": "Groups",
            "types": {
                "sms": "SMS Event",
                "voice": "Voice event"
            },
            "used-for-upcoming-campaigns": "This template is used for upcoming campaigns. Editing it will not change the campaigns.",
            "groups-select-all": "By choosing this option you will select<br>all groups, including the contacts in them",
            "contacts-select-all": "By choosing this option you will <br>select all contacts in the group"
        },
        "templates": {
            "groups-select-all": "By choosing this option you will select<br> all groups and templates",
            "templates-select-all": "By choosing this option you will select<br> all templates in the group",
            "continue-editing-template": "Continue editing template",
            "deny-editing-template": "Cancel",
            "save-template-message": "In order for you to save your template, you will have to continue by filling out" + " the necessary template information.",
            "play": "Play",
            "stop": "Stop",
            "delete": "Delete",
            "record": "Record",
            "upload": "Upload WAV file"
        },
        "notifications": {
            "realtime": {
                "no-notifications": "You don't have any notifications at this time.",
                "import-finished": {
                    "zero": "Your import has finished. No contacts have been imported.",
                    "one": "Your import has finished. One contact has been successfully imported.",
                    "other": "Your import has finished. {{count}} contacts have been successfully imported."
                },
                "campaign-prepared": "Your campaign {{name}} is prepared and will be sent as scheduled. You also edit or delete it now."
            },
            "success-update-campaign": "Successfully updated campaign",
            "success-prepared-campaign": "Successfully prepared campaign",
            "success-scheduled-campaign": "Successfully scheduled campaign",
            "success-delete-campaign": "Successfully deleted campaign",
            "error-delete-campaign": "The campaign cannot be deleted",
            "error-background-processing-active": "The campaign is being prepared right now and cannot be edited",
            "success-create-companies": "Successfully created company",
            "success-update-companies": "Successfully updated company",
            "success-delete-companies": "Successfully deleted company",
            "success-create-contacts": "Successfully created contact",
            "success-update-contacts": "Successfully updated contact",
            "success-delete-contacts": "Successfully deleted contact",
            "success-delete-many-contacts": "Successfully deleted contacts",
            "success-add-contacts-to-group": "Successfully added contacts to group",
            "success-create-contact-groups": "Successfully created group",
            "success-update-contact-groups": "Successfully updated group",
            "success-delete-contact-groups": "Successfully deleted group",
            "success-delete-many-contact-groups": "Successfully deleted groups",
            "success-create-imports": "Successfully imported data",
            "success-create-methods": "Successfully created payment method",
            "success-update-methods": "Successfully updated payment method",
            "success-delete-methods": "Successfully deleted payment method",
            "success-create-reports": "Successfully created report",
            "success-update-reports": "Successfully updated report",
            "success-delete-reports": "Successfully deleted report",
            "success-create-templates": "Successfully created template",
            "success-update-templates": "Successfully updated template",
            "success-delete-templates": "Successfully deleted template",
            "success-delete-many-templates": "Successfully deleted templates",
            "success-add-templates-to-group": "Successfully added templates to group",
            "success-create-template-groups": "Successfully created template group",
            "success-update-template-groups": "Successfully updated template group",
            "success-delete-template-groups": "Successfully deleted template group",
            "success-delete-many-template-groups": "Successfully deleted template groups",
            "success-update-users": "Successfully updated user settings",
            "success-update-company": "Successfully updated company",
            "success-upload-voice-file": "Successfully uploaded file",
            "free-plan-notice": "Нови цени на абонаментни планове",
            "active-plan-notice": "Нови <a target='_blank' href='http://povikvane.io/pricing'>цени</a> на абонаментни" + " планове от 01.04.2018. При изтичане на абонаментният Ви план след тази дата Вие автоматично ще преминете на \"Безплатен абонаментен план.\""
        },
        "errors": {
            "validation-title": "Validation error",
            "validation-text": "Please, fill the required fields",
            "events": {
                "template-not-found": "Template not found",
                "template-not-found-title": "Error",
                "contacts-not-selected": "No contacts selected",
                "contacts-not-selected-title": "Error",
                "already-scheduled-title": "Error",
                "already-scheduled": "The campaign is already scheduled"
            },
            "busy-payment-gateway": "There was a problem with the internet connection. Your inquiry was not processed. Please try again.",
            "invalid-card-details": "Our system encountered an error while processing your payment. Please check the information that you entered and try again. If you see this message again, please get in touch with the bank that issued your card.",
            "card-processor-declined": "Your payment was declined. Please get in touch with the bank that issued your" + " card, or use another one.",
            "login-general-error": "Error",
            "microphone-access": "To make a sound record you have to grant microphone access.",
            "upload-audio": "Error uploading audio file.",
            "upload-audio-wrong-file-type": "Wrong file type. Please upload WAV file.",
            "user-not-approved-title": "Error",
            "user-not-approved": "The user account is not approved by administrator."
        },
        "tos": {
            "title": "<h1>EN Povikvane Terms of Service</h1>",
            "text": "<p>EN orem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n                dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n                suscipit ullam. Dolor, laborum!</p>\n            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>\n        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab adipisci, aliquam amet blanditiis\n        dolore, dolores esse ipsa ipsum modi, mollitia necessitatibus nemo nostrum provident quaerat quod\n        suscipit ullam. Dolor, laborum!</p>",
            "go-back": "Go back to register form."
        },
        "cookieconsent": {
            "message": "This website uses cookies to ensure you get the best out of your experience.",
            "dismiss": "Got it!",
            "link": "Learn more",
            "href": "http://povikvane.io/general-terms-and-conditions"
        },
        "welcome": {
            "title": "Welcome to Povikvane",
            "create-event": "Create campaign",
            "slide1": {
                "title": "Follow these simple steps to send your first SMS.",
                "text": "Create or import your contacts.\n                        From the homepage choose \"Contacts\".\n                        Enter your contacts manually or simply import them."
            },
            "slide2": {
                "title": "Create your first campaign.",
                "text": "From the homepage select \"Templates\".\n                        The template holds the message that you want to send out. Start by creating a name for your template then fill out the SMS template body.\n                        You can now send your first message."
            },
            "slide3": {
                "title": "Use the \"Report\" screen to track your SMS deliveries.",
                "text": "You can view the number of delivered SMS, as well as other details regarding the SMS status.\n                      You can download a full, detailed report for each campaign, choosing the file format of your desire.\n                      By analyzing past events, you will improve your future campaigns."
            },
            "slide4": {
                "title": "In order for you to send out or schedule your messages, start by selecting \"New Campaign\".",
                "text": "Enter the date and time for the event.\n                      Choose the template that holds the text message body as well as the recipients amongst your contact lists.\n                      Choose \"Schedule campaign\", to schedule your first campaign."
            },
            "slide5": {
                "title": "Setup your Profile.",
                "text": "You can control and customize your Profile in the \"Settings Panel\".\n                      You can enter and edit personal, billing and financial information.\n                      You can select the monthly subscription that suits you best.\n                      You can enable and disable different features of Povikvane."
            }
        },
        "gdpr": {
            "the-terms-of-service-title": "Notice",
            "the-terms-of-service": "terms of service",
            "the-terms-of-service-href": "http://povikvane.io/uslovia-polzvane",
            "agree-terms-text": "You must agree to the terms of service.",
            "agree-to-terms": "i agree",
            "delete-account": "delete my account"
        }
    };
});