define('frontend/models/campaign', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _moment, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    var Validators = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.event.name-present'
        })],
        sendDate: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.event.send-date-present'
        }), (0, _emberCpValidations.validator)('date', {
            onOrAfter: 'now',
            precision: 'day',
            format: 'DD/MM/YYYY',
            message: function message(errorType) {
                if (errorType === 'onOrAfter') {
                    return 'validation.event.invalid-date-past';
                }

                return 'validation.event.invalid-date-format';
            }
        })],
        sendTime: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.event.send-time-present'
        }), (0, _emberCpValidations.validator)('time-dnd'), (0, _emberCpValidations.validator)('date', {
            onOrAfter: 'now',
            precision: 'second',
            value: function value(model /*, attribute*/) {
                return model.get('sendDate');
            },

            message: 'validation.event.invalid-time-past'
        })],
        template: [(0, _emberCpValidations.validator)('ds-error'), (0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.event.template-present'
        })]
    }, {
        debounce: 500
    });

    exports.default = _model.default.extend(Validators, {
        name: (0, _attr.default)(),
        sendDate: (0, _attr.default)('moment'),
        sendTime: Ember.computed('sendDate', {
            get: function get() {
                return this.get('sendDate') ? this.get('sendDate').format('HH:mm') : '';
            },
            set: function set(attribute, time) {
                return time;
            }
        }),
        scheduled: (0, _attr.default)('boolean', { defaultValue: false }),
        repeatable: (0, _attr.default)('boolean', { defaultValue: false }),
        repeatInterval: (0, _attr.default)(),
        prepared: (0, _attr.default)('boolean', { defaultValue: false }),
        contactIds: (0, _attr.default)(),
        creditsCost: (0, _attr.default)('number'),
        hasRun: (0, _attr.default)('boolean', { defaultValue: false }),
        isReady: (0, _attr.default)('boolean', { defaultValue: false }),
        template: (0, _relationships.belongsTo)('template'),
        type: (0, _attr.default)('string'),
        // contacts: hasMany('contacts'),
        // // meta relationship, used only to model the creation of a campaign
        // groups: hasMany('contact-groups'),

        setDate: function setDate(date) {
            var _get$split = this.get('sendTime').split(':'),
                _get$split2 = _slicedToArray(_get$split, 2),
                hours = _get$split2[0],
                minutes = _get$split2[1];

            hours = hours === '' ? undefined : hours;

            date = (0, _moment.default)(date);

            if (!isNaN(parseInt(hours))) {
                date.hours(parseInt(hours));
            }

            if (!isNaN(parseInt(minutes))) {
                date.minutes(parseInt(minutes));
            }

            this.set('sendDate', date);
        },
        setTime: function setTime(time) {
            var date = (0, _moment.default)(this.get('sendDate'));

            date.hours(time.hours !== undefined ? time.hours : date.hours());
            date.minutes(time.minutes !== undefined ? time.minutes : date.minutes());

            this.set('sendDate', (0, _moment.default)(date));
        }
    });
});