define('frontend/components/download-file-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    url: '',
    token: '',
    campaignType: null,
    report: null,
    tokenValue: Ember.computed('session.data.authenticated.token', function () {
      return this.get('session.data.authenticated.token');
    }),

    downloadApiReport: function downloadApiReport(type, report) {
      if (typeof report === 'string') {
        this.$('form').append('<input type="hidden" name="campaign" value="' + report + '">');
      } else {
        var reports = report.filterBy('isChecked', true).getEach('period');
        this.$('form').append('<input type="hidden" name="campaign" value="' + reports + '">');
      }

      this.$('input[name="token"]').val(this.get('tokenValue'));
      this.$('input[name="type"]').val(type);
      this.$('form').trigger('submit');
    },
    downloadDocument: function downloadDocument(type, campaignId) {
      if (campaignId) {
        var selectedCampaigns = (typeof campaignId === 'undefined' ? 'undefined' : _typeof(campaignId)) === 'object' ? campaignId.filterBy('isChecked', true) : null;
        var selectedCampaignsIds = selectedCampaigns ? selectedCampaigns.getEach('id') : campaignId;
        this.$('form').append('<input type="hidden" name="campaign" value="' + selectedCampaignsIds + '">');
        this.$('form').append('<input type="hidden" name="campaign_type" value="' + this.get('campaignType') + '">');
      }
      this.$('input[name="token"]').val(this.get('tokenValue'));
      this.$('input[name="type"]').val(type);
      this.$('form').trigger('submit');
    },


    actions: {
      download: function download(type, campaignId) {
        if (this.get('report') === 'api-report') {
          this.downloadApiReport(type, campaignId);
        } else {
          this.downloadDocument(type, campaignId);
        }
      }
    }
  });
});