define('frontend/routes/inner-app', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/allowed-urls'], function (exports, _authenticatedRouteMixin, _allowedUrls) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _allowedUrls.default, {
        user: Ember.inject.service(),
        modal: Ember.inject.service(),
        router: Ember.inject.service(),

        model: function model() {
            return this.get('user').load();
        },
        afterModel: function afterModel() {
            if (!this.get('user.data.acceptedTerms')) {
                this.transitionTo('inner-app.accept-terms');
            }
        },


        actions: {
            openPopUpSettings: function openPopUpSettings(template) {
                this.get('modal').destroyAllModals();
                this.get('modal').settings({
                    template: '' + template
                });
            }
        }
    });
});