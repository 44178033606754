define('frontend/components/guest/base-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        flashMessages: Ember.inject.service(),
        i18n: Ember.inject.service(),

        showRecaptcha: false,
        reCaptchaResponse: null,
        attemptNumber: 1,

        didInsertElement: function didInsertElement() {
            this.$().addClass('guest_container');
        },
        handleError: function handleError(errorObj) {
            var error = errorObj.detail ? errorObj.detail : this.get('i18n').t('general.error');
            this.get('flashMessages').danger(error);
        },
        handleErrors: function handleErrors(json) {
            var _this = this;

            if (json.errors) {
                json.errors.forEach(function (error) {
                    return _this.handleError(error);
                });
            }
        },
        resetRecaptcha: function resetRecaptcha() {
            this.incrementProperty('attemptNumber');
            if (this.get('attemptNumber') > 3) {
                this.set('showRecaptcha', true);
                this.set('reCaptchaResponse', 'reCaptcha');
                if (this.get('gRecaptcha')) {
                    this.get('gRecaptcha').resetReCaptcha();
                }
            }
        },


        actions: {
            onCaptchaResolved: function onCaptchaResolved(reCaptchaResponse) {
                this.set('reCaptchaResponse', reCaptchaResponse);
            }
        }
    });
});