define('frontend/components/settings/api-settings', ['exports', 'frontend/mixins/user-settings'], function (exports, _userSettings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed,
        Component = Ember.Component;
    exports.default = Component.extend(_userSettings.default, {
        serviceID: computed.alias('user.data.currentCompany.apiServiceId'),
        webhookUrl: computed.alias('user.data.currentCompany.apiWebhookUrl'),
        activeKeys: [],

        docsUrl: computed('user.data.language', function () {
            if (this.get('user.data.language') === 'en') {
                return '/docs/API_Povikavane_2_0_V1_0_1_en.pdf';
            }

            return '/docs/API_Povikavane_2_0_V1_0_1_bg.pdf';
        }),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('store').findAll('api-key').then(function (result) {
                _this.set('activeKeys', result);
            });
        },


        actions: {
            generateKey: function generateKey() {
                var _this2 = this;

                var key = this.get('store').createRecord('api-key');
                key.save().then(function () {
                    return _this2.get('user').reloadCompany();
                });
            },
            revokeKey: function revokeKey(key) {
                key.destroyRecord();
            },
            updateWebhook: function updateWebhook(value) {
                this.changeCompanyData({
                    name: 'apiWebhookUrl',
                    value: value,
                    notificationMessage: 'settings.api-access.webhook-save-notification'
                });
            }
        }
    });
});