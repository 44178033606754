define("frontend/routes/inner-app/events/index", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        activate: function activate() {
            this.transitionTo("inner-app.events.contact-groups.contacts", "all", { queryParams: { searchGroup: null } });
        }
    });
});