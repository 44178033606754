define('frontend/routes/inner-app/api-reports/show', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-network/fetch'], function (exports, _authenticatedRouteMixin, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    tokenValue: Ember.computed('session.data.authenticated.token', function () {
      return this.get('session.data.authenticated.token');
    }),

    model: function model(params) {
      return (0, _fetch.default)('/api/v1/api-reports/' + params.period, {
        method: 'GET',
        headers: {
          "X-Authorization": "Bearer " + this.get('tokenValue')
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        }

        return;
      }, function () /*error*/{
        /*console.error(error);*/
      }).then(function (json) {
        return json.data.attributes;
      });
    }
  });
});