define('frontend/routes/application', ['exports', 'frontend/utils/parse-error-status'], function (exports, _parseErrorStatus) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        headerLoadingIndicator: Ember.inject.service(),
        session: Ember.inject.service(),
        pusher: Ember.inject.service(),

        actions: {
            error: function error(_error /*, transition*/) {
                if (Array.isArray(_error.errors) && (0, _parseErrorStatus.default)(_error.errors[0].status, 401)) {
                    if (this.get('session.isAuthenticated')) {
                        this.get('pusher').unsubscribe();
                        this.get('session').invalidate();
                    }
                    window.location.href = '/login';
                } else {
                    Ember.Logger.error(_error);
                    this.replaceWith('/not-found');
                }
            },
            loading: function loading(transition) {
                var _this = this;

                this.get('headerLoadingIndicator').start();
                transition.finally(function () {
                    _this.get('headerLoadingIndicator').stop();
                });
            }
        }
    });
});