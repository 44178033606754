define('frontend/mixins/unsaved-data', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var _marked = regeneratorRuntime.mark(params);

    var service = Ember.inject.service;


    function params(transition) {
        var _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, info, _iteratorNormalCompletion2, _didIteratorError2, _iteratorError2, _iterator2, _step2, value;

        return regeneratorRuntime.wrap(function params$(_context) {
            while (1) {
                switch (_context.prev = _context.next) {
                    case 0:
                        _iteratorNormalCompletion = true;
                        _didIteratorError = false;
                        _iteratorError = undefined;
                        _context.prev = 3;
                        _iterator = transition.handlerInfos[Symbol.iterator]();

                    case 5:
                        if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                            _context.next = 36;
                            break;
                        }

                        info = _step.value;
                        _iteratorNormalCompletion2 = true;
                        _didIteratorError2 = false;
                        _iteratorError2 = undefined;
                        _context.prev = 10;
                        _iterator2 = Object.values(info.params)[Symbol.iterator]();

                    case 12:
                        if (_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done) {
                            _context.next = 19;
                            break;
                        }

                        value = _step2.value;
                        _context.next = 16;
                        return value;

                    case 16:
                        _iteratorNormalCompletion2 = true;
                        _context.next = 12;
                        break;

                    case 19:
                        _context.next = 25;
                        break;

                    case 21:
                        _context.prev = 21;
                        _context.t0 = _context['catch'](10);
                        _didIteratorError2 = true;
                        _iteratorError2 = _context.t0;

                    case 25:
                        _context.prev = 25;
                        _context.prev = 26;

                        if (!_iteratorNormalCompletion2 && _iterator2.return) {
                            _iterator2.return();
                        }

                    case 28:
                        _context.prev = 28;

                        if (!_didIteratorError2) {
                            _context.next = 31;
                            break;
                        }

                        throw _iteratorError2;

                    case 31:
                        return _context.finish(28);

                    case 32:
                        return _context.finish(25);

                    case 33:
                        _iteratorNormalCompletion = true;
                        _context.next = 5;
                        break;

                    case 36:
                        _context.next = 42;
                        break;

                    case 38:
                        _context.prev = 38;
                        _context.t1 = _context['catch'](3);
                        _didIteratorError = true;
                        _iteratorError = _context.t1;

                    case 42:
                        _context.prev = 42;
                        _context.prev = 43;

                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }

                    case 45:
                        _context.prev = 45;

                        if (!_didIteratorError) {
                            _context.next = 48;
                            break;
                        }

                        throw _iteratorError;

                    case 48:
                        return _context.finish(45);

                    case 49:
                        return _context.finish(42);

                    case 50:
                    case 'end':
                        return _context.stop();
                }
            }
        }, _marked, this, [[3, 38, 42, 50], [10, 21, 25, 33], [26,, 28, 32], [43,, 45, 49]]);
    }

    exports.default = Ember.Mixin.create({
        queue: service(),
        groups: null,

        setupController: function setupController(controller, model) {
            this._super(controller, model);
            this.cleanAttributes();
        },
        removeFromStore: function removeFromStore(record) {
            this.get('store').unloadRecord(record);
            this.modelFor(this.get('modelRoute')).removeObject(record);
        },
        cleanAttributes: function cleanAttributes() {
            this.controller.set('hasUnsavedData', false);
            this.controller.set('incompletedTransition', null);
        },


        actions: {
            proceedToSave: function proceedToSave() {
                var _this = this;

                this.get('queue').add(function () {
                    return _this.modelFor(_this.routeName).validate().then(function (_ref) {
                        var validations = _ref.validations;

                        _this.controller.set('hasUnsavedData', false);
                        if (validations.get('isValid')) {
                            _this.cleanAttributes();
                            return _this.modelFor(_this.routeName).save().then(function () {
                                _this.send('' + _this.get('proceedToSaveAction'));
                            });
                        }
                        return true;
                    });
                }, this.get('modelName'));
            },
            denySaving: function denySaving() {
                var _this2 = this;

                this.modelFor(this.routeName).rollbackAttributes();
                if (this.controller.get('routeName') !== null) {
                    var paramsTransition = this.controller.get('incompletedTransition.handlerInfos').getEach('params');
                    var finalThings = paramsTransition.filter(function (item) {
                        return Object.keys(item).length === 0;
                    });

                    if (paramsTransition.length === finalThings.length) {
                        return this.transitionTo(this.controller.get('incompletedTransition.targetName')).then(function () {
                            if (_this2.modelFor(_this2.routeName).get('id') === null) {
                                _this2.removeFromStore(_this2.modelFor(_this2.routeName));
                            }
                            _this2.cleanAttributes();
                        });
                    }

                    this.transitionTo.apply(this, [this.controller.get('incompletedTransition.targetName')].concat(_toConsumableArray(params(this.controller.get('incompletedTransition')))));

                    if (this.modelFor(this.routeName).get('id') === null) {
                        this.removeFromStore(this.modelFor(this.routeName));
                    }
                }
                this.cleanAttributes();
            },
            willTransition: function willTransition(transition) {
                var model = this.modelFor(this.routeName);
                if (model.errors.length === undefined) {
                    this.set('groups', model.get(this.get('modelName') + 'Groups'));
                    if (model.get('hasDirtyAttributes') && !model.get('isSaving')) {
                        this.controllerFor(transition.targetName).set('previousUnsavedData', true);
                        this.controller.set('hasUnsavedData', true);
                        this.controller.set('incompletedTransition', transition);
                    }
                }
            }
        }

    });
});