define('frontend/mixins/unsaved-data-transition-block', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        beforeModel: function beforeModel(transition) {
            if (this.controllerFor(this.routeName).get('previousUnsavedData')) {
                this.controllerFor(this.routeName).set('previousUnsavedData', false);
                transition.abort();
            }

            this._super.apply(this, arguments);
        }
    });
});