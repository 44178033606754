define('frontend/routes/inner-app/contact-groups/contacts/create', ['exports', 'frontend/mixins/unsaved-data', 'frontend/mixins/unsaved-data-transition-block'], function (exports, _unsavedData, _unsavedDataTransitionBlock) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var service = Ember.inject.service;
    exports.default = Ember.Route.extend(_unsavedData.default, _unsavedDataTransitionBlock.default, {
        user: service(),
        notifications: service(),
        selectedItems: service(),
        specialItems: service(),

        modelRoute: 'inner-app.contact-groups.contacts',
        modelName: 'contact',
        proceedToSaveAction: 'createdHandler',

        initCustomFieldValues: function initCustomFieldValues(contact) {
            contact.set('customFieldValues', this.get('user.data.currentCompany.customFields').map(function (field) {
                return {
                    fieldId: field.get('id'),
                    name: field.get('name'),
                    value: ''
                };
            }));

            return contact;
        },
        model: function model() {
            var _this = this;

            var params = this.paramsFor(this.get('modelRoute'));
            var contactsModel = this.modelFor(this.get('modelRoute'));

            if (this.shouldDoGeneralQuery(params.groupId)) {
                var contact = this.get('store').createRecord('contact');
                contactsModel.unshiftObject(contact);
                return this.initCustomFieldValues(contact);
            }

            return this.get('store').find('contact-group', params.groupId).then(function (group) {
                var contact = group.get('contacts').createRecord();
                contactsModel.unshiftObject(contact);
                return _this.initCustomFieldValues(contact);
            });
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.set('controller.focusedFirstFormField', false);
        },


        /**
         * Copy-paste from InfiniteScrollRouteMixin
         * See comment there
         * @param groupId
         * @returns {boolean}
         */
        shouldDoGeneralQuery: function shouldDoGeneralQuery(groupId) {
            return groupId === 'all' || groupId === 'ungrouped' || groupId === 'google';
        },

        handleSaveEvent: function handleSaveEvent(params, contact) {
            var specialItems = this.get('specialItems');

            this.controllerFor('inner-app.contact-groups.contacts').checkAndRemoveFromList(contact);

            specialItems.setItemGroups('contact', contact.get('id'), contact.get('contactGroups').mapBy('id'));

            this.get('notifications').showSuccess(this.get('i18n').t('notifications.success-create-contacts'));
        },


        actions: {
            createdHandler: function createdHandler(lastFocusedFieldSelector) {
                var params = this.paramsFor('inner-app.contact-groups.contacts');
                var contact = this.modelFor(this.routeName);
                this.handleSaveEvent(params, contact);
                // The edit route will use this property to focus again the last input
                // that the user was on before the save
                this.controllerFor('inner-app.contact-groups.contacts.edit').set('lastFocusedInput', lastFocusedFieldSelector);
                this.transitionTo('inner-app.contact-groups.contacts.edit', params.groupId, contact.get('id'));
            }
        }
    });
});