define('frontend/adapters/template', ['exports', 'frontend/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var get = Ember.get;


    var SYSTEM_GROUPS = ['no-group'];

    exports.default = _application.default.extend({
        urlForQuery: function urlForQuery(query) {
            if (!get(query, 'filter.group')) {
                return this._super.apply(this, arguments);
            }

            var group = query.filter.group;


            if (SYSTEM_GROUPS.indexOf(group) !== -1) {
                return this._super.apply(this, arguments);
            }

            delete query.filter.group;
            return this.buildURL('template-groups', group) + '/templates';
        }
    });
});