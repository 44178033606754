define('frontend/utils/zindex', ['exports', 'ember-dialog/utils/zindex'], function (exports, _zindex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'max', {
    enumerable: true,
    get: function () {
      return _zindex.max;
    }
  });
});