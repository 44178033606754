define('frontend/models/plan', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _model, _attr, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _model.default.extend({
        name: (0, _attr.default)(),
        period: (0, _attr.default)(),
        credits: (0, _attr.default)(),
        price: (0, _attr.default)(),
        default: (0, _attr.default)(),
        payments: _emberData.default.hasMany('payment')
    });
});