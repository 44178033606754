define('frontend/components/help-section', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        search: '',
        categories: [],

        selectedEntry: Ember.computed('activeAnchor', function () {
            if (this.get('activeAnchor')) {
                return this.get('activeAnchor');
            }
            return null;
        }),

        helpCategories: Ember.computed('search', 'categories', function () {
            var search = Ember.$.trim(this.get('search').toLowerCase());
            var categories = this.get('categories');

            if (!search.length) {
                return categories;
            }

            return categories.map(function (category) {
                var filteredCategory = {};

                filteredCategory.helpEntries = category.get('helpEntries').filter(function (entry) {
                    return entry.get('title').toLowerCase().indexOf(search) > -1;
                });

                if (filteredCategory.helpEntries.length) {
                    filteredCategory.name = category.get('name');
                }

                return filteredCategory;
            });
        }),

        isHelpHiddenObserver: Ember.observer('isHelpHidden', function () {
            if (!this.get('isHelpHidden')) {
                Ember.$('.help_search_field').focus();
                this.getCategories();
            }
        }),

        openStartScreen: function openStartScreen() {
            var homePage = Ember.A([]);

            this.get('categories').forEach(function (category) {
                var entry = category.get('helpEntries').filter(function (entry) {
                    return entry.get('isHome');
                });
                if (entry.length > 0) {
                    homePage = entry.get('firstObject');
                }
            });

            if (!Ember.isEmpty(homePage) && !window.location.hash) {
                this.send('selectEntry', homePage);
            }
        },
        getCategories: function getCategories() {
            var _this = this;

            var categories = this.get('store').peekAll('help-category', {
                include: 'help-entries'
            });

            if (categories.get('length')) {
                this.set('categories', categories);
                this.openStartScreen();
                return;
            }

            this.get('store').query('help-category', {
                include: 'help-entries'
            }).then(function (result) {
                _this.set('categories', result);
                _this.openStartScreen();
            });
        },


        actions: {
            hideHelp: function hideHelp() {
                this.set('activeAnchor', null);
                if (window.location.hash) {
                    window.location.hash = '';
                }
                this.sendAction('hideHelp');
            },
            selectEntry: function selectEntry(entry) {
                this.set('activeAnchor', entry);
                if (entry.get('anchor')) {
                    window.location.hash = '#' + entry.get('anchor');
                } else {
                    window.location.hash = '';
                }
            }
        }
    });
});