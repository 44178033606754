define('frontend/models/contact', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-data-model-fragments/attributes'], function (exports, _model, _attr, _relationships, _emberCpValidations, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;


    var Validators = (0, _emberCpValidations.buildValidations)({
        email: [(0, _emberCpValidations.validator)('format', {
            isWarning: true,
            type: 'email',
            message: 'validation.contact-email-format'
        })],
        phoneNumber: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.contact-phonenumber-present'
        }), (0, _emberCpValidations.validator)('ds-error')],
        firstName: [(0, _emberCpValidations.validator)('ds-error')],
        lastName: [(0, _emberCpValidations.validator)('ds-error')],
        company: [(0, _emberCpValidations.validator)('ds-error')],
        website: [(0, _emberCpValidations.validator)('ds-error')],
        address: [(0, _emberCpValidations.validator)('ds-error')],
        note: [(0, _emberCpValidations.validator)('ds-error')]
    }, {
        debounce: 500
    });

    var Contact = _model.default.extend(Validators, {
        firstName: (0, _attr.default)(),
        lastName: (0, _attr.default)(),
        email: (0, _attr.default)(),
        phoneNumber: (0, _attr.default)(),
        company: (0, _attr.default)(),
        website: (0, _attr.default)(),
        address: (0, _attr.default)(),
        note: (0, _attr.default)(),
        contactGroups: (0, _relationships.hasMany)('contact-group', { async: true }),
        name: computed('firstName', 'lastName', function () {
            var firstName = this.get('firstName') === undefined ? '' : this.get('firstName');
            var lastName = this.get('lastName') === undefined ? '' : this.get('lastName');
            return firstName + ' ' + lastName;
        }),
        isNameEmpty: computed('name', function () {
            return this.get('name.length') === 1;
        }),
        googleId: (0, _attr.default)(),
        customFieldValues: (0, _attributes.fragmentArray)('custom-field-value')
    });

    exports.default = Contact;
});