define('frontend/components/guest/login-form', ['exports', 'frontend/components/guest/base-form', 'ember-network/fetch', 'frontend/utils/parse-error-status', 'ember-cp-validations'], function (exports, _baseForm, _fetch, _parseErrorStatus, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // For model based validators we should extract the mixins in separate moduls
    var Validations = (0, _emberCpValidations.buildValidations)({
        identification: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.login-email-present'
        }), (0, _emberCpValidations.validator)('format', {
            type: 'email',
            message: 'validation.login-email-format'
        })],
        password: [(0, _emberCpValidations.validator)('presence', {
            presence: true,
            message: 'validation.login-password-present'
        })]
    }, {
        debounce: 500
    });

    exports.default = _baseForm.default.extend(Validations, {
        session: Ember.inject.service(),
        user: Ember.inject.service(),
        locale: Ember.inject.service(),

        identification: null, // can be passed in

        didValidate: false,

        showResendVerification: false,
        showBlockedAccount: false,

        handleErrors: function handleErrors(response) {
            var _this = this;

            if (response.errors && response.errors.length > 0) {
                response.errors.forEach(function (error) {
                    if ((0, _parseErrorStatus.default)(error.status, 401)) {
                        _this.get('flashMessages').danger(_this.get('i18n').t('general.error'));
                    } else if ((0, _parseErrorStatus.default)(error.status, 409)) {
                        _this.set('showResendVerification', true);
                    } else if ((0, _parseErrorStatus.default)(error.status, 403)) {
                        _this.set('showBlockedAccount', true);
                    } else {
                        _this.handleError(error);
                    }
                });
            } else {
                this.get('flashMessages').danger(this.get('i18n').t('errors.login-general-error'));
            }
        },


        actions: {
            authenticate: function authenticate() {
                var _this2 = this;

                // If the user account is blocked and then try to login with
                // different account we should hide the message for blocking
                this.set('showBlockedAccount', false);

                this.validate().then(function (_ref) {
                    var validations = _ref.validations;

                    if (validations.get('isValid')) {
                        var credentials = _this2.getProperties('identification', 'password'),
                            authenticator = 'authenticator:jwt-lang-aware-recaptcha';

                        credentials.lang = _this2.get('locale.curentLocale');
                        credentials.g_recaptcha_response = _this2.get('reCaptchaResponse');

                        _this2.get('session').authenticate(authenticator, credentials).then(function () {
                            _this2.sendAction();
                        }, function (response) {
                            _this2.handleErrors(response);
                        });
                    } else {
                        _this2.set('didValidate', true);
                    }
                    _this2.resetRecaptcha();
                });
            },
            sendVerification: function sendVerification() {
                var _this3 = this;

                var data = new FormData();

                data.append('email', this.get('identification'));

                (0, _fetch.default)('/api/v1/verification', {
                    method: 'POST',
                    body: data
                }).then(function (response) {
                    if (response.ok) {
                        _this3.get('flashMessages').success(_this3.get('i18n').t('guest.verification-link-sent'));
                    }
                });
            }
        }
    });
});