define('frontend/routes/inner-app/accept-terms', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'frontend/mixins/allowed-urls'], function (exports, _authenticatedRouteMixin, _allowedUrls) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _allowedUrls.default, {
        user: Ember.inject.service(),
        modal: Ember.inject.service(),
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        router: Ember.inject.service(),

        actions: {
            accept: function accept() {
                var _this = this;

                this.get('ajax').post('users/me/accept-terms', {
                    data: {
                        terms: 'privacy policy 25.05.2018'
                    }
                }).then(function () {
                    _this.get('user').load().then(function () {
                        _this.transitionTo('inner-app');
                    });
                });
            }
        }
    });
});