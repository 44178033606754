define('frontend/components/settings/information-settings', ['exports', 'frontend/mixins/user-settings'], function (exports, _userSettings) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_userSettings.default, {
        visibleLicenses: false,

        actions: {
            showLicenses: function showLicenses() {
                this.set('visibleLicenses', !this.get('visibleLicenses'));
            }
        }
    });
});