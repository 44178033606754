define('frontend/components/power-select-multiple/trigger', ['exports', 'ember-power-select/components/power-select-multiple/trigger'], function (exports, _trigger) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _trigger.default.extend({
        actions: {
            onKeydown: function onKeydown(e) {
                if (e.keyCode === 8 && Ember.isBlank(e.target.value)) {
                    var select = this.get('select');
                    var input = this.get('input');
                    var lastSelection = select.selected[select.selected.length - 1];
                    if (lastSelection) {
                        input.value = '';
                        select.actions.select(this.get('buildSelection')(lastSelection, select), e);
                        select.actions.close(e);
                        e.stopPropagation();
                    }
                } else {
                    this._super(e);
                }
            }
        }
    });
});