define('frontend/components/templates/template-group-name-input', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        tagName: '',
        searchTmplGroup: null, //passed in

        actions: {
            deleteGroup: function deleteGroup(tmpGroup) {
                this.sendAction('deleteGroup', tmpGroup);
            },
            editGroup: function editGroup(tmpGroup) {
                tmpGroup.set('isEditing', true);
            },
            cancelGroupEdit: function cancelGroupEdit(tmpGroup) {
                tmpGroup.set('isEditing', false);
                tmpGroup.rollbackAttributes();
            },
            saveGroup: function saveGroup(tmpGroup) {
                if (tmpGroup.get('isNotValid')) {
                    return;
                }
                if (tmpGroup.get('isEditing') === true) {
                    tmpGroup.set('isEditing', false);
                    this.sendAction('saveGroup', tmpGroup);
                }
                return;
            }
        }

    });
});