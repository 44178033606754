define('frontend/utils/template-max-length', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = templateMaxLength;
  var computed = Ember.computed;
  function templateMaxLength(attributeToTrack) {
    return computed(attributeToTrack, function () {
      return this.get(attributeToTrack) ? 120 : 459;
    });
  }
});