define('frontend/utils/unique-group-name', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = uniqueName;
    function uniqueName(model, message) {
        var itemNumber = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

        var groupsCount = itemNumber === 0 ? model.length + 1 : itemNumber;
        var groupName = message + (' ' + groupsCount);
        var isUniqueName = model.filterBy('name', groupName);

        if (isUniqueName.length === 0) {
            return groupName;
        } else {
            return uniqueName(model, message, groupsCount + 1);
        }
    }
});