define('frontend/controllers/inner-app/import', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        headerLoadingIndicator: Ember.inject.service(),
        ajax: Ember.inject.service(),
        user: Ember.inject.service(),
        notifications: Ember.inject.service(),
        columnDefinitions: Ember.A([]),
        errorTableConfigs: Ember.A([]),
        pendingProcessing: false,
        currentImportedGroupId: null,
        finishedImport: false,
        encodingOptions: [{
            value: 'UTF-8',
            text: 'contacts.import.encoding-utf8'
        }, {
            value: 'CP1251',
            text: 'contacts.import.encoding-cp1251'
        }, {
            value: 'ISO-8859-1',
            text: 'contacts.import.encoding-latin'
        }],
        delimiterOptions: [{
            value: ',',
            text: 'contacts.import.delimiter-comma'
        }, {
            value: '|',
            text: 'contacts.import.delimiter-pipe'
        }, {
            value: '\t',
            text: 'contacts.import.delimiter-tab'
        }],
        selectedEncoding: null,
        selectedDelimiter: null,
        additionalData: {},
        shouldShowResultsLink: Ember.computed('currentImportedGroupId', function () {
            return this.get('currentImportedGroupId');
        }),

        shouldShowUploadButton: Ember.computed('pendingProcessing', 'finishedImport', function () {
            return !this.get('pendingProcessing') && !this.get('finishedImport');
        }),
        googleRedirectUrl: '',
        showMapPhoneTooltip: false,
        loggedInGoogle: false,
        disableGoogleButtons: true,
        selectedImportGroup: null,

        resetState: function resetState() {
            var _this = this;

            this.set('columnDefinitions', Ember.A([]));
            this.set('pendingProcessing', false);
            this.set('finishedImport', false);

            this.get('store').findAll('google-contact', { reload: true }).then(function (result) {
                result.forEach(function (res) {
                    _this.set('googleRedirectUrl', res.get('redirect_url'));
                });
            });
        },

        resetModel: function resetModel() {
            this.set('model', this.get('store').createRecord('import'));
        },
        checkGoogleLogin: function checkGoogleLogin() {
            var _this2 = this;

            this.get('ajax').post('google-access').then(function (result) {
                _this2.setGoogleParams(result);
            });
        },
        setGoogleParams: function setGoogleParams(params) {
            this.set('loggedInGoogle', params.has_token);
            this.set('googleRedirectUrl', params.redirect_url);
            this.set('disableGoogleButtons', false);
        },
        resetEncodingAndDelimiter: function resetEncodingAndDelimiter(encoding, delimiter) {
            this.set('selectedEncoding', null);
            this.set('additionalData.encoding', encoding);
            this.set('selectedDelimiter', null);
            this.set('additionalData.delimiter', delimiter);
        },


        actions: {
            uploadedFile: function uploadedFile(response) {
                this.resetModel();
                var importData = response.body.data;
                this.get('model').set('id', importData.id);
                var parsedContacts = importData.attributes.rawData;

                var headers = parsedContacts.shift();

                headers = headers.map(function (header, index) {
                    return { 'label': header, 'valuePath': '' + index, 'mappedField': '' };
                });

                this.set('columnDefinitions', headers);
                this.get('model').set('rawData', parsedContacts);
                this.set('pendingProcessing', true);
                this.set('enableAdvancedSettings', false);
                this.set('selectedEncoding', null);
                this.set('selectedDelimiter', null);
                this.get('headerLoadingIndicator').stop();
            },
            uploadError: function uploadError() /** response */{
                this.set('enableAdvancedSettings', true);
                this.get('headerLoadingIndicator').stop();
                this.resetEncodingAndDelimiter('UTF-8', ',');
            },
            confirmImport: function confirmImport() {
                var _this3 = this;

                var validationUniqueField = this.get('user.data.currentCompany.contactsUniqueField');
                var colMapping = this.get('columnDefinitions').map(function (field) {
                    return field.mappedField;
                });

                var mappedPhoneField = colMapping.findBy('propertyName', 'phone-number');

                var mappedUniqueField = validationUniqueField ? colMapping.findBy('propertyName', validationUniqueField) : false;

                if (!mappedPhoneField) {
                    this.set('showMapPhoneTooltip', true);
                    Ember.run.later(this, function () {
                        this.set('showMapPhoneTooltip', false);
                    }, 6000);

                    return;
                }

                if (!mappedUniqueField) {
                    this.set('showMapUniqueTooltip', true);
                    Ember.run.later(this, function () {
                        this.set('showMapUniqueTooltip', false);
                    }, 6000);
                    return;
                }

                this.get('headerLoadingIndicator').start();
                this.get('model').set('fieldMap', colMapping);
                this.get('model').save().then(function (model) {
                    if (model.get('backgroundProcessing')) {
                        _this3.get('notifications').showSuccess(_this3.get('i18n').t('contacts.import.background-processing-started'));
                        _this3.resetState();
                        _this3.resetModel();
                    } else {
                        if (model.get('invalid').length > 0) {
                            _this3.set('errorTableConfigs', _this3.get('columnDefinitions').map(function (col) {
                                col.valuePath = col.mappedField.propertyName;
                                return col;
                            }));
                        }
                        _this3.set('currentImportedGroupId', model.get('meta.imported-group-id'));
                        _this3.set('csvLink', model.get('meta.csv'));
                        _this3.set('finishedImport', true);
                    }
                }, function (response) {
                    var errors = Ember.A(response.errors);
                    if (errors.length > 0) {
                        _this3.get('notifications').showWarning(_this3.get('i18n').t(errors.get('firstObject.detail')));
                    }
                    _this3.resetState();
                }).finally(function () {
                    _this3.set('selectedImportGroup', null);
                    _this3.set('pendingProcessing', false);
                    _this3.resetEncodingAndDelimiter('UTF-8', ',');
                    _this3.get('headerLoadingIndicator').stop();
                });
            },
            resetState: function resetState() {
                this.resetState();
            },
            changeCSVDelimiter: function changeCSVDelimiter(selected) {
                this.set('selectedDelimiter', selected);
                this.set('additionalData.delimiter', this.get('selectedDelimiter.value'));
            },
            changeCSVEncoding: function changeCSVEncoding(selected) {
                this.set('selectedEncoding', selected);
                this.set('additionalData.encoding', this.get('selectedEncoding.value'));
            },
            revokeGoogle: function revokeGoogle() {
                var _this4 = this;

                if (this.get('disableGoogleButtons')) {
                    return;
                }
                this.set('disableGoogleButtons', true);
                this.get('ajax').post('google-access', {
                    data: {
                        revoke: true
                    }
                }).then(function (result) {
                    _this4.setGoogleParams(result);
                });
            },
            importGoogle: function importGoogle() {
                if (this.get('disableGoogleButtons')) {
                    return;
                }
                if (this.get('loggedInGoogle')) {
                    this.transitionToRoute('/import-google').then(function (googleRoute) {
                        googleRoute.controller.checkGoogleLogin();
                    });
                } else {
                    window.location.replace(this.get('googleRedirectUrl'));
                }
            },
            selectImportGroup: function selectImportGroup(group) {
                this.set('selectedImportGroup', group);
                if (group) {
                    this.get('model').set('groupId', group.get('id'));
                } else {
                    this.get('model').set('groupId', null);
                }
            }
        }
    });
});